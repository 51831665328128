import React from 'react';

const styles = {
  watermark: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    opacity: 0.2,
    width: '160px',
    height: '120px',
  },
  textWatermark: {
    position: 'absolute',
    top: '60%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    opacity: 0.2,
    width: '160px',
    height: '120px',
  },
};

const watermarkSVG = `
<svg width="160" height="120" viewBox="0 0 80 60" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M61.7701 13.9468C63.897 12.7209 66.1669 11.7164 68.5399 10.9414C61.6272 5.935 53.1272 2.97705 43.9288 2.97705C20.7621 2.98496 1.98438 21.6975 1.98438 44.7838H8.20659C8.20659 25.1221 24.1986 9.18558 43.9288 9.18558C50.4288 9.18558 56.5161 10.9255 61.7701 13.9468Z" fill="#2563EB"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M43.9279 1L43.9286 1C53.5611 1 62.4667 4.09913 69.7061 9.34209L72.8457 11.6158L69.1576 12.8204C66.9108 13.5543 64.7672 14.5035 62.7632 15.6585L61.7709 16.2304L60.7782 15.6595C55.815 12.8055 50.0682 11.163 43.9286 11.163C25.2942 11.163 10.1905 26.2143 10.1905 44.7839V46.7612H0V44.7839C0 20.6056 19.6661 1.00828 43.9279 1ZM43.9286 4.95448C22.5227 4.96214 5.05056 21.7315 4.01664 42.8067H6.27352C7.30516 22.9723 23.7681 7.20853 43.9286 7.20853C50.3835 7.20853 56.4556 8.83523 61.7695 11.6835C62.5726 11.2533 63.3929 10.8523 64.2285 10.4809C58.2802 6.97111 51.3445 4.9546 43.9292 4.95448" fill="#2563EB"/>
  <path d="M4.06319 37.7607C3.85684 39.9199 3.73779 42.1186 3.73779 44.3489H7.92033C7.96795 41.9762 8.55525 39.7459 9.57112 37.7607H4.06319Z" fill="#2563EB"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M1.89831 35.3882H13.4581L11.6923 38.8388C10.8371 40.51 10.3412 42.3886 10.3009 44.3965L10.2542 46.7217H1.35693V44.349C1.35693 42.0347 1.48046 39.7605 1.69306 37.5359L1.89831 35.3882Z" fill="#2563EB"/>
  <path d="M44.6272 37.7607H36.6748C37.6907 39.7459 38.278 41.9762 38.3256 44.3489H43.9605C43.9764 42.0948 44.2065 39.8962 44.6272 37.7607Z" fill="#2563EB"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M32.7881 35.3882H47.5211L46.9637 38.2179C46.5711 40.2112 46.3564 42.2624 46.3416 44.3657L46.325 46.7217H35.9919L35.9453 44.3965C35.905 42.3886 35.4091 40.51 34.5539 38.8388L32.7881 35.3882ZM40.1212 40.1336C40.2815 40.7359 40.4104 41.3507 40.5063 41.9763H41.67C41.713 41.3578 41.7712 40.7435 41.844 40.1336H40.1212Z" fill="#2563EB"/>
  <path d="M9.57169 37.7607C8.55582 39.7459 7.96852 41.9762 7.9209 44.3489H38.3177C38.2701 41.9762 37.6828 39.7459 36.6669 37.7607H9.57169Z" fill="#2563EB"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M8.11354 35.3882H38.1257L38.7883 36.683C39.9648 38.982 40.6436 41.564 40.6985 44.3016L40.7471 46.7217H5.49219L5.54076 44.3016C5.5957 41.564 6.27444 38.982 7.45096 36.683L8.11354 35.3882ZM11.1192 40.1336C10.8947 40.7276 10.7133 41.3429 10.5788 41.9763H35.6604C35.5259 41.3429 35.3446 40.7276 35.1201 40.1336H11.1192Z" fill="#2563EB"/>
  <path d="M64.7856 29.4644C56.3808 29.4644 49.5713 36.2502 49.5713 44.6258C49.5713 53.0014 56.3808 59.7873 64.7856 59.7873C73.1903 59.7873 79.9998 53.0014 79.9998 44.6258C79.9998 36.2502 73.1903 29.4644 64.7856 29.4644ZM64.7856 52.9777C60.1586 52.9777 56.4046 49.2367 56.4046 44.6258C56.4046 40.0149 60.1586 36.274 64.7856 36.274C69.4125 36.274 73.1665 40.0149 73.1665 44.6258C73.1665 49.2367 69.4125 52.9777 64.7856 52.9777Z" fill="#2563EB"/>
  <path d="M38.3253 44.3491H31.492C31.492 44.444 31.5078 44.5389 31.5078 44.6338C31.5078 49.2448 27.7539 52.9857 23.1269 52.9857C18.4999 52.9857 14.7459 49.2448 14.7459 44.6338C14.7459 44.5389 14.7539 44.444 14.7618 44.3491H7.92847C7.92847 44.444 7.9126 44.5389 7.9126 44.6338C7.9126 53.0094 14.7221 59.7953 23.1269 59.7953C31.5316 59.7953 38.3412 53.0094 38.3412 44.6338C38.3412 44.5389 38.3253 44.444 38.3253 44.3491Z" fill="#2563EB"/>
  <path d="M50.397 32.2563L51.2541 31.3705C54.516 27.9934 58.5319 25.4309 62.9684 23.8887" fill="#2563EB"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M63.4912 25.3821C59.2896 26.8427 55.4865 29.2695 52.3978 32.4674L51.5396 33.3542L49.2544 31.1582L50.1105 30.2735C50.1107 30.2733 50.1108 30.2732 50.111 30.273C53.5459 26.7169 57.7744 24.0188 62.4456 22.395L63.4912 25.3821Z" fill="#2563EB"/>
</svg>
`;

const textWatermarkSVG = `
<svg width="160" height="120" viewBox="0 0 76 30" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M15.0734 6H18.2534V23.08H15.0734V6ZM10.6134 11.24V6.08H13.7534V22.78H10.6134V20.92L8.15344 22.12L6.03344 17.8C4.99344 20.66 3.39344 22.18 1.25344 22.28V18.84C3.15344 18.54 4.27344 13.8 4.27344 6.34L7.47344 6.46C7.47344 9.02 7.33344 11.28 7.07344 13.24L10.6134 20.02V14.44H8.73344V11.24H10.6134ZM36.5117 6V23.08H33.1317V6H36.5117ZM25.8717 6.2C29.0917 6.2 31.2317 8.72 31.2317 14.34C31.2317 19.96 29.0917 22.48 25.8717 22.48C22.6517 22.48 20.4917 19.96 20.4917 14.34C20.4917 8.72 22.6517 6.2 25.8717 6.2ZM25.8717 9.3C24.7317 9.3 23.8317 10.52 23.8317 14.34C23.8317 18.18 24.7317 19.38 25.8717 19.38C26.9917 19.38 27.8917 18.18 27.8917 14.34C27.8917 10.52 26.9917 9.3 25.8717 9.3ZM75.5083 12.24V15.52H73.5083V23.08H70.1283V6H73.5083V12.24H75.5083ZM57.9483 9.44V6.4H67.7683V11.52C67.7683 18.36 63.6683 22.26 57.9483 22.28V19.12C60.7283 19.1 62.8683 17.9 63.8483 15.32H57.9483V12.26H64.4083V11.76V9.44H57.9483Z" fill="#2563EB"/>
  <path d="M55.23 13.94V17.04H39.51V13.94H42.11V9.52H39.69V6.4H55.05V9.52H52.63V13.94H55.23ZM45.51 9.52V13.94H49.23V9.52H45.51ZM38.73 19.32H56.01V22.48H38.73V19.32Z" fill="#67E8F9"/>
</svg>
`;

const Watermark = () => (
  <>
    <div style={styles.watermark} dangerouslySetInnerHTML={{ __html: watermarkSVG }} />
    <div style={styles.textWatermark} dangerouslySetInnerHTML={{ __html: textWatermarkSVG }} />
  </>
);

export default Watermark;
