import React, { useEffect, useState } from 'react';
import { useIncidents } from '../context/IncidentContext';
import { CircularProgress, Box, Modal } from '@mui/material';
import AccidentList from '../components/accident/AccidentList';
import AccidentModal from '../components/accident/AccidentModal';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import MapContainer from '../components/maps/MapContainer';
import MainLayout from '../components/layout/MainLayout';
import useWindowDimensions from '../hook/useWindowDimensions';
// Custom hook to track window width


const MainPage = () => {
  const { userIncidents, selectedIncident, setSelectedIncident, fetchRecentIncidents, loading, initialLoad, setCurrentPage } = useIncidents();
  const [modalOpen, setModalOpen] = useState(false);
  const { isNarrow } = useWindowDimensions();

  useEffect(() => {
    setCurrentPage('main');
    fetchRecentIncidents();
  }, [fetchRecentIncidents, setCurrentPage]);

  const markers = userIncidents.map(accident => ({
    position: {
      lat: parseFloat(accident.latitude),
      lng: parseFloat(accident.longitude),
    },
    title: `장소: ${accident.address}`,
    isSelected: selectedIncident && 
    selectedIncident.timeStamp === accident.timeStamp &&
    selectedIncident.userId === accident.userId,
accident,
}));

  const handleAccidentClick = (accident) => {
    setSelectedIncident(accident);
    setModalOpen(true);  // 모달창 열기
  };

  const handleMarkerClick = (accident) => {
    setSelectedIncident(accident);
    setModalOpen(true);
  };

  const handleCloseDetails = () => {
    setSelectedIncident(null);
    setModalOpen(false);
  };

  const selectedCenter = selectedIncident ? {
    lat: parseFloat(selectedIncident.latitude),
    lng: parseFloat(selectedIncident.longitude),
  } : null;

  const getSeverityColor = (severity) => {
    if (severity === '급가속') return '#2563EB';
    if (severity === '급감속') return 'orange';
    return '#EF4444';
  };

  if (initialLoad && loading) {
    return (
      <MainLayout>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <CircularProgress />
        </Box>
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      <Box sx={{ 
        display: 'flex', 
        flexDirection: isNarrow ? 'column' : 'row',
        height: '100%',
          overflow: 'hidden',
      }}>
        <Box sx={{ 
          width: isNarrow ? '100%' : '75vw', 
          height: isNarrow ? '60%' : '92vh',
        }}>
          <MapContainer
            markers={markers}
            center={selectedCenter}
            onMarkerClick={handleMarkerClick}
          />
        </Box>
        <Box sx={{ 
            width: isNarrow ? '100%' : '25vw', 
            height: isNarrow ? '40%' : '92vh', 
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
              width: 'clamp(8px, 0.5vw, 12px)',  // 스크롤 바의 너비 조정
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: '#F3F4F6',  // 스크롤 바 배경
              borderRadius: '10px',  // 둥근 모서리 적용
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#9CA3AF',  // 스크롤 바의 색상
              borderRadius: '10px',  // 스크롤 바 모서리를 둥글게
              border: '2px solid transparent',  // 스크롤 바에 테두리 추가
              backgroundClip: 'content-box',  // 내부 여백 조정
            },
            '&::-webkit-scrollbar-thumb:hover': {
              backgroundColor: '#6B7280',  // 스크롤 바를 호버할 때 색상 변경
            },
          }}>
          <Box sx={{ padding: '1.04vh 0.42vw', borderTop: '1px solid #ddd' }}>
            <Box sx={{ 
              marginTop: isNarrow ? '2vh' : '4vh', 
              marginLeft: '1vw',
            }}>
              <Box component="h3" sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                fontWeight: 'bold', 
                fontSize: isNarrow ? '16px' : '1.15vw',
              }}>
                최근 사고 리스트
                <HelpOutlineOutlinedIcon sx={{ 
                  verticalAlign: 'middle', 
                  marginLeft: '1vw', 
                  color: '#4B5563', 
                  width: isNarrow ? '16px' : '1.25vw', 
                  height: isNarrow ? '16px' : '1.25vw',
                }} />
              </Box>
            </Box>
            <AccidentList accidents={userIncidents} handleAccidentClick={handleAccidentClick} selectedAccident={selectedIncident} />
          </Box>
        </Box>
      </Box>
      <Modal
        open={modalOpen}
        onClose={handleCloseDetails}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: '#F3F4F6',
          borderRadius: '3vh',
          p: '4vh',
        }}>
          {selectedIncident && (
            <AccidentModal selectedAccident={selectedIncident} handleCloseDetails={handleCloseDetails} getSeverityColor={getSeverityColor} />
          )}
        </Box>
      </Modal>
    </MainLayout>
  );
};

export default MainPage;