import React, { useState, useEffect } from 'react';
import {
   Grid, Card, CardContent, CardMedia, Typography, Box, Snackbar, Checkbox
} from '@mui/material';
import { useAuth } from '../../context/AuthProvider';
import { getDatabase, ref, get, set, remove } from 'firebase/database';
import CustomAddButton from '../../components/utils/CustomAddButton';
import CustomButton from '../../components/utils/CustomButton';
import SearchBox from '../../components/utils/SearchBox';
import MemberPagination from '../../components/member/MemberPagination';
import InsuranceEditModal from '../../components/Insurance/InsuranceEditModal'; 
import SubLayout from '../../components/layout/SubLayout';
import useWindowDimensions from '../../hook/useWindowDimensions'; // Import the hook

const generateImageUrl = (text) => {
  const colors = ['FF0000', '00FF00', '0000FF', 'FFFF00', 'FF00FF', '00FFFF', 'FFA500', '800080', '008080', '000000'];
  const randomColor = colors[Math.floor(Math.random() * colors.length)];
  return `https://via.placeholder.com/400x400/${randomColor}/FFFFFF?text=${encodeURIComponent(text)}`;
};

const InsurancePage = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchType, setSearchType] = useState('name');
  const [currentPage, setCurrentPage] = useState(1);
  const [insuranceCompanies, setInsuranceCompanies] = useState([]);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editCompany, setEditCompany] = useState(null);
  const [alertOpen, setAlertOpen] = useState(false);
  const itemsPerPage = 8;
  const { isNarrow } = useWindowDimensions(); // Get the screen size condition
  const { currentUser } = useAuth();
  const isViewer = currentUser?.role === 'viewer';

  const searchTypes = [
    { value: 'name', label: '보험사' },
    { value: 'number', label: '전화번호' },
  ];

  useEffect(() => {
    const fetchInsuranceCompanies = async () => {
      const db = getDatabase();
      const dbRef = ref(db, 'insurance');
      const snapshot = await get(dbRef);

      if (snapshot.exists()) {
        const data = snapshot.val();
        const companiesArray = Object.keys(data).map(key => ({
          ...data[key],
          name: key,
          imageUrl: data[key].imageUrl || generateImageUrl(key)
        }));
        setInsuranceCompanies(companiesArray);
        setFilteredCompanies(companiesArray);
      }
    };

    fetchInsuranceCompanies();
  }, []);

  useEffect(() => {
    setFilteredCompanies(
      insuranceCompanies.filter(company =>
        company[searchType].toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, searchType, insuranceCompanies]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const paginatedCompanies = filteredCompanies.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const count = Math.ceil(filteredCompanies.length / itemsPerPage);

  const handleDialogOpen = (company = null) => {
    setEditCompany(company);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleSave = async (company) => {
    const db = getDatabase();
    const companyRef = ref(db, `insurance/${company.name}`);
    const companyData = {
      ...company,
      imageUrl: company.imageUrl || generateImageUrl(company.name)
    };

    try {
      await set(companyRef, companyData);
      const existingCompanyIndex = insuranceCompanies.findIndex(item => item.name === company.name);
      let updatedCompanies;

      if (existingCompanyIndex > -1) {
        updatedCompanies = [
          ...insuranceCompanies.slice(0, existingCompanyIndex),
          companyData,
          ...insuranceCompanies.slice(existingCompanyIndex + 1)
        ];
      } else {
        updatedCompanies = [...insuranceCompanies, companyData];
      }

      setInsuranceCompanies(updatedCompanies);
      setFilteredCompanies(updatedCompanies);
      setDialogOpen(false);
      setEditCompany(null);
    } catch (error) {
      console.error('Error saving company:', error);
      setAlertOpen(true);
    }
  };

  const handleCheckboxChange = (companyName) => {
    setSelectedCompanies(prev => {
      if (prev.includes(companyName)) {
        return prev.filter(name => name !== companyName);
      } else {
        return [...prev, companyName];
      }
    });
  };

  const handleDelete = async () => {
    const db = getDatabase();
    try {
      const updatedCompanies = insuranceCompanies.filter(company => !selectedCompanies.includes(company.name));
      await Promise.all(selectedCompanies.map(companyName => remove(ref(db, `insurance/${companyName}`))));
      setInsuranceCompanies(updatedCompanies);
      setFilteredCompanies(updatedCompanies);
      setSelectedCompanies([]);
    } catch (error) {
      console.error('Error deleting companies:', error);
      setAlertOpen(true);
    }
  };

  return (
      <SubLayout>
        <Box sx={{ flexGrow: 1 }}>
          <Typography 
            variant="h4" 
            sx={{ 
              fontWeight: 'bold', 
              marginBottom: 'clamp(10px, 1.48vh, 20px)',
              fontSize: 'clamp(24px, 2vw, 36px)',
            }}
          >
            보험사
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', mb: 'clamp(20px, 4vh, 40px)' }}>
            <SearchBox 
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              searchType={searchType}
              setSearchType={setSearchType}
              searchTypes={searchTypes}
            />
          <CustomButton 
            onClick={() => !isViewer && handleDelete()} 
            disabled={isViewer}
            sx={{ 
              ml: 'clamp(10px, 1vw, 20px)', 
              mr: 'clamp(10px, 1vw, 20px)', 
              width: 'clamp(100px, 7vw, 200px)',
              opacity: isViewer ? 0.5 : 1,
              cursor: isViewer ? 'not-allowed' : 'pointer',
            }}
          >
            보험사 삭제
          </CustomButton>
          <CustomAddButton 
            onClick={() => !isViewer && handleDialogOpen({ name: '', number: '', aos_url: '', ios_url: '' })}
            disabled={isViewer}
            sx={{
              opacity: isViewer ? 0.5 : 1,
              cursor: isViewer ? 'not-allowed' : 'pointer',
            }}
          >
            보험사 추가
          </CustomAddButton>
        </Box>
          <Grid container spacing={4}>
            {paginatedCompanies.map((company) => (
              <Grid 
                item 
                key={company.name} 
                xs={12} 
                sm={6} 
                md={isNarrow ? 4 : 3} // Adjust grid size based on isNarrow (3 columns if narrow, 4 columns otherwise)
              >
                <Card sx={{
                  width: isNarrow ? '300px' : 'clamp(230px, 17vw, 17vw)',  // Conditionally set width
                  height: isNarrow ? '300px' : 'clamp(230px, 27vh, 27vh)',  // Conditionally set height
                  backgroundColor: '#F3F4F6',
                  position: 'relative',
                  padding: 'clamp(10px, 2vw, 2vw)', // Clamped padding
                  borderRadius: 'clamp(5px, 0.52vw, 0.52vw)', // Clamped border-radius
                  boxShadow: '0 clamp(2px, 0.21vw, 0.21vw) clamp(3px, 0.31vh, 0.31vh) rgba(0,0,0,0.1)', // Clamped shadow
                  transition: 'transform 0.2s',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  }
                }}>
                  <Checkbox 
                    checked={selectedCompanies.includes(company.name)}
                    onChange={() => handleCheckboxChange(company.name)}
                    sx={{ 
                      position: 'absolute', 
                      top: 'clamp(8px, 1vw, 1vw)', 
                      right: 'clamp(8px, 1vw, 1vw)', 
                      zIndex: 1, 
                      width: 'clamp(20px, 1.35vw, 1.35vw)', 
                      height: 'clamp(20px, 2.41vh, 2.41vh)' 
                    }} 
                  />
                  <Box onClick={() => handleDialogOpen(company)} sx={{ cursor: 'pointer' }}>
                    <CardMedia
                      component="img"
                      image={company.imageUrl}
                      alt={company.name}
                      sx={{ 
                        objectFit: 'contain', 
                        width: '100%', 
                        padding: 'clamp(5px, 0.52vw, 0.52vw)', 
                        height: 'auto', 
                        maxHeight: 'clamp(100px, 13.89vh, 13.89vh)' 
                      }} 
                    />
                    <CardContent sx={{ textAlign: 'center', padding: 'clamp(6px, 0.63vw, 0.63vw)' }}>
                      <Typography variant="h6" component="div" gutterBottom sx={{ fontWeight: 'bold', fontSize: 'clamp(14px, 1.04vw, 1.04vw)' }}>
                        {company.name}
                      </Typography>
                      <Typography variant="body2" color="text.secondary" sx={{ fontSize: 'clamp(12px, 0.83vw, 0.83vw)' }}>
                        {company.number}
                      </Typography>
                    </CardContent>
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 'clamp(20px, 2vh, 40px)' }}>
            <MemberPagination
              count={count}
              page={currentPage}
              onChange={handlePageChange}
            />
          </Box>
        </Box>

        <InsuranceEditModal
          open={dialogOpen}
          onClose={handleDialogClose}
          company={editCompany}
          onSave={handleSave}
          isViewer={isViewer}  // isViewer prop 전달
        />


      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={() => setAlertOpen(false)}
        message="An error occurred. Please try again."
      />
      </SubLayout>
  );
};

export default InsurancePage;
