import { useState, useEffect, useCallback, useMemo } from 'react';
import axios from 'axios';

const useGeocodedAddresses = (accidents) => {
  const [processedAddresses, setProcessedAddresses] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const getStoredAddress = useCallback((key) => {
    const stored = localStorage.getItem(key);
    return stored ? JSON.parse(stored) : null;
  }, []);

  const storeAddress = useCallback((key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
  }, []);

  const fetchAddress = useCallback(async (accident) => {
    const { latitude, longitude, address } = accident;
    let storageKey;
    let params;

    if (latitude && longitude) {
      storageKey = `address_${latitude}_${longitude}`;
      params = {
        latlng: `${latitude},${longitude}`,
        key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        language: 'ko',
        result_type: 'street_address'
      };
    } else if (address) {
      storageKey = `address_${address}`;
      params = {
        address: address,
        key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        language: 'ko',
        result_type: 'street_address'
      };
    } else {
      return '주소 정보 없음';
    }

    const storedAddress = getStoredAddress(storageKey);
    if (storedAddress) {
      return storedAddress;
    }
    try {
      const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json`, { params });
  
      if (response.data.status === 'OK' && response.data.results.length > 0) {
        let roadNameAddress = response.data.results[0].formatted_address;
        
        // '대한민국' 문구 제거 (대소문자 구분 없이)
        roadNameAddress = roadNameAddress.replace(/대한민국\s*/gi, '');
        
        // 주소 앞뒤의 공백 제거
        roadNameAddress = roadNameAddress.trim();
        
        storeAddress(storageKey, roadNameAddress);
        return roadNameAddress;
      }
    } catch (error) {
      console.error('Error fetching address:', error);
    }
    return '주소 정보 없음';
  }, [getStoredAddress, storeAddress]);

  const accidentsToProcess = useMemo(() => {
    return accidents.filter(accident => 
      !processedAddresses[accident.timeStamp] &&
      (accident.latitude || accident.longitude || accident.address)
    );
  }, [accidents, processedAddresses]);

  useEffect(() => {
    let isMounted = true;
    if (accidentsToProcess.length === 0) return;

    setIsLoading(true);

    const processAddresses = async () => {
      const newAddresses = {};
      await Promise.all(accidentsToProcess.map(async (accident) => {
        const address = await fetchAddress(accident);
        if (address && isMounted) {
          newAddresses[accident.timeStamp] = address;
        }
      }));
      
      if (isMounted) {
        setProcessedAddresses(prevAddresses => ({...prevAddresses, ...newAddresses}));
        setIsLoading(false);
      }
    };

    processAddresses();

    return () => {
      isMounted = false;
    };
  }, [accidentsToProcess, fetchAddress]);

  const getAddress = useCallback((accident) => {
    return processedAddresses[accident.timeStamp] || accident.address || '주소 정보 없음';
  }, [processedAddresses]);

  return { getAddress, isLoading };
};

export default useGeocodedAddresses;