import React, { useState, useEffect } from 'react';
import { Box, Paper } from '@mui/material';

const drawerWidth = 'clamp(200px, 16.77vw, 100%)';

const SubLayout2 = ({ children }) => {
  const [isDrawerVisible, setIsDrawerVisible] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      const newIsDrawerVisible = window.innerWidth > window.screen.width * 0.8;
      setIsDrawerVisible(newIsDrawerVisible);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call once to set initial state
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        paddingTop: 'clamp(60px, 8vh, 80px)', // AppBar 높이만큼 상단 여백
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Paper
        elevation={3}
        sx={{
          flexGrow: 1,
          width: '100%',
          height: '100%',
          overflow: 'hidden', // Changed from 'auto' to 'hidden'
          paddingLeft: isDrawerVisible
            ? `calc(${drawerWidth})`
            : '0',
          transition: 'padding-left 0.3s',
          '@media print': {
            boxShadow: 'none',
            border: 'none',
          },
        }}
      >
        {children}
      </Paper>
    </Box>
  );
};

export default SubLayout2;