import React from 'react';
import { Box, Grid, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import UserInfoSection from '../accident/AccidentModal/UserInfoSection';
import VehicleInfoSection from '../accident/AccidentModal/VehicleInfoSection';
import AccidentTimeLocationSection from '../accident/AccidentModal/AccidentTimeLocationSection';
import WeatherInfoSection from '../accident/AccidentModal/WeatherInfoSection';
import AccidentChart from './AccidentChart';
import useWindowDimensions from '../../hook/useWindowDimensions';

const styles = {
  modal: {
    backgroundColor: '#F3F4F6',
    borderRadius: 'clamp(10px, 1.04vw, 1.04vw)',
    width: 'clamp(300px, 80vw, 80vw)',
    height: 'clamp(400px, 80vh, 80vh)',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  titleBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 'clamp(10px, 1vw, 20px)',
    borderBottom: '1px solid #E5E7EB',
  },
  title: {
    fontWeight: 'bold',
    fontSize: 'clamp(20px, 1.56vw, 1.56vw)',
  },
  closeButton: {
    height: 'clamp(30px, 2.34vw, 2.34vw)',
    width: 'clamp(30px, 2.34vw, 2.34vw)',
    color: 'black',
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  userInfoSection: {
    flexShrink: 0,
  },
  scrollableContent: {
    flex: 1,
    overflow: 'auto',
    padding: 'clamp(10px, 1vw, 20px)',
    '&::-webkit-scrollbar': {
      width: '0.42vw',
      height: '0.42vw',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
      borderRadius: '0.21vw',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#555',
    },
  },
  section: {
    backgroundColor: '#FFFFFF',
    borderRadius: 'clamp(10px, 1.04vw, 20px)',
    marginBottom: 'clamp(10px, 1vw, 1vw)',
    overflow: 'hidden',
    height: 'auto',
  },
};

const AccidentModal = ({ selectedAccident, handleCloseDetails }) => {
  const { isNarrow } = useWindowDimensions();

  if (!selectedAccident) {
    return null;
  }

  return (
    <Box sx={styles.modal}>
      <Box sx={styles.titleBox}>
        <Typography sx={styles.title}>사고 상세 정보</Typography>
        <IconButton onClick={handleCloseDetails}>
          <CloseIcon sx={styles.closeButton} />
        </IconButton>
      </Box>
      <Box sx={styles.content}>
        <Box sx={styles.userInfoSection}>
          <UserInfoSection selectedAccident={selectedAccident} isNarrow={isNarrow} />
        </Box>
        <Box sx={styles.scrollableContent}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={isNarrow ? 12 : 4}>
              <Box sx={styles.section}>
                <VehicleInfoSection selectedAccident={selectedAccident} isNarrow={isNarrow} />
              </Box>
            </Grid>
            <Grid item xs={12} md={isNarrow ? 12 : 4}>
              <Box sx={styles.section}>
                <AccidentTimeLocationSection selectedAccident={selectedAccident} isNarrow={isNarrow} />
              </Box>
            </Grid>
            <Grid item xs={12} md={isNarrow ? 12 : 4}>
              <Box sx={styles.section}>
                <WeatherInfoSection selectedAccident={selectedAccident} isNarrow={isNarrow} />
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ ...styles.section, padding: '1.56vw', marginTop: 'clamp(10px, 1vw, 1vw)', marginBottom: '5vh' }}>
            <Typography sx={{ fontWeight: 'bold',     fontSize: 'clamp(16px, 1.25vw, 24px)', }}>
              추가 정보
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={isNarrow ? 12 : 4}>
              <AccidentChart 
                allData={selectedAccident.allData}
                car_speed={selectedAccident.car_speed}
                dataKey="speed" 
                color="#FF7300" 
                title="자동차 속도 (km/h)" 
                yDomain={[0, 200]} 
                yAxisLabel="km/sec"
              />
              </Grid>
              <Grid item xs={12} md={isNarrow ? 12 : 4}>
                <AccidentChart 
                  allData={selectedAccident.allData} 
                  dataKey="rpm" 
                  color="#8884D8" 
                  title="자동차 RPM (rpm)" 
                  yDomain={[0, 10000]} 
                  yAxisLabel="rpm / sec"
                />
              </Grid>
              <Grid item xs={12} md={isNarrow ? 12 : 4}>
                <AccidentChart 
                  allData={selectedAccident.allData} 
                  dataKey="load" 
                  color="#387908" 
                  title="엔진 온도 (°C)" 
                  yDomain={[0, 100]} 
                  yAxisLabel="°C / sec"
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AccidentModal;