import React, { useState, useEffect } from 'react';
import {
  Paper, Box, Button, Container, Typography, Snackbar, TextField
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useMembers } from '../../context/MemberContext';
import { useAuth } from '../../context/AuthProvider';
import { ForwardToInbox as ForwardToInboxIcon } from '@mui/icons-material';
import axios from 'axios';
import SearchBox from '../../components/utils/SearchBox';
import CheckboxTable from '../../components/utils/CheckboxTable';
import GenericTable from '../../components/utils/GenericTable';

const SendMessages = () => {
  const { currentUser } = useAuth();
  const { members, fetchAllMembers } = useMembers();
  const [searchTerm, setSearchTerm] = useState('');
  const [searchType, setSearchType] = useState('userName');
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [message, setMessage] = useState('');
  const [alertOpen, setAlertOpen] = useState(false);
  const [sentMessages, setSentMessages] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser) {
      fetchAllMembers();
    }
  }, [currentUser, fetchAllMembers]);

  const visibleMembers = members.filter(member =>
    member[searchType] && member[searchType].toString().toLowerCase().includes(searchTerm)
  );

  const handleCheckboxChange = (event, id) => {
    if (id === 'selectAll') {
      if (event.target.checked) {
        const newSelecteds = visibleMembers.map((member) => member.id || member.userId);
        setSelectedMembers(newSelecteds);
      } else {
        setSelectedMembers([]);
      }
    } else {
      const selectedIndex = selectedMembers.indexOf(id);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selectedMembers, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selectedMembers.slice(1));
      } else if (selectedIndex === selectedMembers.length - 1) {
        newSelected = newSelected.concat(selectedMembers.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selectedMembers.slice(0, selectedIndex),
          selectedMembers.slice(selectedIndex + 1),
        );
      }

      setSelectedMembers(newSelected);
    }
  };

  const handleSendMessages = async () => {
    if (!message || selectedMembers.length === 0) {
      setAlertOpen(true);
      return;
    }

    try {
      const response = await axios.post('https://aligoapi-url/send', {
        userId: currentUser.userId,
        message,
        recipients: selectedMembers,
      });

      if (response.data.success) {
        setSentMessages([...sentMessages, {
          message,
          recipients: selectedMembers,
          timestamp: new Date().toLocaleString()
        }]);
        setMessage('');
        setSelectedMembers([]);
      }
    } catch (error) {
      console.error('Failed to send messages:', error);
    }
  };

  const searchTypes = [
    { value: 'userId', label: 'ID' },
    { value: 'userName', label: 'Name' },
    { value: 'userPhone', label: 'Phone' },
    { value: 'userCarNumber', label: 'Vehicle Number' }
  ];

  const memberColumns = [
    { id: 'userId', label: 'ID' },
    { id: 'userName', label: 'Name' },
    { id: 'userPhone', label: 'Phone' },
    { id: 'userCarNumber', label: 'Vehicle' }
  ];

  const messageColumns = [
    { id: 'message', label: 'Message' },
    { id: 'recipients', label: 'Recipients', render: (row) => row.recipients.join(', ') },
    { id: 'timestamp', label: 'Timestamp' }
  ];

  const handleRowClick = (member) => {
    navigate(`/member-details/${member.userId}`);
  };

  return (
    <Container maxWidth="xl" sx={{ mt: 4, overflow: 'hidden' }}>
      <Paper
        sx={{
          p: 4,
          boxShadow: 3,
          borderRadius: 5,
          height: '90vh',
          overflowY: 'auto',
          overflowX: 'hidden',
          '&::-webkit-scrollbar': {
            width: '8px',
            height: '8px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888',
            borderRadius: '4px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#555',
          },
          '& .MuiTableRow-root:hover': {
            backgroundColor: '#f5f5f5',
          }
        }}
      >
        <Typography variant="h4" gutterBottom>Send Messages</Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
          <SearchBox
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            searchType={searchType}
            setSearchType={setSearchType}
            searchTypes={searchTypes}
          />
        </Box>
        <CheckboxTable
          items={visibleMembers}
          selectedItems={selectedMembers}
          handleCheckboxChange={handleCheckboxChange}
          columns={memberColumns}
          handleRowClick={handleRowClick}
        />
        <TextField
          label="Message"
          multiline
          rows={4}
          variant="outlined"
          fullWidth
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          sx={{ marginY: 2 }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSendMessages}
          startIcon={<ForwardToInboxIcon />}
        >
          Send Messages
        </Button>
        <Typography variant="h6" gutterBottom sx={{ marginTop: 4 }}>Sent Messages</Typography>
        <GenericTable
          columns={messageColumns}
          data={sentMessages}
          showCheckboxes={false}
          itemsPerPage={5}
        />
        <Snackbar
          open={alertOpen}
          autoHideDuration={6000}
          onClose={() => setAlertOpen(false)}
          message="Please fill in all required fields"
        />
      </Paper>
    </Container>
  );
};

export default SendMessages;
