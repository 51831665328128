import React, { useState, useEffect } from 'react';
import {
  Box, Table, TableBody, TableCell, TableHead, TableRow, Chip, Tabs, Tab
} from '@mui/material';
import { useService } from '../../context/ServiceContext';
import SearchBox from '../utils/SearchBox';
import { useNavigate } from 'react-router-dom';
import MemberPagination from '../member/MemberPagination';
import useWindowDimensions from '../../hook/useWindowDimensions';

const ComplaintSection = ({ searchTerm, setSearchTerm, searchType, setSearchType, searchTypes, tabIndex, setTabIndex }) => {
  const { complaints } = useService();
  const [filteredComplaints, setFilteredComplaints] = useState([]);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const itemsPerPage = 10;
  const { isNarrow } = useWindowDimensions();

  const styles = {
    headerBox: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      borderBottom: '0.185vh solid #E5E7EB',
      color: 'black',
    },
    tabs: {
      marginBottom: 'clamp(-8px, 0, 0)',
    },
    tab: {
      color: '#6B7280',
      fontWeight: 'bold',
      fontSize: 'clamp(13px, 1.2vw,  1.2vw)',
      '&.Mui-selected': {
        color: '#141414',
      },
    },
    searchBox: {
      display: 'flex',
      alignItems: 'center',
    },
    tableBox: {
      overflowX: isNarrow ? 'auto' : 'hidden',
      overflowY: 'auto',
      width: '100%',
    },
    table: {
      width: '100%',
      borderCollapse: 'collapse',
      borderSpacing: '0',
      minWidth: 'auto',
    },
    headerCell: {
      color: '#6B7280',
      fontWeight: 'bold',
      padding: 'clamp(6px, 0.5vw, 0.5vw)',
      height: 'clamp(40px, 5.7vh, 5.7vh)',
      fontSize: 'clamp(12px, 1vw, 0.8vw)',
    },
    tableCell: {
      borderBottom: 'none',
      height: 'clamp(40px, 5.7vh, 5.7vh)',
      padding: 'clamp(6px, 0.5vw, 0.5vw)',
      color: '#141414',
      fontSize: 'clamp(12px, 1vw, 0.8vw)',
      fontWeight: 'bolder',
    },
    numberCell: {
      color: '#6B7280',
      borderBottom: 'none',
      padding: 'clamp(8px, 1vw, 16px)',
      fontWeight: 'bold',
      fontSize: 'clamp(12px, 1vw, 0.8vw)',
    },
    tableRow: {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#f5f5f5',
      },
    },
  };
  
  useEffect(() => {
    filterComplaints();
  }, [tabIndex, searchTerm, searchType, complaints]);

  const filterComplaints = () => {
    const status = tabIndex === 1;
    const filtered = complaints
      .filter(c => (status ? c.answerBody : !c.answerBody))
      .filter(c => {
        if (searchType === 'userId' && c.userId?.toLowerCase().includes(searchTerm.toLowerCase())) return true;
        if (searchType === 'body' && c.body?.toLowerCase().includes(searchTerm.toLowerCase())) return true;
        if (searchType === 'title' && c.title?.toLowerCase().includes(searchTerm.toLowerCase())) return true;
        return false;
      })
      .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));

    setFilteredComplaints(filtered);
  };

  const handleRowClick = (complaint) => {
    const encodedTimeStamp = encodeURIComponent(complaint.timeStamp);
    navigate(`/complaint/${complaint.userId}/${encodedTimeStamp}`);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const paginatedComplaints = filteredComplaints.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <Box sx={styles.headerBox}>
        <Tabs 
          value={tabIndex}
          onChange={(event, newValue) => setTabIndex(newValue)}
          sx={styles.tabs}
        >
          <Tab 
            label="미완료"
            sx={styles.tab}
          />
          <Tab 
            label="답변 완료"
            sx={styles.tab}
          />
        </Tabs>
        <Box sx={styles.searchBox}>
          <SearchBox
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            searchType={searchType}
            setSearchType={setSearchType}
            searchTypes={searchTypes}
          />
        </Box>
      </Box>
      
      <Box sx={styles.tableBox}>
        <Table sx={styles.table}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ ...styles.headerCell, width: '5%' }}>No</TableCell>
              <TableCell sx={{ ...styles.headerCell, width: '10%' }}>ID</TableCell>
              <TableCell sx={{ ...styles.headerCell, width: '20%' }}>제목</TableCell>
              <TableCell sx={{ ...styles.headerCell, width: '20%' }}>내용</TableCell>
              <TableCell sx={{ ...styles.headerCell, width: '20%' }}>민원 날짜</TableCell>
              <TableCell sx={{ ...styles.headerCell, width: '15%' }}>처리 상태</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedComplaints.map((complaint, index) => (
              <TableRow
                key={complaint.timestamp}
                sx={styles.tableRow}
                onClick={() => handleRowClick(complaint)}
              >
                <TableCell sx={{ ...styles.numberCell, width: '5%' }}>{index + 1}</TableCell>
                <TableCell sx={{ ...styles.tableCell, width: '10%' }}>{complaint.userId}</TableCell>
                <TableCell sx={{ ...styles.tableCell, width: '20%' }}>{complaint.title}</TableCell>
                <TableCell sx={{ ...styles.tableCell, width: '20%' }}>{complaint.body}</TableCell>
                <TableCell sx={{ ...styles.tableCell, width: '20%' }}>{complaint.timeStamp}</TableCell>
                <TableCell sx={{ ...styles.tableCell, width: '15%' }}>
                  <Chip
                    label={complaint.answerBody ? `답변 완료` : '미완료'}
                    sx={{
                      backgroundColor: complaint.answerBody ? '#2563EB' : '#E8680F',
                      color: 'white',
                      fontSize: 'clamp(10px, 1.3vh, 14px)',
                      height: 'clamp(24px, 2.78vh, 32px)',
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', padding: 'clamp(8px, 1vh, 16px)', boxSizing: 'border-box', background: 'white' }}>
        <MemberPagination
          count={Math.ceil(filteredComplaints.length / itemsPerPage)}
          page={page}
          onChange={handlePageChange}
        />
      </Box>
    </Box>
  );
};

export default ComplaintSection;