// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, get, set, update, remove, onChildAdded, onChildChanged, onChildRemoved, off, onValue, push } from 'firebase/database';
import { getFunctions } from 'firebase/functions';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDwZ2vcuL3j85lT5IUT00u1qoxg27kKOPk",
  authDomain: "safecar-1487e.firebaseapp.com",
  databaseURL: "https://safecar-1487e-default-rtdb.firebaseio.com",
  projectId: "safecar-1487e",
  storageBucket: "safecar-1487e.appspot.com",
  messagingSenderId: "996292816200",
  appId: "1:996292816200:web:604de85a03bfd13d25577d",
  measurementId: "G-N4HSEB3YZK"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const functions = getFunctions(app);
const storage = getStorage(app);
const messaging = getMessaging(app);

export { 
  database, 
  ref, 
  get, 
  set, 
  update, 
  remove, 
  functions, 
  storage, 
  storageRef, 
  uploadBytes, 
  getDownloadURL, 
  onChildAdded, 
  onChildChanged, 
  onChildRemoved, 
  off,
  onValue,  
  getToken,
  onMessage,
  messaging,
  push, // 여기에 push를 추가했습니다.
};