import React, { useEffect, useState, useRef } from 'react';
import '../styles/ControlSite.css';
import main4 from '../../../styles/img/info/main4.png';
import report1 from '../../../styles/img/info/report1.png';
import report2 from '../../../styles/img/info/report2.png';
import reportEn1 from '../../../styles/img/info/reportEn1.png';
import reportEn2 from '../../../styles/img/info/reportEn2.png';
import reportJp1 from '../../../styles/img/info/reportJp1.png';
import reportJp2 from '../../../styles/img/info/reportJp2.png';
import { ThickChevronLeft, ThickChevronRight } from '../styles/ThickChevronIcons';

const AccidentReport = ({
  scale,
  mobileScale,
  text,
  language,
  header2Ref,
  MultilineText
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 431);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const imagesContainerRef = useRef(null);

  const getImage = (koImage, enImage, jpImage) => {
    switch(language) {
      case 'en':
        return enImage;
      case 'ja':
        return jpImage;
      default:
        return koImage;
    }
  };

  const images = [
    getImage(report1, reportEn1, reportJp1),
    getImage(report2, reportEn2, reportJp2)
  ];

  useEffect(() => {
    const handleResize = () => {
      const newIsMobile = window.innerWidth <= 431;
      setIsMobile(newIsMobile);
      document.documentElement.style.setProperty('--scale', newIsMobile ? mobileScale : scale);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
      }
    );

    if (header2Ref.current) {
      observer.observe(header2Ref.current);
    }

    return () => {
      window.removeEventListener('resize', handleResize);
      if (header2Ref.current) {
        observer.unobserve(header2Ref.current);
      }
    };
  }, [scale, mobileScale, header2Ref]);

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <>
      <div className="headerSection">
        <div className="headerContent">
          <div className="verticalLine"></div>
          {text.trafficAccidentReport}
        </div>
      </div>
      <div className="main1Container">
        <img src={main4} alt="Main 4" className="main1Image" />
        <div className="overlay10"></div>
        {isMobile ? (
          <div className={`mobileSubImagesContainer ${isVisible ? 'visible' : ''}`} ref={imagesContainerRef}>
            <button 
              className={`navButton prevButton ${currentImageIndex === 0 ? 'dark' : 'light'}`} 
              onClick={handlePrevImage}
            >
              <ThickChevronLeft size={24} />
            </button>
            <img
              src={images[currentImageIndex]}
              alt={`report${currentImageIndex + 1}`}
              className="mobileSubImage"
              style={{
                width: `${329 * mobileScale}px`,
                height: `${470 * mobileScale}px`
              }}
            />
            <button 
              className={`navButton nextButton ${currentImageIndex === 0 ? 'light' : 'dark'}`} 
              onClick={handleNextImage}
            >
              <ThickChevronRight size={24} />
            </button>
          </div>
        ) : (
          <div className={`subImagesContainer2 ${isVisible ? 'visible' : ''}`}>
            {images.map((img, index) => (
              <img
                key={index}
                src={img}
                alt={`report${index + 1}`}
                className="subImage2"
                style={{width: `${270 * scale}px`}}
              />
            ))}
          </div>
        )}
      </div>
      <div ref={header2Ref} className="textSection">
        <div className={`textContent2 ${isVisible ? 'visible' : ''}`}>
          {language === 'en' && isMobile ? text.trafficAccidentReportDesc : <MultilineText text={text.trafficAccidentReportDesc} />}
        </div>
      </div>
    </>
  );
};

export default React.memo(AccidentReport);