import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, Divider } from '@mui/material';
import { useFormatData } from '../../../hook/useFormatData';

const styles = {
  infoSection: (isNarrow) => ({
    padding: 'clamp(15px, 1.56vw, 30px)',
    backgroundColor: '#FFFFFF',
    borderRadius: 'clamp(10px, 1.04vw, 20px)',
    height: isNarrow ? '27vh' : '55vh',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '0.42vw',
      height: '0.42vw',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
      borderRadius: '0.21vw',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#555',
    },
  }),
  title: {
    fontWeight: 'bold',
    marginBottom: 'clamp(10px, 1vh, 20px)',
    fontSize: 'clamp(16px, 1.25vw, 24px)',
  },
  weatherSummary: {
    fontSize: 'clamp(14px, 1.04vw, 20px)',
    backgroundColor: 'rgba(78, 158, 249, 0.14)',
    color: '#2563EB',
    padding: 'clamp(10px, 1.04vw, 20px)',
    borderRadius: 'clamp(8px, 0.83vw, 16px)',
    marginTop: 'clamp(5px, 0.52vw, 10px)',
    marginBottom: 'clamp(10px, 1.04vw, 20px)',
    fontWeight: 'bold',
  },
  divider: {
    height: 'clamp(40px, 4.63vh, 50px)',
    marginTop: 'clamp(5px, 0.52vw, 10px)',
  },
  infoValue: {
    fontWeight: 'bold',
    fontSize: 'clamp(14px, 1.04vw, 20px)',
  },
  infoLabel: {
    color: '#6B7280',
    fontSize: 'clamp(12px, 0.83vw, 16px)',
  },
  gridContainer: {
    marginTop: 'clamp(10px, 1.04vw, 20px)',
  },
};

const WeatherInfoSection = ({ selectedAccident, isNarrow }) => {
  const [weather, setWeather] = useState({
    custom: 'N/A',
    direction: 'N/A',
    humidity: 'N/A',
    pytCode: 'N/A',
    rainfall: 'N/A',
    skyCode: 'N/A',
    temperature: 'N/A',
    timeStamp: 'N/A',
  });

  useEffect(() => {
    if (selectedAccident && selectedAccident.weather) {
      setWeather({
        custom: selectedAccident.weather.custom || 'N/A',
        direction: selectedAccident.weather.direction || 'N/A',
        humidity: selectedAccident.weather.humidity || 'N/A',
        pytCode: selectedAccident.weather.pytCode || 'N/A',
        rainfall: selectedAccident.weather.rainfall || 'N/A',
        skyCode: selectedAccident.weather.skyCode || 'N/A',
        temperature: selectedAccident.weather.temperature || 'N/A',
        timeStamp: selectedAccident.weather.timeStamp || 'N/A',
      });
    }
  }, [selectedAccident]);
  const { formatDirection, formatTransmission } = useFormatData();
  return (
    <Box sx={styles.infoSection(isNarrow)}>
      <Typography sx={styles.title}>
        날씨 정보
      </Typography>
      <Typography sx={styles.weatherSummary}>
        {weather.timeStamp} 당시 날씨는 {weather.skyCode} 이었고, {weather.pytCode} 상태였습니다. 충돌 당시 풍속은 {formatDirection(weather.custom)}방향으로 {weather.direction}로 예상됩니다.
      </Typography>
      <Grid container spacing={2} sx={styles.gridContainer}>
        <Grid item xs={6}>
          <Typography sx={styles.infoValue}>
            {weather.temperature}
          </Typography>
          <Typography sx={styles.infoLabel}>
            온도
          </Typography>
        </Grid>
        <Divider orientation="vertical" flexItem sx={styles.divider} />
        <Grid item xs={5}>
          <Typography sx={styles.infoValue}>
            {weather.humidity}
          </Typography>
          <Typography sx={styles.infoLabel}>
            습도
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography sx={styles.infoValue}>
            {weather.direction} 
          </Typography>
          <Typography sx={styles.infoLabel}>
            풍속 [{formatDirection(weather.custom)}]
          </Typography>
        </Grid>
        <Divider orientation="vertical" flexItem sx={styles.divider} />
        <Grid item xs={5}>
          <Typography sx={styles.infoValue}>
            {weather.rainfall}
          </Typography>
          <Typography sx={styles.infoLabel}>
            강수량
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default WeatherInfoSection;