import React, { useState, useEffect, useRef, useContext, useCallback, useMemo } from 'react';
import useIntersectionObserver from './hooks/useIntersectionObserver';
import SafeWebStyles from './styles/SafeWebStyles';
import Carousel from './sections/Carousel';
import Header from './sections/Header';
import ControlSite from './sections/ControlSite';
import AccidentReport from './sections/AccidentReport';
import VideoSection from './sections/VideoSection';
import Footer from './sections/Footer';
import { textContent } from './utils/textContent';
import myrenVideo from '../../styles/video/myrenvideo.mp4';
import scrollup from '../../styles/img/appbar/scrollUp.png';
import scrollupHover from '../../styles/img/appbar/scrollupHover.png';
import { LanguageContext } from '../../context/LanguageContext';
import './styles/Scroll.css';

const SafeWeb = () => {
  const [scale, setScale] = useState(1);
  const [mobileScale, setMobileScale] = useState(1);
  const [isLangDropdownOpen, setIsLangDropdownOpen] = useState(false);
  const [isLangHovered, setIsLangHovered] = useState(false);
  const [showScrollTop, setShowScrollTop] = useState(false);
  const [isScrollTopHovered, setIsScrollTopHovered] = useState(false);
  const containerRef = useRef(null);
  
  const { language } = useContext(LanguageContext);
  const [header1Visible, header1Ref] = useIntersectionObserver({ threshold: 0.1 });
  const [header2Visible, header2Ref] = useIntersectionObserver({ threshold: 0.1 });

  const styles = useMemo(() => SafeWebStyles(scale,mobileScale, showScrollTop, isLangDropdownOpen, isLangHovered, header1Visible, header2Visible), 
  [scale, mobileScale, showScrollTop, isLangDropdownOpen, isLangHovered, header1Visible, header2Visible]);

  const text = useMemo(() => textContent[language] || textContent.ko, [language]);

  const handleScroll = useCallback(() => {
    if (containerRef.current) {
      setShowScrollTop(containerRef.current.scrollTop > 300);
    }
  }, []);

  useEffect(() => {
    const updateScale = () => {
      const windowWidth = window.innerWidth;
      setScale(windowWidth / 1920); // 기존 스케일 유지
      if (windowWidth <= 430) {
        setMobileScale(windowWidth / 430); // 430px 이하일 때 모바일 스케일 적용
      } else {
        setMobileScale(1); // 430px 초과일 때 모바일 스케일은 1로 설정
      }
    };
    window.addEventListener('resize', updateScale);
    updateScale();

  const container = containerRef.current;
  if (container) {
    container.addEventListener('scroll', handleScroll);
  }

  return () => {
    if (container) {
      container.removeEventListener('scroll', handleScroll);
    }
  };
}, [handleScroll]);

  const scrollToTop = useCallback(() => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }, []);

  const scrollToSection = useCallback((sectionId) => {
    const section = document.getElementById(sectionId);
    if (section && containerRef.current) {
      containerRef.current.scrollTo({
        top: section.offsetTop,
        behavior: 'smooth'
      });
    }
  }, []);
  const MultilineText = useCallback(({ text }) => {
    const isMobile = window.innerWidth <= 430;
    
    let content = text;
    if (!isMobile || (isMobile && language === 'ko')) {
      content = text.replace(/\n/g, '<br />');
    }
  
    return <span style={styles.multilineText} dangerouslySetInnerHTML={{ __html: content }} />;
  }, [styles, language]);

  return (
    <div style={styles.container} ref={containerRef} onScroll={handleScroll}>
      <Header 
        scale={scale}
        mobileScale={mobileScale}
        styles={styles}
        text={text}
        scrollToSection={scrollToSection}
        isLangDropdownOpen={isLangDropdownOpen}
        setIsLangDropdownOpen={setIsLangDropdownOpen}
        isLangHovered={isLangHovered}
        setIsLangHovered={setIsLangHovered}
        MultilineText={MultilineText}
        videoSrc={myrenVideo}
      />

      <div id="carousel">
        <Carousel 
          scale={scale}
          text={text}
          mobileScale={mobileScale}
          language={language}
        />
      </div>

      <div id="controlSite">
        <ControlSite 
          scale={scale}
          mobileScale={mobileScale}
          styles={styles}
          text={text}
          language={language}
          header1Ref={header1Ref}
          MultilineText={MultilineText}
        />
      </div>

      <div id="accidentReport">
        <AccidentReport 
          scale={scale}
          mobileScale={mobileScale}
          styles={styles}
          text={text}
          language={language}
          header2Ref={header2Ref}
          MultilineText={MultilineText}
        />
      </div>

      <div id="video">
        <VideoSection 
          scale={scale}
          mobileScale={mobileScale}
          styles={styles}
          text={text}
          videoSrc={myrenVideo}
        />
      </div>

      <Footer 
        scale={scale} 
        mobileScale={mobileScale}
        styles={styles} 
        text={text} 
        language={language}
      />

    <div 
        className={`scroll-top-button ${showScrollTop ? 'visible' : ''}`}
        style={styles.scrollTopButton} 
        onClick={scrollToTop}
        onMouseEnter={() => setIsScrollTopHovered(true)}
        onMouseLeave={() => setIsScrollTopHovered(false)}
      >
        <img 
          src={isScrollTopHovered ? scrollupHover : scrollup} 
          alt="Scroll to top" 
          className="scroll-top-icon"
          style={styles.scrollTopIcon} 
        />
      </div>
    </div>
  );
};

export default React.memo(SafeWeb);