import React from 'react';
import { Box, Typography, TextField, Button } from '@mui/material';
import { useAuth } from '../../context/AuthProvider';
import reply from '../../styles/icons/customer/reply.png';

const styles = {
  responseForm: {
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid #D1D5DB',
    borderRadius: '8px',
    overflow: 'auto',
    padding: 'clamp(10px, 2vw, 16px)',
    backgroundColor: '#FFFFFF',
    // 스크롤 바 스타일 추가
    '&::-webkit-scrollbar': {
      width: '8px',
      height: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',  // 스크롤 바의 색상
      borderRadius: '10px',     // 둥근 모서리
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#555',  // 스크롤 바의 호버 색상
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1', // 스크롤 바 트랙 색상
    },
  },
  userDetails: {
    color: '#111827',
    fontWeight: '500',
    fontSize: 'clamp(14px, 2.5vw, 18px)',
    lineHeight: 'clamp(24px, 4vw, 48px)',
    paddingBottom: 'clamp(5px, 1vw, 8px)',
    borderBottom: '1px solid #E5E7EB',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: 'clamp(10px, 2vw, 16px)',
  },
  button: {
    backgroundColor: '#2563EB',
    '&:hover': {
      backgroundColor: '#1D4ED8',
    },
    textTransform: 'none',
    fontSize: 'clamp(14px, 2.5vw, 20px)',
    fontWeight: '500',
    padding: 'clamp(5px, 1vw, 8px) clamp(15px, 3vw, 30px)',
  },
};

const ResponseForm = ({ complaint, answerBody, setAnswerBody, handleSubmit }) => {
  const { currentUser } = useAuth();
  const isViewer = currentUser?.role === 'viewer';
  const handleChange = (e) => {
    if (isViewer) return;
    setAnswerBody(e.target.value);
  };

  const onSubmit = (e) => {
    if (isViewer) return;
    handleSubmit(e);
  };
  return (
    <>
      <Box component="form" noValidate autoComplete="off" sx={styles.responseForm}>
        <Typography variant="body1" sx={{
          ...styles.userDetails,
          display: 'flex',
          alignItems: 'center',
          gap: 'clamp(5px, 1vw, 10px)'
        }}>
          <img src={reply} alt="reply" style={{ width: 'clamp(10px, 2vw, 30px)', height: 'clamp(10px, 2vw, 30px)' }} />
          <span>
            <strong>{complaint.userId}</strong>
          </span>
        </Typography>
        <Typography variant="body1" sx={{
          ...styles.userDetails,
          color: '#6B7280',
          fontSize: 'clamp(14px, 2.5vw, 18px)'
        }}>
          제목: <span style={{ color: '#141414' }}>{complaint.title}</span>
        </Typography>
        <TextField
          fullWidth
          multiline
          rows={6}
          variant="standard"
          placeholder="내용을 입력하세요"
          value={answerBody}
          onChange={(e) => setAnswerBody(e.target.value)}
          sx={{
            marginTop: 'clamp(10px, 2vw, 16px)',
            '& .MuiInput-underline:before': { borderBottom: 'none' },
            '& .MuiInput-underline:after': { borderBottom: 'none' },
            '& .MuiInput-underline:hover:not(.Mui-disabled):before': { borderBottom: 'none' },
            '& .MuiInputBase-input': {
              fontSize: 'clamp(14px, 2.5vw, 18px)',
            },
          }}
        />
      </Box>
      <Box sx={styles.buttonContainer}>
      <Button 
          variant="contained" 
          sx={{
            ...styles.button,
            opacity: isViewer ? 0.5 : 1,
            cursor: isViewer ? 'not-allowed' : 'pointer',
          }}
          onClick={onSubmit}
          disabled={isViewer}
        >
          보내기
        </Button>
      </Box>
    </>
  );
};

export default ResponseForm;