import React, { useState } from 'react';
import { Container, Paper, Typography, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Snackbar, IconButton } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';

const NoticeDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { notice } = location.state;

  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [editedNotice, setEditedNotice] = useState({ ...notice });
  const [alertOpen, setAlertOpen] = useState(false);

  const handleOpenEditDialog = () => {
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  const handleOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleEditChange = (event) => {
    const { name, value } = event.target;
    setEditedNotice(prevState => ({ ...prevState, [name]: value }));
  };

  const handleEditSave = async () => {
    try {
      await axios.put(`http://localhost:3001/api/updateNotice`, editedNotice);
      setOpenEditDialog(false);
      navigate(`/notice/${notice.noticeId}`, { state: { notice: editedNotice } }); // 변경된 공지사항 세부 페이지로 이동
    } catch (error) {
      console.error('Error saving notice:', error);
      setAlertOpen(true);
    }
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`http://localhost:3001/api/notices/${notice.noticeId}`);
      setOpenDeleteDialog(false);
      navigate('/notice'); // 삭제 후 목록 페이지로 이동
    } catch (error) {
      console.error('Error deleting notice:', error);
      setAlertOpen(true);
    }
  };

  return (
    <Container maxWidth="xl" sx={{ mt: 4 }}>
      <Paper style={{ padding: '20px', height: '90vh', borderRadius: 5 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <IconButton onClick={() => navigate(-1)} sx={{ mr: 2 }}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h4" gutterBottom>
            {notice.title}
          </Typography>
        </Box>
        <Typography variant="subtitle1" color="textSecondary" gutterBottom>
          -{notice.timeStamp}
        </Typography>
        <Typography variant="body1">
          {notice.body}
        </Typography>
        <Box sx={{ mt: 2 }}>
          <Button variant="contained" color="primary" onClick={handleOpenEditDialog} sx={{ mr: 2 }}>
            Edit
          </Button>
          <Button variant="contained" color="secondary" onClick={handleOpenDeleteDialog}>
            Delete
          </Button>
        </Box>
      </Paper>

      {/* Edit Dialog */}
      <Dialog open={openEditDialog} onClose={handleCloseEditDialog}>
        <DialogTitle>Edit Notice</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please update the notice details below.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            name="title"
            label="Title"
            type="text"
            fullWidth
            value={editedNotice.title}
            onChange={handleEditChange}
          />
          <TextField
            margin="dense"
            name="content"
            label="Content"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={editedNotice.content}
            onChange={handleEditChange}
          />
          <TextField
            margin="dense"
            name="category"
            label="Category"
            type="text"
            fullWidth
            value={editedNotice.category}
            onChange={handleEditChange}
          />
          <TextField
            margin="dense"
            name="date"
            label="Date"
            type="date"
            fullWidth
            value={editedNotice.date}
            onChange={handleEditChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleEditSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Dialog */}
      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Delete Notice</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this notice?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={() => setAlertOpen(false)}
        message="An error occurred. Please try again."
      />
    </Container>
  );
};

export default NoticeDetail;
