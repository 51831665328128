import React, { useState, useEffect,useCallback } from 'react';
import {
  Typography, Box, Snackbar, Button,
} from '@mui/material';
import { getDatabase, ref, onValue, off, get, update } from "firebase/database";
import { encodeParams } from '../../utils/encodingUtils';
import MemberPagination from '../../components/member/MemberPagination';
import ReportTabs from '../../components/report/ReportTabs';
import ReportTable from '../../components/report/ReportTable';
import SubLayout from '../../components/layout/SubLayout';
import axios from 'axios';

const RequestReport = () => {
  const [loading, setLoading] = useState(true);
  const [reportRequests, setReportRequests] = useState([]);
  const [selectedReports, setSelectedReports] = useState([]);
  const [currentPageN, setCurrentPageN] = useState(0);
  const [currentPageY, setCurrentPageY] = useState(0);
  const [rowsPerPageN] = useState(10);
  const [rowsPerPageY] = useState(10);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });
  const [tabIndex, setTabIndex] = useState(0);
  const [allSelected, setAllSelected] = useState(false);
  const [hoveredRows, setHoveredRows] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [searchType, setSearchType] = useState('id');

  const searchTypes = [
    { value: 'id', label: 'ID' },
    { value: 'accidentDate', label: '사고 날짜' },
    { value: 'accident_type', label: '사고 종류' },
    { value: 'address', label: '위치' },
  ];

  useEffect(() => {
    const fetchReportRequests = async () => {
      const db = getDatabase();
      const reportRef = ref(db, 'requestReport');

      const handleValueChange = (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          const requests = Object.keys(data).flatMap(userId =>
            Object.keys(data[userId]).map(requestDate => ({
              userId,
              requestDate,
              ...data[userId][requestDate],
            }))
          );
          setReportRequests(requests);
        } else {
          setReportRequests([]);
        }
        setLoading(false);
      };

      onValue(reportRef, handleValueChange);

      return () => {
        off(reportRef, 'value', handleValueChange);
      };
    };

    fetchReportRequests();
  }, []);

  const formatDateTime = (timestamp) => {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };

  const handleRowClick = async (report) => {
    const db = getDatabase();
    const accidentListRef = ref(db, `accidentList/${report.userId}/${report.timeStamp}`);

    try {
      const snapshot = await get(accidentListRef);
      if (snapshot.exists()) {
        const accidentData = snapshot.val();
        if (accidentData.reportUrl) {
          window.open(accidentData.reportUrl, '_blank');
        } else {
          setSnackbar({ open: true, message: '보고서 URL이 없습니다.', severity: 'info' });
        }
      } else {
        setSnackbar({ open: true, message: '사고 데이터를 찾을 수 없습니다.', severity: 'error' });
      }
    } catch (error) {
      setSnackbar({ open: true, message: '데이터를 불러오는 중 오류가 발생했습니다.', severity: 'error' });
    }
  };

  useEffect(() => {
    const db = getDatabase();
    const reportRef = ref(db, 'requestReport');

    const handleValueChange = (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        const requests = Object.keys(data).flatMap(userId =>
          Object.keys(data[userId]).map(requestDate => ({
            userId,
            requestDate,
            ...data[userId][requestDate],
          }))
        );
        setReportRequests(requests);
      } else {
        setReportRequests([]);
      }
      setLoading(false);
    };

    onValue(reportRef, handleValueChange);

    return () => {
      off(reportRef, 'value', handleValueChange);
    };
  }, []);
  const handleRequestReports = useCallback(async () => {
    try {
      const db = getDatabase();
      for (const report of selectedReports) {
        const cid = generateCID(); // CID 생성 함수 (아래에 정의)
        const accidentRef = ref(db, `accidentList/${report.userId}/${report.timeStamp}`);
        
        // 기존 데이터를 가져옵니다.
        const snapshot = await get(accidentRef);
        if (snapshot.exists()) {
          const accidentData = snapshot.val();
          
          // cid를 추가하고 데이터를 업데이트합니다.
          await update(accidentRef, { ...accidentData, cid: cid });
        }
      }
  
      await axios.post('https://us-central1-safecar-1487e.cloudfunctions.net/api/processReports', {
        reports: selectedReports
      });
      setSnackbar({ open: true, message: '보고서 요청이 성공적으로 처리되었습니다.', severity: 'success' });
      setSelectedReports([]);
    } catch (error) {
      console.error("Error processing reports:", error);
      setSnackbar({ open: true, message: '오류가 발생했습니다.', severity: 'error' });
    }
  }, [selectedReports]);
  
  // CID 생성 함수
  const generateCID = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const numbers = '0123456789';
    
    const generateSegment = (allowNumbers = true) => {
      let segment = '';
      for (let i = 0; i < 4; i++) {
        if (allowNumbers && Math.random() < 0.5) {
          segment += numbers.charAt(Math.floor(Math.random() * numbers.length));
        } else {
          segment += characters.charAt(Math.floor(Math.random() * characters.length));
        }
      }
      return segment;
    };
  
    const cid = `${generateSegment()}-${generateSegment()}-${generateSegment()}`;
  
    const digitCount = cid.replace(/[^0-9]/g, '').length;
    if (digitCount < 6) {
      let additionalDigitsNeeded = 6 - digitCount;
      let cidArray = cid.split('');
      
      for (let i = 0; i < cidArray.length && additionalDigitsNeeded > 0; i++) {
        if (!numbers.includes(cidArray[i])) {
          cidArray[i] = numbers.charAt(Math.floor(Math.random() * numbers.length));
          additionalDigitsNeeded--;
        }
      }
      
      return cidArray.join('');
    }
  
    return cid;
  };
  const handleSelectReport = (report) => {
    setSelectedReports(prevSelected => {
      const isSelected = prevSelected.some(selected => selected.userId === report.userId && selected.requestDate === report.requestDate);
      if (isSelected) {
        return prevSelected.filter(selected => !(selected.userId === report.userId && selected.requestDate === report.requestDate));
      }
      return [...prevSelected, report];
    });
  };

  const handleSelectAll = () => {
    const incompleteReports = reportRequests.filter(report => report.complete !== 'Y');
    if (allSelected) {
      setSelectedReports([]);
    } else {
      setSelectedReports(incompleteReports);
    }
    setAllSelected(!allSelected);
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };
  const filterReports = (reports) => {
    return reports.filter((report) => {
      if (searchTerm === '') return true;
      switch (searchType) {
        case 'id':
          return report.userId === searchTerm; // 정확히 일치
        case 'accidentDate':
          return report.timeStamp && formatDateTime(report.timeStamp).toLowerCase().includes(searchTerm.toLowerCase());
        case 'accident_type':
          return report.accident_type && report.accident_type.toLowerCase().includes(searchTerm.toLowerCase());
        case 'address':
          return report.address && report.address.toLowerCase().includes(searchTerm.toLowerCase());
        default:
          return true;
      }
    });
  };

  const filteredReportsN = filterReports(reportRequests.filter(report => report.complete !== 'Y'))
    .sort((a, b) => {
      if (a.requestDate === b.requestDate) {
        return new Date(b.timeStamp || 0) - new Date(a.timeStamp || 0);
      }
      return new Date(b.requestDate || 0) - new Date(a.requestDate || 0);
    });

  const filteredReportsY = filterReports(reportRequests.filter(report => report.complete === 'Y'))
    .sort((a, b) => {
      if (a.completeTimestamp === b.completeTimestamp) {
        return new Date(b.timeStamp || 0) - new Date(a.timeStamp || 0);
      }
      return new Date(b.completeTimestamp || 0) - new Date(a.completeTimestamp || 0);
    });

  const paginatedReportsN = filteredReportsN.slice(
    currentPageN * rowsPerPageN,
    currentPageN * rowsPerPageN + rowsPerPageN
  );

  const paginatedReportsY = filteredReportsY.slice(
    currentPageY * rowsPerPageY,
    currentPageY * rowsPerPageY + rowsPerPageY
  );

  return (
    <SubLayout>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 'clamp(10px, 1vh, 20px)' }}>
          <Typography variant="h4" sx={{ fontWeight: 'bold', marginBottom: 'clamp(10px, 1vh, 20px)', fontSize: 'clamp(24px, 2vw, 36px)' }}>
            보고서 관리 요청
          </Typography>
        </Box>
        <ReportTabs
          tabIndex={tabIndex}
          setTabIndex={setTabIndex}
          handleRequestReports={handleRequestReports}
          selectedReports={selectedReports}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          searchType={searchType}
          setSearchType={setSearchType}
          searchTypes={searchTypes}
        />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', flexGrow: 1 }}>
          <ReportTable
            tabIndex={tabIndex}
            paginatedReportsN={paginatedReportsN}
            paginatedReportsY={paginatedReportsY}
            selectedReports={selectedReports}
            handleSelectReport={handleSelectReport}
            handleSelectAll={handleSelectAll}
            allSelected={allSelected}
            handleRowClick={handleRowClick}
            hoveredRows={hoveredRows}
            setHoveredRows={setHoveredRows}
            formatDateTime={formatDateTime}
            currentPage={tabIndex === 0 ? currentPageN : currentPageY}
            rowsPerPage={tabIndex === 0 ? rowsPerPageN : rowsPerPageY}
        
          />
        </Box>

      <Box sx={{ display: 'flex', justifyContent: 'center', position: 'fixed', bottom: '0', left: '0', right: '0', padding: 'clamp(10px, 1vh, 20px)', boxSizing: 'border-box', background: 'white' }}>
        <MemberPagination
          count={tabIndex === 0 ? Math.ceil(filteredReportsN.length / rowsPerPageN) : Math.ceil(filteredReportsY.length / rowsPerPageY)}
          page={tabIndex === 0 ? currentPageN + 1 : currentPageY + 1}
          onChange={(event, newPage) => tabIndex === 0 ? setCurrentPageN(newPage - 1) : setCurrentPageY(newPage - 1)}
        />
      </Box>
      <Snackbar
      open={snackbar.open}
      onClose={handleCloseSnackbar}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        bgcolor: 'rgba(0, 0, 0, 0.5)',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: 'clamp(200px, 90%, 400px)',
          maxHeight: '90vh',
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
          borderRadius: 'clamp(5px, 1vw, 10px)',
          padding: 'clamp(10px, 3vh, 30px)',
          backgroundColor: 'white',
          color: 'black',
        }}
      >
        <Typography
          sx={{
            fontWeight: 'bold',
            fontSize: 'clamp(18px, 2vw, 16px)',
            marginBottom: 'clamp(10px, 2vh, 20px)',
            lineHeight: 1.5,
            textAlign: 'center',
          }}
        >
          {snackbar.message}
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', marginTop: 'auto' }}>
          <Button
            variant="contained"
            onClick={handleCloseSnackbar}
            sx={{ 
              marginTop: 'clamp(10px, 2vh, 20px)', 
              fontSize: 'clamp(10px, 1.5vw, 14px)',
              padding: 'clamp(5px, 1vh, 10px) clamp(10px, 2vw, 20px)',
            }}
          >
            확인
          </Button>
        </Box>
      </Box>
    </Snackbar>
  </SubLayout>
  );
};

export default RequestReport;
