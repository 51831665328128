import React, { useState, useEffect, useRef } from 'react';
import emailjs from 'emailjs-com';
import '../styles/InquiryModal.css';

const InquiryModal = ({ isOpen, onClose, scale = 1, text }) => {
  const [email, setEmail] = useState('');
  const [content, setContent] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isEmailFocused, setIsEmailFocused] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState(null);
  
  const modalRef = useRef(null);

  useEffect(() => {
    const validateEmail = (email) => {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return regex.test(email);
    };

    setIsEmailValid(validateEmail(email));
    setIsFormValid(validateEmail(email) && content.trim() !== '');
  }, [email, content]);

  useEffect(() => {
    if (modalRef.current) {
      modalRef.current.style.setProperty('--scale', scale);
    }
  }, [scale]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!isFormValid) return;

    emailjs.send(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
      {
        from_name: email,
        message: content
      },
      process.env.REACT_APP_EMAILJS_USER_ID
    )
    .then((response) => {
      console.log('SUCCESS!', response.status, response.text);
      setSubmissionStatus('success');
      setEmail('');
      setContent('');
      setIsEmailFocused(false);
      setIsFormValid(false);
    }, (err) => {
      console.log('FAILED...', err);
      setSubmissionStatus('error');
    });
  };

  const resetModal = () => {
    setSubmissionStatus(null);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay" ref={modalRef}>
      <div className="modal-content">
        <div className="close-button-wrapper">
          <button onClick={resetModal} className="close-button">×</button>
        </div>
        {submissionStatus === null ? (
          <>
            <h2 className="title">{text.inquiryTitle}</h2>
            <form onSubmit={handleSubmit} className="form">
              <div className="field-group">
                <label className="label">
                  {text.emailAddress} <span className="required">*</span>
                  {isEmailFocused && !isEmailValid && <span className="error-text"> ({text.invalidEmailMessage})</span>}
                </label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onFocus={() => setIsEmailFocused(true)}
                  onBlur={() => setIsEmailFocused(false)}
                  placeholder={text.emailPlaceholder}
                  className="input"
                  required
                />
              </div>
              <div className="field-group">
                <label className="label">
                  {text.inquiryContent} <span className="required">*</span>
                </label>
                <textarea
                  value={content}
                  onChange={(e) => setContent(e.target.value)}
                  placeholder={text.contentPlaceholder}
                  className="textarea"
                  required
                />
              </div>
              <button
                type="submit"
                className={`submit-button ${!isFormValid ? 'submit-button-disabled' : ''}`}
                disabled={!isFormValid}
              >
                {text.submitButton}
              </button>
            </form>
          </>
        ) : (
          <div className="message-container">
            <h2 className="title">
              {submissionStatus === 'success' ? text.successMessage : text.errorMessage}
            </h2>
          </div>
        )}
      </div>
    </div>
  );
};

export default InquiryModal;