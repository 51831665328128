import React from 'react';
import { Box, Paper } from '@mui/material';

const MainLayout = ({ children }) => {
  return (
    <Box 
      sx={{ 
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        paddingTop: 'clamp(60px, 8vh, 80px)', // AppBar 높이만큼 상단 여백
      }}
    >
      <Paper 
        sx={{ 
          width: '100%', 
          height: '100%', 
          boxShadow: 3, 
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            width: 'clamp(6px, 0.42vw, 8px)',
            height: 'clamp(6px, 0.42vw, 8px)',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888',
            borderRadius: '4px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#555',
          },
          '@media print': {
            boxShadow: 'none',
            border: 'none',
          },
        }}
      >
        {children}
      </Paper>
    </Box>
  );
};

export default MainLayout;