import React, { useState, useRef } from 'react';
import { styled } from '@mui/system';
import { useAuth } from '../../context/AuthProvider';
import {
  Dialog, DialogContent, DialogTitle, TextField, Grid, Box, Button, IconButton, InputAdornment, Typography, Snackbar, DialogActions, DialogContentText
} from '@mui/material';
import { Close as CloseIcon, Clear as ClearIcon, Visibility, VisibilityOff } from '@mui/icons-material';
import { ref, update } from 'firebase/database';
import { database } from '../../firebase'; // Ensure correct import path
import CarCC from '../../styles/icons/member/CarCC.png';
import CarModel from '../../styles/icons/member/CarModel.png';
import CarInfo from '../../styles/icons/member/CarInfo.png';
import CarNumber from '../../styles/icons/member/CarNumber.png';
import GuardianName from '../../styles/icons/member/GuardianName.png';
import GuardianNumber from '../../styles/icons/member/GuardianNumber.png';
import Insurance from '../../styles/icons/member/Insurance.png';
import MemberName from '../../styles/icons/member/MemberName.png';
import MemberNumber from '../../styles/icons/member/MemberNumber.png';
import Password from '../../styles/icons/member/Password.png';
import useWindowDimensions from '../../hook/useWindowDimensions';
import RenderTextField from './RenderTextField';

const CustomDialog = styled(Dialog)(({ theme, isNarrow }) => ({
  '& .MuiDialog-paper': {
    width: isNarrow ? '80vw' : 'clamp(600px, 55vw, 1000px)',
    height: isNarrow ? '50vh' : 'clamp(400px, 57vh, 700px)',
    maxWidth: 'none',
    maxHeight: 'none',
    borderRadius: 'clamp(10px, 1.04vw, 20px)',
    overflow: 'hidden',
  },
}));

const CustomDialogContent = styled(DialogContent)({
  height: 'calc(100% - 48px)',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: 'clamp(4px, 0.42vw, 8px)',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#f1f1f1',
    borderRadius: 'clamp(5px, 0.52vw, 10px)',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#888',
    borderRadius: 'clamp(5px, 0.52vw, 10px)',
    '&:hover': {
      backgroundColor: '#555',
    },
  },
});

const MemberDetailDialog = ({ open, handleClose, handleSave, handleChange, currentMember }) => {
  const { currentUser } = useAuth();
  const isViewer = currentUser?.role === 'viewer';
  const [showPassword, setShowPassword] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const { isNarrow } = useWindowDimensions();

  const handleImmediateChange = (name, value) => {
    if (isViewer) return; // viewer는 수정 불가
    
    const newValue = value === '' ? null : value;
    handleChange(name, newValue);

    const updates = {};
    updates[`userInFo/${currentMember.userId}/${name}`] = newValue;
    update(ref(database), updates)
      .then(() => {
      })
      .catch((error) => {
      });
  };

  const handleDelete = async () => {
    try {
      const updates = {};
      updates[`userInFo/${currentMember.userId}/userDelete`] = 'Y';
      await update(ref(database), updates);
      setDeleteDialogOpen(false);
      handleClose();
    } catch (error) {
      console.error('Error deleting member:', error);
    }
  };

 
  return (
    <CustomDialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth={false}
      isNarrow={isNarrow}
    >
      <DialogTitle sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        padding: 'clamp(10px, 1vw, 20px)',
      }}>
        <Typography variant="h6" sx={{ fontSize: 'clamp(18px, 1.15vw, 24px)', fontWeight: 'bold' }}>
          회원 상세 정보
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            width: 'clamp(24px, 1.61vw, 31px)',
            height: 'clamp(24px, 1.61vw, 31px)',
            color: '#444444',
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <CustomDialogContent sx={{ borderBottom: 'none' }}>
        <Grid container spacing={1} sx={{ padding: '1.04vw' }}>  {/* 20px for a 1920px wide screen */}
          <Grid item xs={12} sm={6}>
            <RenderTextField 
              label="회원이름"
              name="userName"
              value={currentMember?.userName}
              icon={MemberName}
              onChangeHandler={(e) => handleImmediateChange('userName', e.target.value)}
              showPassword={showPassword}
              setShowPassword={setShowPassword}
            />
            <RenderTextField 
              label="회원연락처"
              name="userPhone"
              value={currentMember?.userPhone}
              icon={MemberNumber}
              onChangeHandler={(e) => handleImmediateChange('userPhone', e.target.value)}
              showPassword={showPassword}
              setShowPassword={setShowPassword}
            />
            <RenderTextField 
              label="차량 번호"
              name="userCarNumber"
              value={currentMember?.userCarNumber}
              icon={CarNumber}
              onChangeHandler={(e) => handleImmediateChange('userCarNumber', e.target.value)}
              showPassword={showPassword}
              setShowPassword={setShowPassword}
            />
            <RenderTextField 
              label="차량 모델"
              name="userCarModelName"
              value={currentMember?.userCarModelName}
              icon={CarModel}
              onChangeHandler={(e) => handleImmediateChange('userCarModelName', e.target.value)}
              showPassword={showPassword}
              setShowPassword={setShowPassword}
            />
            <RenderTextField 
              label="차량 CC"
              name="userCarModelCC"
              value={currentMember?.userCarModelCC}
              icon={CarCC}
              onChangeHandler={(e) => handleImmediateChange('userCarModelCC', e.target.value)}
              showPassword={showPassword}
              setShowPassword={setShowPassword}
            />
            <RenderTextField 
              label="차 정보"
              name="userCarInfo"
              value={currentMember?.userCarInfo}
              icon={CarInfo}
              onChangeHandler={(e) => handleImmediateChange('userCarInfo', e.target.value)}
              showPassword={showPassword}
              setShowPassword={setShowPassword}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <RenderTextField 
              label="보호자 이름"
              name="userGuardianName"
              value={currentMember?.userGuardianName}
              icon={GuardianName}
              onChangeHandler={(e) => handleImmediateChange('userGuardianName', e.target.value)}
              showPassword={showPassword}
              setShowPassword={setShowPassword}
            />
            <RenderTextField 
              label="보호자 연락처"
              name="userGuardianNumber"
              value={currentMember?.userGuardianNumber}
              icon={GuardianNumber}
              onChangeHandler={(e) => handleImmediateChange('userGuardianNumber', e.target.value)}
              showPassword={showPassword}
              setShowPassword={setShowPassword}
            />
            <RenderTextField 
              label="보험사"
              name="insurance_name"
              value={currentMember?.insurance_name}
              icon={Insurance}
              onChangeHandler={(e) => handleImmediateChange('insurance_name', e.target.value)}
              showPassword={showPassword}
              setShowPassword={setShowPassword}
            />
            <RenderTextField 
              label="비밀번호"
              name="userPW"
              value={currentMember?.userPW}
              icon={Password}
              onChangeHandler={(e) => handleImmediateChange('userPW', e.target.value)}
              showPassword={showPassword}
              setShowPassword={setShowPassword}
            />
          </Grid>
        </Grid>
      </CustomDialogContent>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 'clamp(10px, 1.04vw, 20px)', pt: 0 }}>
        {currentMember.userDelete === 'Y' ? (
          <Typography variant="body2" sx={{ 
            color: 'red', 
            fontWeight: 'bold', 
            marginRight: 'clamp(5px, 1vw, 20px)',
            fontSize: 'clamp(12px, 0.83vw, 16px)'
          }}>
            이미 탈퇴한 회원입니다.
          </Typography>
        ) : (
          <Button 
            onClick={() => !isViewer && setDeleteDialogOpen(true)}
            disabled={isViewer}
            sx={{
              color: isViewer ? '#9CA3AF' : '#6B7280',
              textDecoration: 'underline',
              fontSize: 'clamp(12px, 0.83vw, 16px)', 
              backgroundColor: 'transparent',
              cursor: isViewer ? 'not-allowed' : 'pointer',
              opacity: isViewer ? 0.5 : 1,
              '&:hover': {
                color: isViewer ? '#9CA3AF' : '#2563EB',
                backgroundColor: 'transparent',
                textDecoration: 'underline',
              },
              '&.Mui-disabled': {
                color: '#9CA3AF',
              }
            }}
          >
            회원탈퇴하기
          </Button>
        )}
      </Box>

      <Snackbar 
        open={alertOpen} 
        autoHideDuration={6000} 
        onClose={() => setAlertOpen(false)} 
        message="회원정보가 성공적으로 수정되었습니다."
        sx={{
          '& .MuiSnackbarContent-root': {
            fontSize: 'clamp(12px, 1vw, 16px)',
            padding: 'clamp(8px, 1vw, 16px)',
          }
        }}
      />
      <Dialog 
        open={deleteDialogOpen} 
        onClose={() => setDeleteDialogOpen(false)}
        PaperProps={{
          sx: {
            width: 'clamp(280px, 26.04vw, 500px)',
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
            borderRadius: 'clamp(8px, 0.83vw, 16px)',
            padding: 'clamp(16px, 1.56vw, 30px)',
          }
        }}
      >
        <Box>
          <DialogContent>
            <DialogContentText sx={{ 
              fontWeight: 'bold', 
              color: 'black', 
              fontSize: 'clamp(14px, 0.83vw, 18px)'
            }}>
              정말 삭제하시겠습니까?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button 
              onClick={() => setDeleteDialogOpen(false)} 
              color="primary" 
              sx={{ 
                fontWeight: 'bold', 
                fontSize: 'clamp(12px, 0.73vw, 16px)'
              }}
            >
              취소
            </Button>
            <Button 
              onClick={handleDelete} 
              color="error" 
              sx={{ 
                fontWeight: 'bold', 
                fontSize: 'clamp(12px, 0.73vw, 16px)'
              }}
            >
              삭제
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </CustomDialog>
  );
};

export default MemberDetailDialog;
