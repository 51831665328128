import React, { useEffect, useRef, useState, useCallback } from 'react';
import MarkerBlue from '../../styles/icons/accident/MarkerBlue.png';
import MarkerRed from '../../styles/icons/accident/MarkerRed.png';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const KakaoMap = ({ markers, center, onMarkerClick }) => {
  const mapRef = useRef(null);
  const mapInstance = useRef(null);
  const clustererRef = useRef(null);
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [initialLocation] = useState({ lat: 37.554722, lng: 126.970833 }); // 서울역 좌표

  useEffect(() => {
    const loadKakaoMapScript = () => {
      if (document.getElementById('kakao-map-script')) {
        setScriptLoaded(true);
        return;
      }

      const script = document.createElement('script');
      script.id = 'kakao-map-script';
      script.async = true;
      script.src = `https://dapi.kakao.com/v2/maps/sdk.js?appkey=${process.env.REACT_APP_KAKAO_MAP_API_KEY}&libraries=clusterer&autoload=false`;
      script.onload = () => {
        window.kakao.maps.load(() => {
          setScriptLoaded(true);
        });
      };
      script.onerror = () => {
        console.error('Failed to load Kakao Maps script.');
      };
      document.head.appendChild(script);
    };

    loadKakaoMapScript();
  }, []);

  const initializeMap = useCallback(() => {
    if (!window.kakao || !window.kakao.maps || mapInstance.current) return;

    const container = mapRef.current;
    const options = {
      center: new window.kakao.maps.LatLng(initialLocation.lat, initialLocation.lng),
      level: 5,
    };

    mapInstance.current = new window.kakao.maps.Map(container, options);

    clustererRef.current = new window.kakao.maps.MarkerClusterer({
      map: mapInstance.current,
      averageCenter: true,
      minLevel: 5,
      disableClickZoom: true,
    });

    addMarkers(markers);
  }, [initialLocation, markers]);

  const addMarkers = useCallback((markers) => {
    if (!window.kakao || !window.kakao.maps) return;

    const markerObjects = [];

    markers.forEach(markerData => {
      // 마커 데이터의 위치가 없을 경우 기본 위치 사용
      const markerPosition = markerData.position
        ? new window.kakao.maps.LatLng(markerData.position.lat, markerData.position.lng)
        : new window.kakao.maps.LatLng(initialLocation.lat, initialLocation.lng);
        
      const markerImageSrc = markerData.isSelected ? MarkerRed : MarkerBlue;
      const markerImageSize = new window.kakao.maps.Size(24, 35);
      const markerImage = new window.kakao.maps.MarkerImage(markerImageSrc, markerImageSize);

      const marker = new window.kakao.maps.Marker({
        position: markerPosition,
        title: markerData.title,
        image: markerImage,
      });

      window.kakao.maps.event.addListener(marker, 'click', () => {
        if (onMarkerClick) onMarkerClick(markerData.accident);
      });

      markerObjects.push(marker);
    });

    clustererRef.current.addMarkers(markerObjects);

    window.kakao.maps.event.addListener(clustererRef.current, 'clusterclick', (cluster) => {
      const level = mapInstance.current.getLevel() - 1;
      mapInstance.current.setLevel(level, { anchor: cluster.getCenter() });
    });
  }, [onMarkerClick, initialLocation]);

  useEffect(() => {
    if (scriptLoaded && mapRef.current && !mapInstance.current) {
      initializeMap();
    }
  }, [scriptLoaded, initializeMap]);

  useEffect(() => {
    if (center && mapInstance.current) {
      mapInstance.current.setCenter(new window.kakao.maps.LatLng(center.lat, center.lng));
    }
  }, [center]);

  useEffect(() => {
    if (mapInstance.current && clustererRef.current) {
      clustererRef.current.clear();
      addMarkers(markers);
    }
  }, [markers, addMarkers]);

  if (!scriptLoaded) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <CircularProgress />
      </Box>
    );
  }

  return <div ref={mapRef} style={{ width: '100%', height: '100%' }} />;
};

export default KakaoMap;
