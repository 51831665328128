import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#2563EB', // Gothic
      light: '#9ac5d1', // 밝은 버전의 Gothic
      dark: '#253B85', // 어두운 버전의 Gothic
      extraLight: '#b5d8e1', // 더 밝은 버전의 Gothic
      extraDark: '#274a55'  // 더 어두운 버전의 Gothic
    },
    secondary: {
      main: '#F2BC79', // 노른자색
      light: '#f5cc9c', // 밝은 버전의 노른자색
      dark: '#b28956', // 어두운 버전의 노른자색
      extraLight: '#f8dcb6', // 더 밝은 버전의 노른자색
      extraDark: '#8a623b'  // 더 어두운 버전의 노른자색
    },
    error: {
      main: '#F28080', // LightCoral
      light: '#f5a3a3', // 밝은 버전의 LightCoral
      dark: '#af5757', // 어두운 버전의 LightCoral
      extraLight: '#f8b8b8', // 더 밝은 버전의 LightCoral
      extraDark: '#8b4040'  // 더 어두운 버전의 LightCoral
    },
    background: {
      default: '#F3F4F6', // Almond
      paper: '#fff', // Wewak
      lightDefault: '#E5E5E5', // 더 밝은 버전의 Almond
      darkDefault: '#c1a693', // 어두운 버전의 Almond
      lightPaper: '#f5c2be', // 더 밝은 버전의 Wewak
      darkPaper: '#b38684'  // 어두운 버전의 Wewak
    },
  },
  typography: {
    fontFamily: 'Pretendard, sans-serif',
  }
});

export default theme;
