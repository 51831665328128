import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Container, CircularProgress, Box, Typography , Button} from '@mui/material';
import { database, ref, get, onChildAdded, onChildChanged, onChildRemoved, off } from '../../firebase';
import { decodeParams } from '../../utils/encodingUtils';
import Page1 from './Page1';
import Page2 from './Page2';
import axios from 'axios';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useReactToPrint } from 'react-to-print';


const fetchWhat3WordsAddress = async (lat, lng) => {
  try {
    const response = await axios.get(
      `https://api.what3words.com/v3/convert-to-3wa?coordinates=${lat},${lng}&key=${process.env.REACT_APP_W3W_API_KEY}&language=en`
    );
    return response.data.words;
  } catch (error) {
    console.error('What3Words API error:', error);
    return 'N/A';
  }
};

const ReportPreviewPage = () => {
  const { encodedUserId, encodedTimeStamp } = useParams();
  const { userId, timeStamp } = decodeParams(encodedUserId, encodedTimeStamp);
  const [selectedIncident, setSelectedIncident] = useState(null);
  const [loading, setLoading] = useState(true);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [what3Words, setWhat3Words] = useState("");
  const [uid, setUid] = useState("");
  const [insuranceInfo, setInsuranceInfo] = useState({});

  useEffect(() => {
    if (userId && timeStamp) {
      const incidentRef = ref(database, `accidentList/${userId}/${timeStamp}`);
      
      const fetchData = async () => {
        const incidentSnap = await get(incidentRef);
        if (incidentSnap.exists()) {
          const data = incidentSnap.val();
          setSelectedIncident(data);
          const lat = parseFloat(data.latitude);
          const lng = parseFloat(data.longitude);
          if (!isNaN(lat) && !isNaN(lng)) {
            setLatitude(lat);
            setLongitude(lng);
          }
          // cid가 없는 경우에만 uid 생성
          if (!data.cid) {
            setUid(generateUID());
          }
          fetchUserInfo(userId).then((info) => {
            setInsuranceInfo(info);
          });
        }
        setLoading(false);
      };
      fetchData();

      onChildAdded(incidentRef, fetchData);
      onChildChanged(incidentRef, fetchData);
      onChildRemoved(incidentRef, fetchData);

      return () => {
        off(incidentRef, 'child_added', fetchData);
        off(incidentRef, 'child_changed', fetchData);
        off(incidentRef, 'child_removed', fetchData);
      };
    }
  }, [userId, timeStamp]);

  useEffect(() => {
    if (latitude !== null && longitude !== null) {
      fetchWhat3WordsAddress(latitude, longitude).then(setWhat3Words);
    }
  }, [latitude, longitude]);

  const fetchUserInfo = async (userId) => {
    try {
      const userInfoRef = ref(database, `userInFo/${userId}`);
      const userInfoSnap = await get(userInfoRef);

      if (userInfoSnap.exists()) {
        return userInfoSnap.val();
      } else {
        console.error("No such document!");
        return {};
      }
    } catch (error) {
      console.error("Error fetching document:", error);
      return {};
    }
  };

  const generateUID = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const numbers = '0123456789';
    
    const generateSegment = (allowNumbers = true) => {
      let segment = '';
      for (let i = 0; i < 4; i++) {
        if (allowNumbers && Math.random() < 0.5) {
          segment += numbers.charAt(Math.floor(Math.random() * numbers.length));
        } else {
          segment += characters.charAt(Math.floor(Math.random() * characters.length));
        }
      }
      return segment;
    };

    const uid = `${generateSegment()}-${generateSegment()}-${generateSegment()}`;

    const digitCount = uid.replace(/[^0-9]/g, '').length;
    if (digitCount < 6) {
      let additionalDigitsNeeded = 6 - digitCount;
      let uidArray = uid.split('');
      
      for (let i = 0; i < uidArray.length && additionalDigitsNeeded > 0; i++) {
        if (!numbers.includes(uidArray[i])) {
          uidArray[i] = numbers.charAt(Math.floor(Math.random() * numbers.length));
          additionalDigitsNeeded--;
        }
      }
      
      return uidArray.join('');
    }

    return uid;
  };

  const rounded = (value) => (value ? Math.round(value * 1000) / 1000 : '-');


  useEffect(() => {
    const updateScale = () => {
      const pageWidth = 210; // Page width in mm
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;
      const scaleWidth = screenWidth / (pageWidth * 3.7795275591);
      const scaleHeight = screenHeight / (297 * 3.7795275591); // Assuming A4 size height in mm
      const scale = Math.min(scaleWidth, scaleHeight);
      document.documentElement.style.setProperty('--scale', scale);
    };

    updateScale();
    window.addEventListener('resize', updateScale);
    return () => window.removeEventListener('resize', updateScale);
  }, []);

  // 프린트할 컴포넌트의 ref
  const componentRef = useRef();

// 프린트 핸들러
const handlePrint = useReactToPrint({
  content: () => componentRef.current,
  documentTitle: `사고보고서_${selectedIncident?.cid || uid}`,
  pageStyle: `
    @page {
      size: 210mm 297mm;
      margin: 0;
    }
    @media print {
      html, body {
        margin: 0;
        padding: 0;
      }
      body * {
        visibility: hidden;
      }
      #printSection, #printSection * {
        visibility: visible;
      }
      #printSection {
        position: absolute;
        left: 0;
        top: 0;
      }
      .page {
        page-break-after: always;
        height: 297mm;
        width: 210mm;
        margin: 0;
      }
      .page:last-child {
        page-break-after: avoid;
      }
    }
  `,
});
  // Styles for the download button
  const downloadButtonStyle = {
    position: 'fixed',
    right: '20px',
    top: '20px',
    backgroundColor: '#2563EB',
    color: 'white',
    '&:hover': {
      backgroundColor: '#1E40AF',
    },
    display: {
      xs: 'none',  // Hidden on mobile
      md: 'flex'   // Visible on desktop
    },
    zIndex: 1000
  };

  if (loading) {
    return (
      <Container sx={{ mt: 4, display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Container>
    );
  }

  if (!selectedIncident) {
    return (
      <Container sx={{ mt: 4, display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Typography variant="h5">사고를 찾을 수 없습니다</Typography>
      </Container>
    );
  }
  return (
    <Container sx={{ 
      bgcolor: 'white', 
      height: '100vh', 
      overflowY: 'auto', 
      padding: 0, 
      display: 'flex', 
      justifyContent: 'center',
      position: 'relative'
    }}>
      <Button
        variant="contained"
        startIcon={<FileDownloadIcon />}
        onClick={handlePrint}
        sx={downloadButtonStyle}
      >
        보고서 저장
      </Button>
      
      <Box 
        ref={componentRef}
        id="printSection"
        sx={{ 
          position: 'relative',
          width: '100%',
          transform: 'scale(var(--scale))',
          transformOrigin: 'top left',
          '@media print': {
            transform: 'none'
          }
        }}
      >
        <Page1
          cid={selectedIncident?.cid || uid}
          selectedIncident={selectedIncident}
          latitude={latitude}
          longitude={longitude}
          what3Words={what3Words}
          insuranceInfo={insuranceInfo}
          rounded={rounded}
        />
        <Page2 
          allData={selectedIncident.allData} 
          car_speed={selectedIncident.car_speed}  
        />
      </Box>
    </Container>
  );
};

export default ReportPreviewPage;