import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(() => {
    const storedUser = localStorage.getItem('currentUser');
    return storedUser ? JSON.parse(storedUser) : null;
  });

  useEffect(() => {
    if (currentUser) {
      localStorage.setItem('currentUser', JSON.stringify(currentUser));
    } else {
      localStorage.removeItem('currentUser');
    }
  }, [currentUser]);

  const login = async (username, password, mode) => {
    try {
      if (mode === 'view') {
        if (username === 'see1' && password === 'see1') {
          const user = { username, role: 'viewer', canEdit: false };
          setCurrentUser(user);
          return user;
        } else if (username === 'see2' && password === 'see2') {  // 새로운 viewer 계정 추가
          const user = { username, role: 'viewer', canEdit: false };
          setCurrentUser(user);
          return user;
        }
      } else if (mode === 'edit') {
        if (username === 'mainAdmin' && password === 'admin123') {
          const user = { username, role: 'admin', canEdit: true };
          setCurrentUser(user);
          return user;
        } else if (username === 'myren03' && password === 'abc0032@') {
          const user = { username, role: 'specialUser', canEdit: true };
          setCurrentUser(user);
          return user;
        }
      }
      throw new Error('Invalid credentials');
    } catch (error) {
      console.error('Login error:', error.message);
      throw error;
    }
  };

  const logout = () => {
    setCurrentUser(null);
    localStorage.removeItem('currentUser');
  };

  return (
    <AuthContext.Provider value={{ currentUser, login, logout, setCurrentUser }}>
      {children}
    </AuthContext.Provider>
  );
};