import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';

// Create Notice context
const NoticeContext = createContext();

export const NoticeProvider = ({ children }) => {
  const [notices, setNotices] = useState([]);

  // Fetch notices from Firebase
  useEffect(() => {
    const fetchNotices = async () => {
      try {
        const response = await axios.get('https://us-central1-safecar-1487e.cloudfunctions.net/api/getAllNotices');
        const data = response.data;

        // Convert the object to an array
        const noticesArray = Object.keys(data).map(key => ({ ...data[key], noticeId: key }));
        setNotices(noticesArray);

      } catch (error) {
        console.error("Error fetching notices:", error);
      }
    };

    fetchNotices();
  }, []);

  // Add a new notice
  const addNotice = async (notice) => {
    try {
      const response = await axios.post('https://us-central1-safecar-1487e.cloudfunctions.net/api/addNotice', notice);
      setNotices(prevNotices => [...prevNotices, response.data]);
    } catch (error) {
      console.error("Error adding notice:", error);
    }
  };

  // Update an existing notice
  const updateNotice = async (updatedNotice) => {
    try {
      await axios.put('https://us-central1-safecar-1487e.cloudfunctions.net/api/updateNotice', updatedNotice);
      setNotices(prevNotices => prevNotices.map(notice => (notice.noticeId === updatedNotice.noticeId ? updatedNotice : notice)));
    } catch (error) {
      console.error("Error updating notice:", error);
    }
  };

  return (
    <NoticeContext.Provider value={{ notices, addNotice, updateNotice }}>
      {children}
    </NoticeContext.Provider>
  );
};

export const useNotice = () => useContext(NoticeContext);
