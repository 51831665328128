import React, { useState, useEffect } from 'react';
import { Box, Pagination } from '@mui/material';

const MemberPagination = ({ count, page, onChange }) => {
  const [isNarrow, setIsNarrow] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsNarrow(window.innerWidth <= window.screen.width * 0.8);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initial check
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const styles = {
    paginationBox: {
      display: 'flex',
      justifyContent: 'center',
      position: 'absolute',
      bottom: '0',
      left: isNarrow ? '0' : 'clamp(200px, 17vw, 400px)', // Changes based on narrow width
      right: '0',
      padding: 'clamp(2px, 0.26vw, 8px)',
      boxSizing: 'border-box',
      background: 'white',
    },
  };

  const paginationItemStyles = {
    '& .MuiPaginationItem-root': {
      borderRadius: 'clamp(2px, 0.21vw, 6px)',
      fontSize: 'clamp(10px, 0.73vw, 18px)',
      minWidth: 'clamp(20px, 1.67vw, 40px)',
      height: 'clamp(20px, 1.67vw, 40px)',
      fontFamily: isNarrow ? 'inherit' : '"Nanum Gothic", sans-serif', // Korean font when not narrow
    },
    '& .MuiPaginationItem-root.Mui-selected': {
      backgroundColor: 'primary.main',
      color: 'white',
      borderRadius: 'clamp(2px, 0.21vw, 6px)',
    },
    '& .MuiPaginationItem-root:hover': {
      border: 'clamp(1px, 0.1vw, 3px) solid #2563EB',
      color: '#000000',
    },
  };

  return (
    <Box sx={styles.paginationBox}>
      <Pagination
        count={count}
        page={page}
        onChange={onChange}
        color="primary"
        showFirstButton
        showLastButton
        sx={paginationItemStyles}
      />
    </Box>
  );
};

export default MemberPagination;
