import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuth } from './AuthProvider';

const MemberContext = createContext();

export const useMembers = () => useContext(MemberContext);

export const MemberProvider = ({ children }) => {
  const [members, setMembers] = useState([]);
  const [pendingMembers, setPendingMembers] = useState([]);
  const [memberCache, setMemberCache] = useState({});
  const { currentUser } = useAuth();

  const fetchAllMembers = async () => {
    try {
      const response = await fetch('https://us-central1-safecar-1487e.cloudfunctions.net/api/getAllMembers', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setMembers(data);
    } catch (error) {
      console.error('Error fetching all members:', error);
    }
  };

  const fetchMember = async (userId) => {
    if (memberCache[userId]) {
      return memberCache[userId];
    }
    try {
      const response = await fetch(`https://us-central1-safecar-1487e.cloudfunctions.net/api/getMemberById?userId=${userId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setMemberCache(prevCache => ({ ...prevCache, [userId]: data }));
      return data;
    } catch (error) {
      console.error('Error fetching member:', error);
      throw error;
    }
  };

  const fetchAccidentsForMember = async (userId) => {
    try {
      const response = await fetch(`https://us-central1-safecar-1487e.cloudfunctions.net/api/getAccidentsForMember?userId=${userId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching accidents for member:', error);
      throw error;
    }
  };

  const addMember = async (member) => {
    try {
      const response = await fetch('https://us-central1-safecar-1487e.cloudfunctions.net/api/addMember', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(member)
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setPendingMembers([...pendingMembers, { ...member, userId: data.id }]);
    } catch (error) {
      console.error('Error adding member:', error);
    }
  };

  const approveMember = async (userId) => {
    try {
      await fetch(`https://us-central1-safecar-1487e.cloudfunctions.net/api/approveMember?userId=${userId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      const memberToApprove = pendingMembers.find(member => member.userId === userId);
      setMembers([...members, { ...memberToApprove, approved: true }]);
      setPendingMembers(pendingMembers.filter(member => member.userId !== userId));
    } catch (error) {
      console.error('Error approving member:', error);
    }
  };

  const updateMember = async (updatedMember) => {
    try {
      const response = await fetch(`https://us-central1-safecar-1487e.cloudfunctions.net/api/updateMember?userId=${updatedMember.userId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(updatedMember)
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setMembers(members.map(member => member.userId === updatedMember.userId ? data : member));
    } catch (error) {
      console.error('Error updating member:', error);
    }
  };

  const deleteMember = async (userId) => {
    try {
      await fetch(`https://us-central1-safecar-1487e.cloudfunctions.net/api/deleteMember?userId=${userId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      setMembers(members.filter(member => member.userId !== userId));
    } catch (error) {
      console.error('Error deleting member:', error);
    }
  };

  const restoreMember = async (userId) => {
    try {
      await fetch(`https://us-central1-safecar-1487e.cloudfunctions.net/api/restoreMember?userId=${userId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      setMembers(members.map(m => (m.userId === userId ? { ...m, is_deleted: false } : m)));
    } catch (error) {
      console.error('Error restoring member:', error);
    }
  };

  useEffect(() => {
    if (currentUser) {
      fetchAllMembers();
    }
  }, [currentUser]);

  return (
    <MemberContext.Provider value={{
      members,
      pendingMembers,
      addMember,
      approveMember,
      updateMember,
      deleteMember,
      restoreMember,
      fetchAllMembers,
      fetchMember,
      fetchAccidentsForMember
    }}>
      {children}
    </MemberContext.Provider>
  );
};
