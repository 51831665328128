import React, { createContext, useState } from 'react';
import { database, ref, get } from '../firebase';
import axios from 'axios';

export const MapContext = createContext();

export const MapProvider = ({ children }) => {
  const [selectedIncident, setSelectedIncident] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [loading, setLoading] = useState(true);
  const [what3Words, setWhat3Words] = useState('');

  const fetchIncidentByTimestamp = async (userId, timeStamp) => {
    try {
      const formattedTimestamp = decodeURIComponent(timeStamp).replace('T', ' ');
      const incidentRef = ref(database, `accidentList/${userId}/${formattedTimestamp}`);
      const incidentSnap = await get(incidentRef);

      if (incidentSnap.exists()) {
        return incidentSnap.val();
      } else {
        console.error('No such document!');
        return null;
      }
    } catch (error) {
      console.error('Error fetching document:', error);
      return null;
    }
  };

  const fetchWhat3WordsAddress = async (lat, lng) => {
    try {
      const response = await axios.get(
        `https://api.what3words.com/v3/convert-to-3wa?coordinates=${lat},${lng}&key=G5YMQ4R9&language=en`
      );
      return response.data.words;
    } catch (error) {
      console.error('What3Words API error:', error);
      return 'N/A';
    }
  };

  const loadIncidentData = async (userId, timeStamp) => {
    setLoading(true);
    const data = await fetchIncidentByTimestamp(userId, timeStamp);
    setSelectedIncident(data);
    if (data) {
      const lat = parseFloat(data.latitude);
      const lng = parseFloat(data.longitude);
      if (!isNaN(lat) && !isNaN(lng)) {
        setLatitude(lat);
        setLongitude(lng);
        const w3w = await fetchWhat3WordsAddress(lat, lng);
        setWhat3Words(w3w);
      }
    }
    setLoading(false);
  };

  return (
    <MapContext.Provider
      value={{ selectedIncident, latitude, longitude, loading, what3Words, loadIncidentData }}
    >
      {children}
    </MapContext.Provider>
  );
};
