import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { Box, CssBaseline } from '@mui/material';
import theme from './styles/theme';
import Dashboard from './components/utils/DashBoard';
import AdminList from './pages/admin/AdminList';
import CustomerService from './pages/customer/CustomerService';
import Notice from './pages/notice/NoticeList';
import InsurancePage from './pages/Insurance/InsurancePage';
import AdminInfo from './pages/admin/AdminInfo';
import NoticeDetail from './pages/notice/NoticeDetail';
import MainPage from './pages/MainPage';
import AdminMembers from './pages/admin/AdminMembers';
import { AuthProvider } from './context/AuthProvider';
import { ServiceProvider } from './context/ServiceContext';
import { MemberProvider } from './context/MemberContext';
import { IncidentProvider } from './context/IncidentContext';
import { LanguageProvider  } from './context/LanguageContext';
import { NoticeProvider } from './context/NoticeContext';
import { MapProvider } from './context/MapContext';
import { NotificationProvider } from './context/NotificationContext';
import AppManagement from './pages/app-magement/AppManagement';
import SendNotification from './pages/send-notice/SendNotification';
import AllMember from './pages/member/AllMember';
import Incidents from './pages/accident/Incidents';
import AccidentDetailsPage from './pages/accident/AccidentDetailsPage';
import SendMessages from './pages/send-message/SendMessages';
import DetailedComplaint from './pages/customer/DetailedComplaint';
import RequsetReport from './pages/accident/RequestReport';
import ReportPreviewPage from './pages/accident/ReportPreviewPage';
import NotificationPage from './pages/admin-notice/NotificationPage';
import NotificationDisplay from './pages/admin-notice/NotificationDisplay';
import Info from './pages/info/Info';
import SafeWeb  from './pages/info/SafeWeb';
import IPCheck from './pages/login/IPCheck';

const AppContent = ({ drawerOpen, setDrawerOpen }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isLoginPath = location.pathname === '/login';
  const isReportPreviewPath = location.pathname.startsWith('/report-preview');
  const isInfoPath = location.pathname === '/info';
  const isSafeWebPath = location.pathname === '/safeWeb';
  const shouldShowDashboardAndNotification = !isLoginPath && !isReportPreviewPath && !isInfoPath && !isSafeWebPath;
  useEffect(() => {
    if (location.pathname === '/') {
      navigate('/login');
    }
  }, [location, navigate]);

  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <CssBaseline />
      {shouldShowDashboardAndNotification && <Dashboard drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          position: 'relative',
        }}
      >
        <Routes>
          <Route path="/" element={<Navigate replace to="/login" />} />
          <Route path="/main" element={<MainPage />} />
          <Route path="/notifications" element={<NotificationPage />} />
          <Route path="/login" element={<IPCheck />} />
          <Route path="/app-management" element={<AppManagement />} />
          <Route path="/all-members" element={<AllMember />} />
          <Route path="/incidents" element={<Incidents />} />
          <Route path="/request" element={<RequsetReport />} />
          <Route path="/incidents/:userId" element={<AccidentDetailsPage />} />
          <Route path="/send-notification" element={<SendNotification />} />
          <Route path="/admins" element={<AdminList />} />
          <Route path="/admin-info" element={<AdminInfo />} />
          <Route path="/admin-members/:adminId" element={<AdminMembers />} />
          <Route path="/customer-service" element={<CustomerService />} />
          <Route path="/complaint/:userId/:timeStamp" element={<DetailedComplaint />} />
          <Route path="/send-message" element={<SendMessages />} />
          <Route path="/notice" element={<Notice />} />
          <Route path="/notice/:id" element={<NoticeDetail />} />
          <Route path="/insurance" element={<InsurancePage />} />
          <Route path="/info" element={<Info />} />
          <Route path="*" element={<div>404 Not Found</div>} />
        </Routes>
      </Box>
    </Box>
  );
};

const App = () => {
  const [drawerOpen, setDrawerOpen] = React.useState(true);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <AuthProvider>
          <MemberProvider>
            <IncidentProvider>
              <ServiceProvider>
                <NoticeProvider>
                  <MapProvider>
                    <LanguageProvider >
                      <Routes>
                        <Route path="/" element={<Navigate replace to="/login" />} />
                        <Route
                          path="/report-preview/:encodedUserId/:encodedTimeStamp"
                          element={<ReportPreviewPage />}
                        />
                        <Route path="/safeWeb" element={<SafeWeb />} />
                        <Route
                          path="/*"
                          element={
                            <NotificationProvider>
                              <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
                                <AppContent drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
                                {window.location.pathname !== '/login' && 
                                window.location.pathname !== '/safeWeb' && 
                                <NotificationDisplay />}
                              </Box>
                            </NotificationProvider>
                          }
                        />
                      </Routes>
                    </LanguageProvider >
                  </MapProvider>
                </NoticeProvider>
              </ServiceProvider>
            </IncidentProvider>
          </MemberProvider>
        </AuthProvider>
      </Router>
    </ThemeProvider>
  );
};

export default App;