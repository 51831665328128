// CustomAddButton.js
import React from 'react';
import { Button } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';

const buttonStyles = {
  normal: {
    border: 'clamp(1px, 0.1vw, 2px) solid #E5E7EB',
    borderRadius: 'clamp(6px, 0.52vw, 12px)',
    color: '#000000',
    padding: 'clamp(4px, 0.42vw, 0.42vw) clamp(8px, 0.83vw, 0.83vw)',
    minWidth: 'clamp(80px, 6.25vw, 150px)',
    fontWeight: 'bold',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    fontSize: 'clamp(12px, 0.83vw, 20px)',  // 수정된 부분
  },
  hover: {
    borderColor: '#2563EB',
    color: '#2563EB',
    backgroundColor: '#fff',
  },
  active: {
    backgroundColor: '#2563EB',
    color: '#ffffff',
    borderColor: '#2563EB',
  },
  icon: {
    color: '#2563EB',
    marginRight: 'clamp(4px, 0.42vw, 10px)',
    fontSize: 'clamp(12px, 1.25vw, 24px)',
  },
};

const CustomAddButton = ({ children, onClick, icon: Icon = AddIcon, ...props }) => {
  return (
    <Button
      onClick={onClick}
      sx={{
        ...buttonStyles.normal,
        '&:hover': buttonStyles.hover,
        '&:active': buttonStyles.active,
        ...props.sx,
      }}
      {...props}
    >
      {Icon && <Icon sx={buttonStyles.icon} />}
      {children}
    </Button>
  );
};

export default CustomAddButton;