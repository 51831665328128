import { useMemo } from 'react';

const directionMap = {
  'N': 'N(북)', 'NNE': 'NNE(북북동)', 'NE': 'NE(북동)', 'ENE': 'ENE(동북동)',
  'E': 'E(동)', 'ESE': 'ESE(동남동)', 'SE': 'SE(남동)', 'SSE': 'SSE(남남동)',
  'S': 'S(남)', 'SSW': 'SSW(남남서)', 'SW': 'SW(남서)', 'WSW': 'WSW(서남서)',
  'W': 'W(서)', 'WNW': 'WNW(서북서)', 'NW': 'NW(북서)', 'NNW': 'NNW(북북서)'
};

const transmissionMap = {
  'P': 'P(Park) 주차모드',
  'R': 'R(Reverse) 후진모드',
  'N': 'N(Neutral) 중립모드',
  'D': 'D(Drive) 주행모드'
};

export const useFormatData = () => {
  return useMemo(() => {
    return {
      formatDirection: (custom) => {
        if (!custom || custom.trim() === '') {
          return '-';
        }
        return directionMap[custom] || custom;
      },
      formatTransmission: (transmission) => {
        if (!transmission || transmission.trim() === '') {
          return '-';
        }
        return transmissionMap[transmission] || transmission;
      }
    };
  }, []);
};