import React, { useState, useRef } from 'react';
import play from '../../../styles/img/appbar/play.png';
import playpause from '../../../styles/img/appbar/playpuse.png';
import '../styles/VideoSection.css';  // Import the new CSS file

const VideoSection = ({ scale, text, videoSrc }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  const togglePlay = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <div className="video-section" style={{'--scale': scale}}>
      <h2 className="video-title">{text.promoVideo}</h2>
      <div className="video-container">
        <video
          ref={videoRef}
          src={videoSrc}
          className="video"
          onClick={togglePlay}
        />
        <button className="play-pause-button" onClick={togglePlay}>
          <img 
            src={isPlaying ? playpause : play} 
            alt={isPlaying ? "Pause" : "Play"} 
            className="play-pause-icon"
          />
        </button>
      </div>
    </div>
  );
};

export default React.memo(VideoSection);