import React, { useState, useEffect } from 'react';
import { Box, Paper } from '@mui/material';

const drawerWidth = 'clamp(200px, 16.77vw, 100%)';

const SubLayout = ({ children }) => {
  const [isDrawerVisible, setIsDrawerVisible] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      const newIsDrawerVisible = window.innerWidth > window.screen.width * 0.8;
      setIsDrawerVisible(newIsDrawerVisible);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call once to set initial state
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Box 
      sx={{ 
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        paddingTop: 'clamp(60px, 8vh, 80px)', // AppBar 높이만큼 상단 여백
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Paper
        elevation={3}
        sx={{
          flexGrow: 1,
          width: '100%',
          height: '100%',
          overflow: 'auto',
          paddingTop: 'clamp(15px, 4vh, 60px)',
          paddingRight: 'clamp(15px, 5vw, 60px)',
          paddingBottom: 'clamp(15px, 4vh, 60px)',
          paddingLeft: isDrawerVisible
            ? `calc(${drawerWidth} + clamp(15px, 5vw, 60px))`
            : 'clamp(15px, 5vw, 60px)',
          transition: 'padding-left 0.3s',
          '&::-webkit-scrollbar': {
            width: 'clamp(6px, 0.42vw, 8px)',
            height: 'clamp(6px, 0.42vw, 8px)',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888',
            borderRadius: '4px',
            '&:hover': {
              backgroundColor: '#555',
            },
          },
          '@media print': {
            boxShadow: 'none',
            border: 'none',
          },
        }}
      >
        {children}
      </Paper>
    </Box>
  );
};

export default SubLayout;