import React, { useState } from 'react';
import { Box, Button, TextField, Typography, MenuItem, Grid, CardContent } from '@mui/material';
import SubLayout from '../../components/layout/SubLayout'; // Import the SubLayout component

const AdminInfo = () => {
  // 하드코딩된 관리자 데이터
  const hardcodedAdminData = {
    username: 'mainAdmin',
    grade: 1,
    subordinates: ['subAdmin1', 'subAdmin2'],
    email: 'mainadmin@example.com',
    phone_number: '010-1234-5678',
    memberLimit: 10,
  };

  // 상태 훅을 사용하여 데이터 관리
  const [username] = useState(hardcodedAdminData.username);
  const [role] = useState(hardcodedAdminData.grade === 1 ? 'Main Admin' : 'Sub Admin');
  const [subordinates] = useState(hardcodedAdminData.subordinates);
  const [email, setEmail] = useState(hardcodedAdminData.email);
  const [phone, setPhone] = useState(hardcodedAdminData.phone_number);
  const [memberLimit, setMemberLimit] = useState(hardcodedAdminData.memberLimit);

  const handleSave = () => {
    // 업데이트된 관리자 정보를 콘솔에 출력
    const updatedAdmin = {
      username,
      grade: role === 'Main Admin' ? 1 : 2,
      subordinates,
      email,
      phone_number: phone,
      memberLimit,
    };
    console.log('Saved:', updatedAdmin);
  };

  return (
    <SubLayout>
      <Box>
        <Typography 
          variant="h4" 
          sx={{ 
            fontWeight: 'bold', 
            marginBottom: 'clamp(10px, 1.48vh, 20px)', // Responsive margin using clamp
            fontSize: 'clamp(24px, 2vw, 36px)', // Responsive font size using clamp
          }}
        >
          관리자 정보
        </Typography>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="아이디"
                value={username}
                fullWidth
                margin="normal"
                disabled
                sx={{
                  fontSize: 'clamp(12px, 1.2vw, 16px)', // Clamp for font size
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="역할"
                value={role}
                fullWidth
                margin="normal"
                disabled
                sx={{
                  fontSize: 'clamp(12px, 1.2vw, 16px)', // Clamp for font size
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="이메일"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
                margin="normal"
                sx={{
                  fontSize: 'clamp(12px, 1.2vw, 16px)', // Clamp for font size
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="휴대폰 번호"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                fullWidth
                margin="normal"
                sx={{
                  fontSize: 'clamp(12px, 1.2vw, 16px)', // Clamp for font size
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="하위 관리자 접근권한"
                value={subordinates.join(', ')}
                fullWidth
                margin="normal"
                disabled
                sx={{
                  fontSize: 'clamp(12px, 1.2vw, 16px)', // Clamp for font size
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                select
                label="회원 권한 수"
                value={memberLimit}
                onChange={(e) => setMemberLimit(e.target.value)}
                fullWidth
                margin="normal"
                sx={{
                  fontSize: 'clamp(12px, 1.2vw, 16px)', // Clamp for font size
                }}
              >
                <MenuItem value={0}>0</MenuItem>
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={15}>15</MenuItem>
                <MenuItem value={20}>20</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 'clamp(10px, 2vh, 20px)' }}>
                <Button 
                  variant="contained" 
                  color="primary" 
                  onClick={handleSave}
                  sx={{ padding: 'clamp(10px, 1.5vh, 15px)' }} // Responsive padding using clamp
                >
                  저장
                </Button>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Box>
    </SubLayout>
  );
};

export default AdminInfo;
