import React, { useState, useEffect } from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Checkbox, TablePagination, Chip } from '@mui/material';

const CheckboxTable = ({ items, selectedItems, handleCheckboxChange, columns, handleRowClick }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    if (selectedItems.length === items.length && items.length > 0) {
      handleCheckboxChange({ target: { checked: true } }, 'selectAll');
    }
  }, [selectedItems, items, handleCheckboxChange]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const paginatedItems = items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <Paper sx={{ width: '100%', mb: 2 }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={selectedItems.length > 0 && selectedItems.length < items.length}
                  checked={items.length > 0 && selectedItems.length === items.length}
                  onChange={(event) => handleCheckboxChange(event, 'selectAll')}
                  inputProps={{ 'aria-label': 'select all items' }}
                />
              </TableCell>
              {columns.map((column) => (
                <TableCell key={column.id}>{column.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedItems.map((item) => {
              const itemKey = `${item.userId}/${item.timestamp}`;
              const isItemSelected = selectedItems.includes(itemKey);
              const labelId = `checkbox-table-${itemKey}`;

              return (
                <TableRow
                  hover
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={itemKey}
                  selected={isItemSelected}
                  onClick={() => handleRowClick(item)}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={isItemSelected}
                      disabled={item.replied}
                      inputProps={{ 'aria-labelledby': labelId }}
                      onChange={(event) => handleCheckboxChange(event, itemKey)}
                      onClick={(event) => event.stopPropagation()}  // Prevent row click when clicking checkbox
                    />
                  </TableCell>
                  {columns.map((column) => (
                    <TableCell key={column.id}>
                      {column.id === 'replied' ? (
                        <Chip
                          label={item.replied ? 'Replied' : 'Pending'}
                          color={item.replied ? 'success' : 'warning'}
                        />
                      ) : (
                        item[column.id]
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={items.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default CheckboxTable;
