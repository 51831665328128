import React, { useState } from 'react';
import { Box, Typography, Grid, TablePagination } from '@mui/material';
import { useSendNoti } from '../../context/SendNotiContext';

const NotificationList = () => {
  const { notifications } = useSendNoti();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // 날짜 형식을 조건부로 수정하는 함수
  const formatDate = (dateString) => {
    // 초 단위가 있는 경우 (길이가 19인 경우)에만 초 단위를 제거
    if (dateString.length === 19) {
      return dateString.slice(0, -3);
    }
    // 그 외의 경우 그대로 반환
    return dateString;
  };
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        backgroundColor: '#F9FAFB',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography variant="h6" component="h2" sx={{ 
        padding: 'clamp(10px, 2vh, 30px) clamp(10px, 1.5vw, 30px)', 
        fontSize: 'clamp(16px, 2.22vh, 24px)' 
      }}>
        보낸 알림
      </Typography>
      <Box
        sx={{
          flex: 1,
          overflowY: 'auto',
          padding: '0 clamp(5px, 1vw, 20px)',
          '&::-webkit-scrollbar': {
            width: 'clamp(4px, 0.74vh, 8px)',
            height: 'clamp(4px, 0.74vh, 8px)',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888',
            borderRadius: 'clamp(2px, 0.37vh, 4px)',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#555',
          },
        }}
      >
        <Grid container spacing={2}>
          {notifications.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((notification) => (
            <Grid item xs={12} key={notification.timeStamp}>
              <Box
                sx={{
                  cursor: 'pointer',
                  backgroundColor: '#E5E7EB',
                  borderRadius: 'clamp(5px, 0.93vh, 10px)',
                  padding: 'clamp(10px, 1.85vh, 20px)',
                  boxShadow: '0px clamp(1px, 0.09vh, 2px) clamp(2px, 0.28vh, 4px) rgba(0, 0, 0, 0.1)',
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    backgroundColor: '#2563EB',
                    '& .MuiTypography-root': {
                      color: '#FFFFFF',
                    },
                  },
                }}
              >
                <Grid container alignItems="center">
                  <Grid item xs={4}>
                    <Typography variant="body2" sx={{ 
                      color: '#6B7280', 
                      fontWeight: 'bold', 
                      fontSize: 'clamp(12px, 1.48vh, 16px)' 
                    }}>
                      알림 날짜
                    </Typography>
                    <Typography variant="body2" sx={{ 
                      fontWeight: 'bold', 
                      marginTop: 'clamp(2px, 0.46vh, 5px)', 
                      fontSize: 'clamp(12px, 1.48vh, 16px)' 
                    }}>
             {formatDate(notification.timeStamp)}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body2" sx={{ 
                      color: '#6B7280', 
                      fontWeight: 'bold', 
                      fontSize: 'clamp(12px, 1.48vh, 16px)' 
                    }}>
                      제목
                    </Typography>
                    <Typography variant="body2" sx={{ 
                      fontWeight: 'bold', 
                      marginTop: 'clamp(2px, 0.46vh, 5px)', 
                      fontSize: 'clamp(12px, 1.48vh, 16px)' 
                    }}>
                      {notification.title}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body2" sx={{ 
                      color: '#6B7280', 
                      fontWeight: 'bold', 
                      fontSize: 'clamp(12px, 1.48vh, 16px)' 
                    }}>
                      메세지
                    </Typography>
                    <Typography variant="body2" sx={{ 
                      fontWeight: 'bold', 
                      marginTop: 'clamp(2px, 0.46vh, 5px)', 
                      fontSize: 'clamp(12px, 1.48vh, 16px)' 
                    }}>
                      {notification.message}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box sx={{ p: 'clamp(10px, 1.85vh, 20px)', borderTop: 'clamp(1px, 0.09vh, 2px) solid #E5E7EB' }}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={notifications.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            '& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows': {
              fontSize: 'clamp(12px, 1.48vh, 16px)',
            },
            '& .MuiTablePagination-select': {
              fontSize: 'clamp(12px, 1.48vh, 16px)',
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default NotificationList;