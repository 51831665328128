import React from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import CustomButton from '../utils/CustomButton';
import SearchBox from '../utils/SearchBox';

const ReportTabs = ({
  tabIndex,
  setTabIndex,
  handleRequestReports,
  selectedReports,
  searchTerm,
  setSearchTerm,
  searchType,
  setSearchType,
  searchTypes
}) => {
  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      borderBottom: '0.185vh solid #E5E7EB',
      color: 'black',
    }}>
    <Tabs 
        value={tabIndex}
        onChange={(event, newValue) => setTabIndex(newValue)}
        sx={{
          minHeight: 'unset',
          marginBottom: 'clamp(-8px, 0,  0)',
        }}
      >
        <Tab 
          label="미완료 보고서 요청"
          sx={{
            color: '#6B7280',
            fontWeight: 'bold',
            fontSize: 'clamp(13px, 1.2vw,  1.2vw)',
            minHeight: 'unset',
            '&.Mui-selected': {
              color: '#141414',
            },
          }}
        />
        <Tab 
          label="완료된 보고서 요청"
          sx={{
            color: '#6B7280',
            fontWeight: 'bold',
            fontSize: 'clamp(13px, 1.2vw,  1.2vw)',
            minHeight: 'unset',
            '&.Mui-selected': {
              color: '#141414',
            },
          }}
        />
      </Tabs>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {tabIndex === 0 && (
          <CustomButton
            onClick={handleRequestReports}
            disabled={selectedReports.length === 0}
            sx={{
              height: 'clamp(24px, 3vh, 40px)',
              fontSize: 'clamp(12px, 1.2vw, 16px)',
            }}
          >
            선택된 요청 처리하기
          </CustomButton>
        )}
        {tabIndex === 1 && (
          <SearchBox
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            searchType={searchType}
            setSearchType={setSearchType}
            searchTypes={searchTypes}
          />
        )}
      </Box>
    </Box>
  );
};

export default ReportTabs;