import React from 'react';
import { TextField, MenuItem, FormControl, InputLabel, Select, Box, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

const styles = {
  select: {
    border: 'clamp(1px, 0.1vw, 3px) solid #E5E7EB',
    borderRadius: 'clamp(6px, 0.52vw, 15px)',
    color: '#000000',
    boxShadow: 'none',
    backgroundColor: '#FFFFFF',
    fontSize: 'clamp(12px, 0.83vw, 20px)',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#E5E7EB',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#2563EB',
      },
    },
    '& .MuiSelect-select': {
      padding: 'clamp(6px, 0.52vw, 15px) clamp(8px, 0.73vw, 18px)',
    },
    '&:hover ': {
      borderColor: '#2563EB',
    },
  },
  textField: {
    width: 'clamp(200px, 20vw, 400px)',
    border: 'clamp(1px, 0.1vw, 3px) solid #E5E7EB',
    borderRadius: 'clamp(6px, 0.52vw, 15px)',
    color: '#000000',
    fontSize: 'clamp(12px, 0.83vw, 20px)',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#E5E7EB',
      },
      '&:hover fieldset': {
        borderColor: '#2563EB',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#2563EB',
      },
      '& .MuiInputAdornment-root': {
        marginRight: '0',
      },
    },
    '& .MuiOutlinedInput-input': {
      padding: 'clamp(4px, 0.3vw, 8px) clamp(8px, 0.73vw, 18px)',
      fontSize: 'clamp(14px, 1vw, 24px)',
    },
  },
  menuItem: {
    height: 'clamp(24px, 2.08vw, 50px)',
    margin: 'clamp(1px, 0.1vw, 3px) clamp(3px, 0.31vw, 8px)',
    fontSize: 'clamp(12px, 0.83vw, 20px)',
    '&.Mui-selected': {
      backgroundColor: 'rgba(78, 158, 249, 0.14)',
      borderRadius: 'clamp(5px, 0.42vw, 12px)',
      margin: 'clamp(1px, 0.1vw, 3px) clamp(3px, 0.31vw, 8px)',
      color: 'black',
      '&:hover': {
        backgroundColor: 'rgba(78, 158, 249, 0.07)',
      },
    },
    '&:hover': {
      backgroundColor: 'rgba(78, 158, 249, 0.07)',
      borderRadius: 'clamp(5px, 0.42vw, 12px)',
      margin: 'clamp(1px, 0.1vw, 3px) clamp(3px, 0.31vw, 8px)',
    },
  },
};

const SearchBox = ({ searchTerm, setSearchTerm, searchType, setSearchType, searchTypes }) => {
  const handleSearchTypeChange = (event) => {
    setSearchType(event.target.value);
  };

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const clearSearchTerm = () => {
    setSearchTerm('');
  };

  return (
    <Box sx={{ display: 'flex', gap: 'clamp(10px, 1.04vw, 30px)', alignItems: 'center' }}>
      <FormControl size="small" sx={{ minWidth: 'clamp(80px, 6.25vw, 150px)' }}>
        <InputLabel id="search-type-label">Type</InputLabel>
        <Select
          labelId="search-type-label"
          id="search-type"
          value={searchType}
          label="Type"
          onChange={handleSearchTypeChange}
          size="small"
          sx={styles.select}
        >
          {searchTypes.map((type) => (
            <MenuItem key={type.value} value={type.value} sx={styles.menuItem}>
              {type.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        label="Search"
        variant="outlined"
        size="small"
        fullWidth
        value={searchTerm}
        onChange={handleSearchTermChange}
        sx={styles.textField}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon sx={{ fontSize: 'clamp(18px, 1.25vw, 30px)' }} />
            </InputAdornment>
          ),
          endAdornment: searchTerm ? (
            <InputAdornment position="end" onClick={clearSearchTerm} style={{ cursor: 'pointer' }}>
              <ClearIcon sx={{ fontSize: 'clamp(18px, 1.25vw, 30px)' }} />
            </InputAdornment>
          ) : null,
        }}
      />
    </Box>
  );
};

export default SearchBox;