import React, { useState} from 'react';
import {
  Container, Paper, Box, Button, Typography, Table, TextField, TableBody, TableCell, TableHead, TableRow, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Add as AddIcon } from '@mui/icons-material';
import { useNotice } from '../../context/NoticeContext';
import SearchBox from '../../components/utils/SearchBox';

const NoticeList = () => {
  const navigate = useNavigate();
  const { notices, addNotice, updateNotice } = useNotice();
  const [searchTerm, setSearchTerm] = useState('');
  const [searchType, setSearchType] = useState('title');
  const [alertOpen, setAlertOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentNotice, setCurrentNotice] = useState({});

  const handleAddNotice = () => {
    setCurrentNotice({});
    setDialogOpen(true);
  };

  const handleRowClick = (notice) => {
    navigate(`/notice/${notice.noticeId}`, { state: { notice } });
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDialogChange = (name, value) => {
    setCurrentNotice({
      ...currentNotice,
      [name]: value,
    });
  };

  const handleSave = async () => {
    try {
      if (currentNotice.noticeId) {
        await updateNotice(currentNotice);
      } else {
        await addNotice(currentNotice);
      }
      setDialogOpen(false);
    } catch (error) {
      console.error('Error saving notice:', error);
      setAlertOpen(true);
    }
  };

  const searchTypes = [
    { value: 'title', label: 'Title' },
    { value: 'body', label: 'Content' },
  ];

  const filteredNotices = notices
    .filter(
      (notice) => notice && notice[searchType]?.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => {
      if (a.importance && !b.importance) return -1;
      if (!a.importance && b.importance) return 1;
      return new Date(b.timeStamp) - new Date(a.timeStamp);
    });
//최근 3일 데이터 
  const isNew = (timeStamp) => {
    const noticeDate = new Date(timeStamp);
    const currentDate = new Date();
    const diffTime = Math.abs(currentDate - noticeDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays <= 3;
  };

  return (
    <Container maxWidth="xl" sx={{ mt: 4 }}>
      <Paper sx={{ p: 4, boxShadow: 3, borderRadius: 5, height: '90vh', overflow: 'auto' }}>
        <Typography variant="h4" gutterBottom>
          Notice
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
          <SearchBox
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            searchType={searchType}
            setSearchType={setSearchType}
            searchTypes={searchTypes}
          />
          <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleAddNotice}>
            Add Notice
          </Button>
        </Box>

       
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Content</TableCell>
                <TableCell>Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredNotices.map((notice) => (
                <TableRow key={notice.noticeId} onClick={() => handleRowClick(notice)}>
                  <TableCell>
                    {notice?.title || 'No Title'}
                    {notice?.importance && <span style={{ color: 'red', marginLeft: 10 }}>중요!</span>}
                    {isNew(notice?.timeStamp) && <span style={{ color: 'green', marginLeft: 10 }}>New</span>}
                  </TableCell>
                  <TableCell>
                    <Typography noWrap variant="body2">
                      {notice?.body || 'No Content'}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">
                      {notice?.timeStamp ? new Date(notice.timeStamp).toLocaleString() : 'No Date'}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

        <Snackbar
          open={alertOpen}
          autoHideDuration={6000}
          onClose={() => setAlertOpen(false)}
          message="An error occurred. Please try again."
        />
        <Dialog open={dialogOpen} onClose={handleDialogClose}>
          <DialogTitle>{currentNotice.noticeId ? 'Edit Notice' : 'Add Notice'}</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              label="Title"
              type="text"
              fullWidth
              value={currentNotice.title || ''}
              onChange={(e) => handleDialogChange('title', e.target.value)}
            />
            <TextField
              margin="dense"
              label="Content"
              type="text"
              fullWidth
              multiline
              rows={4}
              value={currentNotice.body || ''}
              onChange={(e) => handleDialogChange('body', e.target.value)}
            />
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
              <Typography>Importance</Typography>
              <Checkbox
                checked={currentNotice.importance || false}
                onChange={(e) => handleDialogChange('importance', e.target.checked)}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSave} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    </Container>
  );
};

export default NoticeList;
