import React, { useEffect, useState, useRef } from 'react';
import { ThickChevronLeft, ThickChevronRight } from '../styles/ThickChevronIcons';
import '../styles/ControlSite.css';
import main1 from '../../../styles/img/info/main1.png';
import web1 from '../../../styles/img/info/web1.png';
import web2 from '../../../styles/img/info/web2.png';
import webEn1 from '../../../styles/img/info/webEn1.png';
import webEn2 from '../../../styles/img/info/webEn2.png';
import webJp1 from '../../../styles/img/info/webJp1.png';
import webJp2 from '../../../styles/img/info/webJp2.png';

const ControlSite = ({
  scale,
  mobileScale,
  text,
  language,
  header1Ref,
  MultilineText
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 431);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const imagesContainerRef = useRef(null);

  const getImage = (koImage, enImage, jpImage) => {
    switch(language) {
      case 'en':
        return enImage;
      case 'ja':
        return jpImage;
      default:
        return koImage;
    }
  };

  const images = [
    getImage(web1, webEn1, webJp1),
    getImage(web2, webEn2, webJp2)
  ];

  useEffect(() => {
    const handleResize = () => {
      const newIsMobile = window.innerWidth <= 431;
      setIsMobile(newIsMobile);
      document.documentElement.style.setProperty('--scale', newIsMobile ? mobileScale : scale);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
      }
    );

    if (header1Ref.current) {
      observer.observe(header1Ref.current);
    }

    return () => {
      window.removeEventListener('resize', handleResize);
      if (header1Ref.current) {
        observer.unobserve(header1Ref.current);
      }
    };
  }, [scale, mobileScale, header1Ref]);

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <>
      <div className="headerSection">
        <div className="headerContent">
          <div className="verticalLine"></div>
          {text.controlSite}
        </div>
      </div>
      <div className="main1Container">
        <img src={main1} alt="Main 1" className="main1Image" />
        <div className="overlay50"></div>
        {isMobile ? (
          <div className={`mobileSubImagesContainer ${isVisible ? 'visible' : ''}`} ref={imagesContainerRef}>
            <button 
              className={`navButton prevButton ${currentImageIndex === 0 ? 'dark' : 'light'}`} 
              onClick={handlePrevImage}
            >
             <ThickChevronLeft size={24} />
            </button>
            <img
              src={images[currentImageIndex]}
              alt={`web${currentImageIndex + 1}`}
              className="mobileSubImage"
              style={{
                width: `${408 * mobileScale}px`,
                height: `${240 * mobileScale}px`
              }}
            />
            <button 
              className={`navButton nextButton ${currentImageIndex === 0 ? 'light' : 'dark'}`} 
              onClick={handleNextImage}
            >
             <ThickChevronRight size={24} />
            </button>
          </div>
        ) : (
          <div className={`subImagesContainer ${isVisible ? 'visible' : ''}`}>
            {images.map((img, index) => (
              <img
                key={index}
                src={img}
                alt={`web${index + 1}`}
                className="subImage"
                style={{width: `${537 * scale}px`}}
              />
            ))}
          </div>
        )}
      </div>
      <div ref={header1Ref} className="textSection">
        <div className={`textContent ${isVisible ? 'visible' : ''}`}>
          {language === 'en' && isMobile ? text.controlSiteDesc : <MultilineText text={text.controlSiteDesc} />}
        </div>
      </div>
    </>
  );
};

export default React.memo(ControlSite);