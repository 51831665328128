// src/pages/AllMember.js
import React, { useState, useEffect } from 'react';
import {  Typography, Snackbar, Box } from '@mui/material';
import { useMembers } from '../../context/MemberContext';
import { useAuth } from '../../context/AuthProvider';
import MemberDetailDialog from '../../components/member/MemberDetailDialog';
import GenericDialog from '../../components/utils/GenericDialog';
import MemberList from '../../components/member/MemberList';
import MemberActions from '../../components/member/MemberActions';
import MemberPagination from '../../components/member/MemberPagination';
import { ref, onValue } from 'firebase/database';
import { database } from '../../firebase';
import SubLayout from '../../components/layout/SubLayout';


const styles = {
  title: {
    fontWeight: 'bold',
    marginBottom: 'clamp(10px, 1.48vh, 20px)',
    fontSize: 'clamp(24px, 2vw, 36px)'  // 최소 24px, 최대 36px
  },
  memberListContainer: {
    height: 'clamp(300px, 60vh, 600px)',
    marginBottom: 'clamp(20px, 2.78vh, 40px)',
  },
};

const AllMember = () => {
  const { currentUser } = useAuth();
  const { members = [], fetchAllMembers, addMember } = useMembers();
  const [searchTerm, setSearchTerm] = useState('');
  const [searchType, setSearchType] = useState('userName');
  const [filter, setFilter] = useState('all');
  const [openDetailDialog, setOpenDetailDialog] = useState(false);
  const [openGenericDialog, setOpenGenericDialog] = useState(false);
  const [currentMember, setCurrentMember] = useState(null);
  const [alertOpen, setAlertOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(7);
  const [accidentCounts, setAccidentCounts] = useState({});
  const [reportRequestCounts, setReportRequestCounts] = useState({});

  useEffect(() => {
    if (currentUser) {
      fetchAllMembers();
    }
  }, [currentUser, fetchAllMembers]);

  useEffect(() => {
    if (members.length === 0) return;

    members.forEach(member => {
      const accidentRef = ref(database, `accidentList/${member.userId}`);
      const reportRef = ref(database, `requestReport/${member.userId}`);

      onValue(accidentRef, (snapshot) => {
        const accidents = snapshot.val();
        setAccidentCounts(prevCounts => ({
          ...prevCounts,
          [member.userId]: accidents ? Object.keys(accidents).length : 0
        }));
      });

      onValue(reportRef, (snapshot) => {
        const reports = snapshot.val();
        setReportRequestCounts(prevCounts => ({
          ...prevCounts,
          [member.userId]: reports ? Object.keys(reports).length : 0
        }));
      });
    });
  }, [members]);

  const visibleMembers = filter === 'all'
    ? members.filter(member => member.userDelete !== "Y")
    : filter === 'deleted'
      ? members.filter(member => member.userDelete === "Y")
      : members;

  const searchedMembers = visibleMembers.filter(member =>
    member[searchType] && member[searchType].toString().toLowerCase().includes(searchTerm)
  );

  const handleViewDetails = (member) => {
    setCurrentMember(member);
    setOpenDetailDialog(true);
  };

  const handleAddMember = () => {
    setCurrentMember({
      userId: '',
      userName: '',
      userPW: '',
      userPhone: '',
      userCarNumber: '',
      userCarModelName: '',
      userCarManufacturer: '',
      userCarModelCC: '',
      userGuardianName: '',
      userGuardianNumber: ''
    });
    setOpenGenericDialog(true);
  };

  const handleCloseDetailDialog = () => {
    setOpenDetailDialog(false);
    setCurrentMember(null);
  };

  const handleCloseGenericDialog = () => {
    setOpenGenericDialog(false);
    setCurrentMember(null);
  };

  const handleSave = async () => {
    if (!currentMember.userName || !currentMember.userPhone || !currentMember.userCarNumber) {
      setAlertOpen(true);
      return;
    }

    const duplicateUser = members.find(member => member.userId === currentMember.userId);
    if (duplicateUser && !currentMember.userId) {
      setAlertOpen(true);
      return;
    }

    const memberData = { ...currentMember };
    addMember({ ...memberData, manager_id: currentUser ? currentUser.manager_id : null });

    setOpenDetailDialog(false);
    setOpenGenericDialog(false);
    setCurrentMember(null);
  };

  const handleChange = (name, value) => {
    setCurrentMember(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const searchTypes = [
    { value: 'userId', label: 'ID' },
    { value: 'userName', label: '이름' },
    { value: 'userPhone', label: '전화번호' },
    { value: 'userCarNumber', label: '차량' }
  ];

  const currentMembers = searchedMembers.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);
  
  return (
    <SubLayout>
      <Typography variant="h4" sx={styles.title}>
        회원 목록
      </Typography>
      <MemberActions
        filter={filter}
        setFilter={setFilter}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        searchType={searchType}
        setSearchType={setSearchType}
        searchTypes={searchTypes}
        onAddMember={handleAddMember}
      />
      <Box sx={styles.memberListContainer}>
        <MemberList
          members={currentMembers.map(member => ({
            ...member,
            accidentCount: accidentCounts[member.userId] || 0,
            reportRequestCount: reportRequestCounts[member.userId] || 0
          }))}
          onCardClick={handleViewDetails}
          selectedMember={currentMember}
        />
      </Box>
      <MemberPagination
        count={Math.ceil(searchedMembers.length / rowsPerPage)}
        page={currentPage}
        onChange={handlePageChange}
      />
      {currentMember && openDetailDialog && (
        <MemberDetailDialog
          open={openDetailDialog}
          handleClose={handleCloseDetailDialog}
          handleSave={handleSave}
          handleChange={handleChange}
          currentMember={currentMember}
        />
      )}
      {currentMember && openGenericDialog && (
        <GenericDialog
          open={openGenericDialog}
          data={currentMember}
          onClose={handleCloseGenericDialog}
          onSave={handleSave}
          onChange={handleChange}
          fields={[
            { id: 'userId', label: 'ID', autoFocus: true },
            { id: 'userName', label: '이름' },
            { id: 'userPW', label: '비밀번호' },
            { id: 'userPhone', label: '전화번호' },
            { id: 'userCarNumber', label: '차량 번호' },
            { id: 'userCarModelName', label: '차량 모델' },
            { id: 'userCarManufacturer', label: '자동차 제조사' },
            { id: 'userCarModelCC', label: '자동차 모델 CC' },
            { id: 'userGuardianName', label: '보호자 이름' },
            { id: 'userGuardianNumber', label: '보호자 전화번호' }
          ]}
          title="회원 추가"
        />
      )}
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={() => setAlertOpen(false)}
        message="필수 항목을 모두 입력해주세요."
      />
    </SubLayout>
  );
};
export default AllMember;