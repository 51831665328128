import React, { useState, useEffect } from 'react';
import LangDropdown from '../utils/LangDropdown';
import applogo from '../../../styles/img/appbar/applogo.png';
import AppStore from '../../../styles/img/appbar/logo-apple.png';
import GooglePlay from '../../../styles/img/appbar/logo-google.png';
import appStore from '../../../styles/img/appbar/app-apple.png';
import googlePlay from '../../../styles/img/appbar/app-google.png';
import IOS_QR_PNG from '../../../styles/img/appbar/IOS_QR_PNG 1.png';
import AOS_QR_PNG from '../../../styles/img/appbar/AOS_QR_PNG 1.png';
import '../styles/Header.css';

const Header = ({
  scale,
  styles,
  mobileScale,
  text,
  scrollToSection,
  isLangDropdownOpen,
  setIsLangDropdownOpen,
  isLangHovered,
  setIsLangHovered,
  MultilineText,
  videoSrc
}) => {
  useEffect(() => {
    document.documentElement.style.setProperty('--scale', scale);
    document.documentElement.style.setProperty('--mobile-scale', mobileScale);
  }, [scale, mobileScale]);

  const [menuOpen, setMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 431);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 431);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };


  const MenuItem = ({ children, to }) => {
    const [isHovered, setIsHovered] = useState(false);
    return (
      <span
        className={`header-menu-item ${isMobile ? 'mobile' : ''}`}
        onClick={() => {
          scrollToSection(to);
          closeMenu();
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={{ color: isHovered ? 'white' : '#9CA3AF' }}
      >
        {children}
      </span>
    );
  };

  const AppStoreBox = ({ desktopLogo, mobileLogo, qrCode, link, isGooglePlay }) => {
    return (
      <div
        className={`header-app-store-box ${isGooglePlay ? 'google-play' : ''}`}
        onClick={() => window.open(link, '_blank')}
      >
        <img 
          src={isMobile ? mobileLogo : desktopLogo} 
          alt={`${isGooglePlay ? 'Google Play' : 'App Store'} logo`} 
          className="header-app-store-icon" 
          style={isMobile ? {
            width: `calc(${isGooglePlay ? '97px' : '81px'} * var(--mobile-scale))`,
            height: `calc(67px * var(--mobile-scale))`
          } : {}}
        />
        {isMobile ? (
          <span className="header-app-store-download">DOWNLOAD</span>
        ) : (
          <img src={qrCode} alt="QR Code" className="header-app-store-qr" />
        )}
        <div className="header-app-store-overlay"></div>
      </div>
    );
  };

  return (
    <div className="header-container">
      <div className="header-video-container-2">
        <video 
          className="header-video"
          src={videoSrc}
          autoPlay 
          loop 
          muted 
        />
      </div>
      <div className={`header-overlay ${menuOpen ? 'overlay-active' : ''}`}></div>
      <div className={`header-content ${menuOpen ? 'menu-open' : ''}`}>
        <header className="header-nav">
          <div className="header-logo">
            <img src={applogo} alt="SAFECAR logo" />
            <span>SAFECAR</span>
          </div>
          <div className="header-nav-right">
            {isMobile ? (
              <>
                <div className="hamburger-icon" onClick={toggleMenu}>
                  ☰
                </div>
                <LangDropdown
                  isLangDropdownOpen={isLangDropdownOpen}
                  setIsLangDropdownOpen={setIsLangDropdownOpen}
                  isLangHovered={isLangHovered}
                  setIsLangHovered={setIsLangHovered}
                  styles={styles}
                  scale={scale}
                  mobileScale={mobileScale}
                />
              </>
            ) : (
              <>
                <nav className={`header-menu ${menuOpen ? 'active' : ''}`}>
                  <MenuItem to="carousel">{text.appIntro}</MenuItem>
                  <MenuItem to="controlSite">{text.controlSite}</MenuItem>
                  <MenuItem to="accidentReport">{text.accidentReport}</MenuItem>
                  <MenuItem to="video">{text.appVideo}</MenuItem>
                  <LangDropdown
                    isLangDropdownOpen={isLangDropdownOpen}
                    setIsLangDropdownOpen={setIsLangDropdownOpen}
                    isLangHovered={isLangHovered}
                    setIsLangHovered={setIsLangHovered}
                    styles={styles}
                    scale={scale}
                    mobileScale={mobileScale}
                  />
                </nav>
              </>
            )}
          </div>
          {isMobile && (
        <nav className={`header-menu mobile ${menuOpen ? 'active' : ''}`}>
          <div className="menu-close" onClick={closeMenu}>
            <div className="close-icon"></div>
          </div>
          <div className="mobile-menu-items">
            <MenuItem to="carousel">{text.appIntro}</MenuItem>
            <MenuItem to="controlSite">{text.controlSite}</MenuItem>
            <MenuItem to="accidentReport">{text.accidentReport}</MenuItem>
            <MenuItem to="video">{text.appVideo}</MenuItem>
          </div>
        </nav>
          )}
        </header>
        <main className="header-main-content">
          <h1 className="header-title">{text.mainTitle}</h1>
          <p className="header-subtitle">
            <MultilineText text={text.subTitle} styles={styles} />
          </p>
          <div className="header-app-store-container">
            <AppStoreBox 
              desktopLogo={AppStore}
              mobileLogo={appStore}
              qrCode={IOS_QR_PNG} 
              link="https://apps.apple.com/kr/app/%EC%84%B8%EC%9D%B4%ED%94%84%EC%B9%B4/id6550901643"
              isGooglePlay={false}
            />
            <AppStoreBox 
              desktopLogo={GooglePlay}
              mobileLogo={googlePlay}
              qrCode={AOS_QR_PNG} 
              link="https://play.google.com/store/apps/details?id=kr.myren.myren_aos"
              isGooglePlay={true}
            />
          </div>
        </main>
      </div>
    </div>
  );
};

export default React.memo(Header);