import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Typography, CircularProgress, Button } from '@mui/material';
import LoginPage from './login';

const IPCheck = () => {
  const [isAllowed, setIsAllowed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [ipAddress, setIpAddress] = useState(null);

  const checkIP = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.get('https://us-central1-safecar-1487e.cloudfunctions.net/api/checkIP');
      setIpAddress(response.data.ip);
      setIsAllowed(response.data.isAllowed);
    } catch (err) {
      setError('IP 주소를 확인하는 중 오류가 발생했습니다.');
      console.error('Error checking IP:', err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    checkIP();
  }, []);

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
        <Typography ml={2}>접속 가능한 IP인지 확인 중...</Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box textAlign="center" p={4}>
        <Typography variant="h6" color="error">{error}</Typography>
        <Button onClick={checkIP} variant="contained" sx={{ mt: 2 }}>
          다시 시도
        </Button>
      </Box>
    );
  }

  return <LoginPage isIPAllowed={isAllowed} ipAddress={ipAddress} />;
};

export default IPCheck;