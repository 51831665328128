import React from 'react';
import { Box, Typography } from '@mui/material';
import { SendNotiProvider } from '../../context/SendNotiContext';
import NotificationForm from '../../components/sendNoti/NotificationForm';
import NotificationList from '../../components/sendNoti/NotificationList';
import NotiSnackbar from '../../components/sendNoti/NotiSnackbar';
import SubLayout2 from '../../components/layout/SubLayout2';
import useWindowDimensions from '../../hook/useWindowDimensions';

const SendNotification = () => {
  const { isNarrow } = useWindowDimensions();

  const scrollbarStyle = {
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  };

  return (
    <SendNotiProvider>
      <SubLayout2>
        <Box sx={{
          height: '100%',
          overflow: isNarrow ? 'auto' : 'hidden',
          ...scrollbarStyle
        }}>
          <Box sx={{
            display: 'flex',
            flexDirection: isNarrow ? 'column' : 'row',
            height: isNarrow ? 'auto' : '100%',
          }}>
            <Box sx={{
              width: isNarrow ? '100%' : '60%',
              height: isNarrow ? 'auto' : '100%',
              overflow: isNarrow ? 'visible' : 'auto',
              pt: 'clamp(15px, 4vh, 60px)',
              pr: 'clamp(15px, 5vw, 60px)',
              pl: 'clamp(15px, 5vw, 60px)',
              pb: 'clamp(15px, 4vh, 60px)',
              ...(isNarrow ? {} : scrollbarStyle)
            }}>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: 'bold',
                  marginBottom: '1.48vh',
                  fontSize: 'clamp(24px, 2vw, 36px)' ,
                }}
              >
                알림 보내기
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontSize: 'clamp(16px, 1vw, 24px)',  // 최소 16px, 최대 24px
                  color: '#6B7280',
                  fontWeight: 500,
                  marginBottom: '1vh',
                }}
              >
                사용자에게 알림을 보내세요.
              </Typography>
              <NotificationForm />
            </Box>
            <Box sx={{
              width: isNarrow ? '100%' : '40%',
              height: isNarrow ? 'auto' : '100%',
              overflow: isNarrow ? 'visible' : 'hidden',
              ...(isNarrow && { mt: '2vh' })
            }}>
              <NotificationList />
            </Box>
          </Box>
        </Box>
        <NotiSnackbar />
      </SubLayout2>
    </SendNotiProvider>
  );
};

export default SendNotification;