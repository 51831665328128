import React from 'react';
import { Box, Table, TableHead, TableBody, TableRow, TableCell, Checkbox } from '@mui/material';
import defaultImage from '../../styles/icons/reports/reportIcon.png';
import clickedImage from '../../styles/icons/reports/report_click.png';
import formatAccidentType from '../../hook/formatAccidentType';
import useGeocodedAddresses from '../../hook/useGeocodedAddresses';

const getCityLevelAddress = (address) => {
  if (!address) return '위치 정보 없음';
  const parts = address.split(' ');
  const districtIndex = parts.findIndex(part => part.endsWith('구'));
  if (districtIndex !== -1) return parts.slice(districtIndex).join(' ');
  const cityIndex = parts.findIndex(part => part.endsWith('시'));
  if (cityIndex !== -1) return parts.slice(cityIndex).join(' ');
  return address;
};

const ReportTable = ({
  tabIndex,
  paginatedReportsN,
  paginatedReportsY,
  selectedReports,
  handleSelectReport,
  handleSelectAll,
  allSelected,
  handleRowClick,
  hoveredRows,
  setHoveredRows,
  formatDateTime,
  isNarrow,
  currentPage,
  rowsPerPage
}) => {

  const { getAddress, isLoading } = useGeocodedAddresses([...paginatedReportsN, ...paginatedReportsY]);

  const handleRowHover = (userId, requestDate) => {
    setHoveredRows(prev => ({...prev, [`${userId}-${requestDate}`]: true}));
  };

  const handleRowLeave = (userId, requestDate) => {
    setHoveredRows(prev => ({...prev, [`${userId}-${requestDate}`]: false}));
  };

  const styles = {
    tableBox: {
      overflowX: isNarrow ? 'auto' : 'hidden',
      overflowY: 'auto',
      maxHeight: 'calc(100vh - 20vh)',
      width: '100%',
    },
    table: {
      width: '100%',
      borderCollapse: 'collapse',
      borderSpacing: '0',
    },
    tableCell: {
      borderBottom: 'none',
      height: 'clamp(40px, 5.7vh,  5.7vh)',
      padding: 'clamp(6px, 0.5vw, 0.5vw)',
      color: '#141414',
      fontSize: 'clamp(12px, 1vw, 0.8vw)',
      fontWeight: 'bolder',
    },
    headerCell: {
      color: '#6B7280',
      fontWeight: 'bold',
      padding: 'clamp(6px, 0.5vw, 0.5vw)',
      height: 'clamp(40px, 5.7vw, 5.7vh)',
      fontSize: 'clamp(12px, 1vw, 0.8vw)',
    },
    selectedRow: {
      backgroundColor: 'rgba(78, 158, 249, 0.14)',
    },
    numberCell: {
      color: '#6B7280',
      borderBottom: 'none',
      padding: 'clamp(8px, 1vw, 1vw)',
      fontWeight: 'bold',
      fontSize: 'clamp(12px, 1.1vw, 0.8vw)',
    },
    imageCell: {
      cursor: 'pointer',
    },
    checkbox: {
      '& .MuiSvgIcon-root': {
        width: 'clamp(12px, 1vw, 1vw)',
        height: 'clamp(12px, 1vw, 1vw)',
      },
    },
  };

  const renderTable = (reports, isCompleted = false) => (
    <Box sx={styles.tableBox}>
      <Table sx={styles.table}>
        <TableHead>
          <TableRow>
            {!isCompleted && (
              <TableCell sx={{ ...styles.headerCell, width: '5%' }}>
                <Checkbox
                  checked={allSelected}
                  onChange={handleSelectAll}
                  sx={styles.checkbox}
                  indeterminate={selectedReports.length > 0 && selectedReports.length < reports.length}
                />
              </TableCell>
            )}
            {isCompleted && <TableCell sx={{ ...styles.headerCell, width: '5%' }}>No</TableCell>}
            <TableCell sx={{ ...styles.headerCell, width: '10%' }}>ID</TableCell>
            <TableCell sx={{ ...styles.headerCell, width: '20%' }}>{isCompleted ? '완료 시간' : '요청 날짜'}</TableCell>
            <TableCell sx={{ ...styles.headerCell, width: '20%' }}>사고 시간</TableCell>
            <TableCell sx={{ ...styles.headerCell, width: '10%' }}>사고 종류</TableCell>
            <TableCell sx={{ ...styles.headerCell, width: isCompleted ? '25%' : '35%' }}>위치</TableCell>
            {isCompleted && <TableCell sx={{ ...styles.headerCell, width: '10%' }}>보고서</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
        {reports.map((report, index) => {
            const rowKey = `${report.userId}-${report.requestDate}`;
            const rowNumber = currentPage * rowsPerPage + index + 1;
            const address = getAddress(report);
            const shortenedAddress = getCityLevelAddress(address);
            return (
              <TableRow
              key={rowKey}
              sx={!isCompleted && selectedReports.some(selected => selected.userId === report.userId && selected.requestDate === report.requestDate) ? styles.selectedRow : null}
            >
              {!isCompleted && (
                <TableCell sx={styles.tableCell}>
                  <Checkbox
                    checked={selectedReports.some(selected => selected.userId === report.userId && selected.requestDate === report.requestDate)}
                    onChange={() => handleSelectReport(report)}
                    sx={styles.checkbox}
                  />
                </TableCell>
              )}
      {isCompleted && <TableCell sx={{ ...styles.numberCell, width: '5%' }}>{rowNumber}</TableCell>}
                <TableCell sx={styles.tableCell}>{report.userId}</TableCell>
                <TableCell sx={styles.tableCell}>{formatDateTime(isCompleted ? report.completeTimestamp : report.requestDate)}</TableCell>
                <TableCell sx={styles.tableCell}>{formatDateTime(report.timeStamp)}</TableCell>
                <TableCell sx={styles.tableCell}>{formatAccidentType(report.accident_type)}</TableCell>
                <TableCell sx={styles.tableCell}>{shortenedAddress}</TableCell>
                {isCompleted && (
                  <TableCell 
                    sx={{ ...styles.tableCell, ...styles.imageCell }} 
                    onClick={() => handleRowClick(report)}
                    onMouseEnter={() => handleRowHover(report.userId, report.requestDate)}
                    onMouseLeave={() => handleRowLeave(report.userId, report.requestDate)}
                  >
                    <img 
                      src={hoveredRows[rowKey] ? clickedImage : defaultImage} 
                      alt="Report" 
                      style={{ width: 'clamp(16px, 1.8vw, 24px)', height: 'clamp(16px, 1.8vw, 24px)' }} 
                    />
                  </TableCell>
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Box>
  );

  if (isLoading) {
    return <div>주소 정보를 불러오는 중...</div>;
  }

  return tabIndex === 0 ? renderTable(paginatedReportsN) : renderTable(paginatedReportsY, true);
};

export default ReportTable;