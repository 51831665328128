import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import card1 from '../../../styles/img/info/card1.png';
import card2 from '../../../styles/img/info/card2.png';
import card3 from '../../../styles/img/info/card3.png';
import card4 from '../../../styles/img/info/card4.png';
import sub11 from '../../../styles/img/info/sub11.png';
import sub12 from '../../../styles/img/info/sub12.png';
import sub13 from '../../../styles/img/info/sub13.png';
import sub21 from '../../../styles/img/info/sub21.png';
import sub22 from '../../../styles/img/info/sub22.png';
import sub31 from '../../../styles/img/info/sub31.png';
import sub32 from '../../../styles/img/info/sub32.png';
import sub41 from '../../../styles/img/info/sub41.png';
import sub42 from '../../../styles/img/info/sub42.png';
import sub43 from '../../../styles/img/info/sub43.png';
import subEn11 from '../../../styles/img/info/subEn11.png';
import subEn12 from '../../../styles/img/info/subEn12.png';
import subEn13 from '../../../styles/img/info/subEn13.png';
import subEn21 from '../../../styles/img/info/subEn21.png';
import subEn22 from '../../../styles/img/info/subEn22.png';
import subEn31 from '../../../styles/img/info/subEn31.png';
import subEn32 from '../../../styles/img/info/subEn32.png';
import subEn41 from '../../../styles/img/info/subEn41.png';
import subEn42 from '../../../styles/img/info/subEn42.png';
import subEn43 from '../../../styles/img/info/subEn43.png';
import subJp11 from '../../../styles/img/info/subJp11.png';
import subJp12 from '../../../styles/img/info/subJp12.png';
import subJp13 from '../../../styles/img/info/subJp13.png';
import subJp21 from '../../../styles/img/info/subJp21.png';
import subJp22 from '../../../styles/img/info/subJp22.png';
import subJp31 from '../../../styles/img/info/subJp31.png';
import subJp32 from '../../../styles/img/info/subJp32.png';
import subJp41 from '../../../styles/img/info/subJp41.png';
import subJp42 from '../../../styles/img/info/subJp42.png';
import subJp43 from '../../../styles/img/info/subJp43.png';
import '../styles/Carousel.css';

const Carousel = ({ scale, language ,mobileScale, text}) => {
  const carouselRef = useRef(null);
  const [currentCard, setCurrentCard] = useState(1);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 431);
  const cards = useMemo(() => [card4, card1, card2, card3, card4, card1], []);
  const mobileCards = useMemo(() => [card1, card2, card3, card4], []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 431);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    document.documentElement.style.setProperty('--scale', scale);
    document.documentElement.style.setProperty('--mobile-scale', mobileScale);
  }, [scale, mobileScale]);


  // Preload images
  useEffect(() => {
    const preloadImage = (src) => {
      const img = new Image();
      img.src = src;
    };

    cards.forEach(preloadImage);
    // Preload language-specific images here
  }, [cards, language]);

  const getCardContent = useCallback((index) => {
    const cardContent = {
      1: {
        title: {
          ko: "사고 내역",
          en: "Accident Details",
          ja: "事故履歴"
        },
        subtitle: {
          ko: "홈 화면과 마이페이지에서\n사고내역 확인과 관리",
          en: "Check and manage accident details\nfrom the home screen and My Page",
          ja: "ホーム画面とマイページで\n事故履歴の確認と管理"
        },
        images: {
          ko: [sub11, sub12, sub13],
          en: [subEn11, subEn12, subEn13],
          ja: [subJp11, subJp12, subJp13]
        },
        overlayOpacity: 0.7
      },
      2: {
        title: {
          ko: "자동 감지 신고",
          en: "Automatic Detection Report",
          ja: "自動検知報告"
        },
        subtitle: {
          ko: "실시간 자동 감지\n시스템을 통해 사고 대처",
          en: "Respond to accidents\nwith a real-time automatic detection system",
          ja: "リアルタイム自動検知\nシステムで事故に対処"
        },
        images: {
          ko: [sub21, sub22],
          en: [subEn21, subEn22],
          ja: [subJp21, subJp22]
        },
        overlayOpacity: 0.7
      },
      3: {
        title: {
          ko: "메인 화면",
          en: "Main Screen",
          ja: "メイン画面"
        },
        subtitle: {
          ko: "첫 화면에서 필요한 정보만\n쉽게 빠르게 확인",
          en: "Quickly and easily access\nimportant information on the main screen",
          ja: "初期画面で必要な情報のみ\n簡単に素早く確認"
        },
        images: {
          ko: [sub31, sub32],
          en: [subEn31, subEn32],
          ja: [subJp31, subJp32]
        },
        overlayOpacity: 0.7
      },
      4: {
        title: {
          ko: "스캐너 연결",
          en: "Scanner Connection",
          ja: "スキャナー接続"
        },
        subtitle: {
          ko: "안전하고 신속한 대응을 위한\nOBD 스캐너 연동",
          en: "Connect OBD scanner\nfor safe and swift response",
          ja: "安全で迅速な対応のための\nOBDスキャナー連動"
        },
        images: {
          ko: [sub41, sub42, sub43],
          en: [subEn41, subEn42, subEn43],
          ja: [subJp41, subJp42, subJp43]
        },
        overlayOpacity: 0.3
      }
    };
 const contentIndex = ((index - 1 + 4) % 4) + 1;
    const content = cardContent[contentIndex];
    return {
      ...content,
      title: content.title[language],
      subtitle: content.subtitle[language],
      images: content.images[language]
    };
  }, [language]);

  useEffect(() => {
    if (!isMobile) {
      const interval = setInterval(() => {
        if (!isTransitioning) {
          nextCard();
        }
      }, 3000);

      return () => clearInterval(interval);
    }
  }, [currentCard, isTransitioning, isMobile]);

  const nextCard = useCallback(() => {
    setIsTransitioning(true);
    setCurrentCard((prev) => {
      const next = prev + 1;
      if (next >= cards.length - 1) {
        setTimeout(() => {
          if (carouselRef.current) {
            carouselRef.current.style.transition = 'none';
            setCurrentCard(1);
            setTimeout(() => {
              if (carouselRef.current) {
                carouselRef.current.style.transition = 'transform 0.5s ease';
              }
              setIsTransitioning(false);
            }, 50);
          }
        }, 500);
      } else {
        setTimeout(() => setIsTransitioning(false), 500);
      }
      return next;
    });
  }, [cards.length]);

  const prevCard = useCallback(() => {
    setIsTransitioning(true);
    setCurrentCard((prev) => {
      const next = prev - 1;
      if (next <= 0) {
        setTimeout(() => {
          if (carouselRef.current) {
            carouselRef.current.style.transition = 'none';
            setCurrentCard(cards.length - 2);
            setTimeout(() => {
              if (carouselRef.current) {
                carouselRef.current.style.transition = 'transform 0.5s ease';
              }
              setIsTransitioning(false);
            }, 50);
          }
        }, 500);
      } else {
        setTimeout(() => setIsTransitioning(false), 500);
      }
      return next;
    });
  }, [cards.length]);

  const CarouselCard = ({ image, isCenter, index }) => {
    const content = getCardContent(index);
    const overlayOpacity = content.overlayOpacity;
  
    return (
      <div 
        className={`carousel-card ${isCenter && !isMobile ? 'carousel-card-center' : ''}`} 
        style={{backgroundImage: `url(${image})`}}
      >
        <div 
          className="carousel-card-overlay" 
          style={{backgroundColor: `rgba(0, 0, 0, ${overlayOpacity})`}}
        >
          <div className="carousel-card-title">{content.title}</div>
          <div className="carousel-card-subtitle">{content.subtitle}</div>
          <div className="carousel-card-images">
            {content.images.map((img, i) => (
              <img key={i} src={img} alt={`Sub ${index}-${i+1}`} />
            ))}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="carousel-section">
    {isMobile && (
      <div className="safe-app-title-container">
        <div className="safe-app-title-line"></div>
        <h2 className="safe-app-title">{text.appIntro}</h2>
      </div>
    )}
      <div 
        ref={carouselRef}
        className="carousel-container"
        style={isMobile ? {} : {
          transform: `translateX(${-(currentCard * (762) - 381) * scale}px)`,
        }}
      >
        {(isMobile ? mobileCards : cards).map((card, index) => (
          <CarouselCard 
            key={index} 
            image={card} 
            isCenter={!isMobile && index === currentCard}
            index={isMobile ? index + 1 : ((index - 1 + 6) % 6) + 1}
          />
        ))}
      </div>
      {!isMobile && (
        <div className="carousel-button-container">
          <button className="carousel-button" onClick={prevCard}>❮</button>
          <button className="carousel-button" onClick={nextCard}>❯</button>
        </div>
      )}
    </div>
  );
};

export default React.memo(Carousel);