import React from 'react';
import { Paper, Box, Typography, Chip } from '@mui/material';

const styles = {
  paper: {
    height: '100%',
    overflow: 'auto',
    backgroundColor: '#FFFFFF',
    padding: 'clamp(10px, 2vw, 16px)',
  },
  subHeader: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 'clamp(5px, 1vw, 10px)',
  },
  subTitle: {
    fontSize: 'clamp(18px, 3vw, 24px)',
    fontWeight: '600',
    color: '#141414',
  },
  userId: {
    color: '#141414',
    fontWeight: '600',
    fontSize: 'clamp(14px, 2.5vw, 20px)',
    lineHeight: 'clamp(24px, 4vw, 48px)',
  },
  body: {
    fontSize: 'clamp(14px, 2.5vw, 18px)',
    lineHeight: '140%',
    color: '#141414',
    marginTop: 'clamp(5px, 1vw, 10px)',
  },
  timestamp: {
    color: '#6B7280',
    fontWeight: '500',
    fontSize: 'clamp(12px, 2vw, 16px)',
    lineHeight: 'clamp(24px, 4vw, 48px)',
  },
};

const ComplaintDetails = ({ complaint }) => {
  return (
    <Paper elevation={0} sx={styles.paper}>
      <Box sx={styles.subHeader}>
        <Typography sx={styles.subTitle}>{complaint.title}</Typography>
        <Chip
          label={complaint.answerBody ? '답변완료' : '미완료'}
          sx={{
            marginLeft: 'clamp(5px, 1vw, 10px)',
            padding: 'clamp(2px, 0.5vw, 4px)',
            backgroundColor: complaint.answerBody ? '#2563EB' : '#E8680F',
            color: '#FFFFFF',
            fontSize: 'clamp(10px, 1.5vw, 14px)',
          }}
        />
      </Box>
      <Typography variant="body1" sx={styles.userId}>
        ID : {complaint.userId}
      </Typography>
      <Typography variant="body1" sx={styles.body}>
        {complaint.body}
      </Typography>
      <Typography variant="body2" sx={styles.timestamp}>
        {complaint.timestamp}
      </Typography>
    </Paper>
  );
};

export default ComplaintDetails;