import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, Grid, TextField } from '@mui/material';
import { useAuth } from '../../context/AuthProvider';
import { ref, get, update } from 'firebase/database';
import { database } from '../../firebase'; // Adjust path as necessary
import Ip from "../../styles/icons/app_management/Ip.png";
import aos from "../../styles/icons/app_management/aos.png";
import appmanager from "../../styles/icons/app_management/appmanager.png";
import customer from "../../styles/icons/app_management/customer.png";
import ios from "../../styles/icons/app_management/ios.png";
import popup from "../../styles/icons/app_management/popup.png";
import popupcontent from "../../styles/icons/app_management/popupcontent.png";
import url from "../../styles/icons/app_management/url.png";
import CustomButton from '../../components/utils/CustomButton'; // Adjust path as necessary
import IpManagementModal from '../../components/app-management/IpManagementModal'; // Adjust path as necessary
import SubLayout from '../../components/layout/SubLayout'; // Import SubLayout


const AppManagement = () => {
  const { currentUser } = useAuth();
  const isViewer = currentUser?.role === 'viewer';
  const [formData, setFormData] = useState({});
  const [alertOpen, setAlertOpen] = useState(false);
  const [ipDialogOpen, setIpDialogOpen] = useState(false);

  
  const keyToKorean = {
    app_check: '앱 서비스 점검 유무',
    app_check_body: '팝업 내용',
    app_check_title: '팝업 타이틀',
    cs_email: '고객 센터 이메일',
    aos_url: '안드로이드 설치 경로',
    aos_version: '안드로이드 버전',
    ios_url: 'IOS 설치 경로',
    ios_version: 'IOS 버전',
    term_conditions_url: '이용 약관 WEB URL',
    term_policy_url: '개인정보 보호정책 WEB URL',
    accessIP: 'IP 주소 ',
  };

  // 아이콘을 가져오는 함수
  const getIconForKey = (key) => {
    switch (key) {
      case 'accessIP': return Ip;
      case 'app_check': return appmanager;
      case 'app_check_body': return popupcontent;
      case 'app_check_title': return popup;
      case 'cs_email': return customer;
      case 'aos_url':
      case 'ios_url':
      case 'term_conditions_url':
      case 'term_policy_url': return url;
      case 'aos_version': return aos;
      case 'ios_version': return ios;
      default: return null;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const dbRef = ref(database, 'policy');
      const snapshot = await get(dbRef);
      if (snapshot.exists()) {
        setFormData(snapshot.val());
      }
    };
    fetchData();
  }, []);

  const handleChange = (e) => {
    if (isViewer) return;
    const { id, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [id]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isViewer) return;
    const dbRef = ref(database, 'policy');
    await update(dbRef, formData);
    setAlertOpen(true);
    setTimeout(() => setAlertOpen(false), 3000);
  };

  const openIpDialog = () => {
    setIpDialogOpen(true);
  };

  const closeIpDialog = () => {
    setIpDialogOpen(false);
  };

  return (
    <SubLayout>
      <Typography
        variant="h4"
        sx={{
          fontWeight: 'bold',
          marginBottom: 'clamp(10px, 1.48vh, 20px)',
          fontSize: 'clamp(24px, 2vw, 36px)',
        }}
      >
        앱 관리
      </Typography>
      <Box sx={{
        display: 'flex',
        marginBottom: 'clamp(5px, 1vh, 15px)',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap'
      }}>
        <Typography variant="h6" sx={{
          fontSize: 'clamp(16px, 1vw, 24px)' , // 최소 16px, 최대 24px
          color: '#6B7280',
          fontWeight: 500,
          marginRight: 'clamp(10px, 1vw, 20px)',
        }}>
          앱 설정 및 구성을 관리하세요.
        </Typography>
        <CustomButton 
          onClick={handleSubmit} 
          disabled={isViewer}
        >
          저장
        </CustomButton>
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {Object.keys(formData).length > 0 ? (
              Object.keys(formData).map((key) => (
                <Grid item xs={12} key={key}>
                  <Box display="flex" alignItems="center" sx={{
                    minHeight: 'clamp(40px, 5.74vh, 70px)',
                    borderRadius: 'clamp(8px, 1vw, 16px)',
                    padding: 'clamp(8px, 1vw, 16px)',
                  }}>
                    <Box display="flex" alignItems="center" sx={{
                      width: 'clamp(150px, 20%, 250px)',
                      marginRight: 'clamp(10px, 2vw, 30px)',
                    }}>
                      <Box component="img" src={getIconForKey(key)} alt={key} sx={{
                        width: 'clamp(24px, 3.89vh, 42px)',
                        height: 'clamp(24px, 3.89vh, 42px)',
                        marginRight: 'clamp(8px, 1vw, 16px)'
                      }} />
                      <Typography variant="body1" sx={{
                        fontWeight: 'bold',
                        fontSize: 'clamp(12px, 1.48vh, 18px)',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}>
                        {keyToKorean[key] || key.replace(/_/g, ' ').toUpperCase()}
                      </Typography>
                    </Box>
                    {key === 'accessIP' ? (
                      <CustomButton onClick={openIpDialog} disabled={isViewer} sx={{
                        minWidth: 'clamp(60px, 10%, 100px)',
                        fontSize: 'clamp(12px, 1.2vh, 16px)',
                        marginLeft: 0,  // Make sure this is aligned left
                      }}>
                        관리
                      </CustomButton>
                    ) : (
                      <TextField
                        id={key}
                        variant="outlined"
                        fullWidth
                        disabled={isViewer}
                        value={formData[key]}
                        onChange={handleChange}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            backgroundColor: 'white',
                          },
                          '& .MuiInputBase-input': {
                            fontSize: 'clamp(12px, 1.48vh, 18px)',
                            padding: 'clamp(8px, 1vh, 16px)',
                          }
                        }}
                      />
                    )}
                  </Box>
                </Grid>
              ))
            ) : (
              <Typography>Loading...</Typography>
            )}
          </Grid>
        </form>
      </Box>
      {alertOpen && (
        <Box sx={{
          mt: 'clamp(10px, 1.85vh, 20px)',
          textAlign: 'center',
          color: 'green',
          fontSize: 'clamp(12px, 1.48vh, 18px)'
        }}>
          성공적으로 저장이 완료되었습니다.
        </Box>
      )}
      <IpManagementModal open={ipDialogOpen} onClose={closeIpDialog} />
    </SubLayout>
  );
};

export default AppManagement;
