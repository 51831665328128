import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Box, TableContainer, Checkbox } from '@mui/material';

const GenericTable = ({ columns, data, itemsPerPage = 10, showCheckboxes = false, onCheckboxChange, onRowClick }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(itemsPerPage);



    return (
      <Box sx={{ 
        marginBottom: 'clamp(20px, 5.37vh, 40px)',
        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
        borderRadius: 'clamp(8px, 0.52vw, 10px)',
        overflow: 'hidden'
      }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <TableCell 
                    key={column.id} 
                    sx={{ 
                      color: '#6B7280', 
                      fontSize: 'clamp(12px, 0.83vw, 16px)', 
                      fontWeight: 'bold', 
                      width: column.width,
                      padding: 'clamp(8px, 0.78vw, 15px)',
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((item, index) => (
                <TableRow
                  key={item.userId || index}
                  onClick={() => onRowClick(item)} 
                  sx={{
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: 'rgba(78, 158, 249, 0.14)',
                    },
                  }}
                >
                  {columns.map(column => (
                    <TableCell 
                      key={`${column.id}-${item.userId || index}`} 
                      sx={{ 
                        color: column.id === 'userId' ? '#141414' : '#6B7280', 
                        fontSize: 'clamp(16px, 0.83vw,  0.83vw)',
                        fontWeight: column.id === 'userId' ? 'bold' : 'normal',
                        width: column.width,
                        padding: 'clamp(8px, 0.78vw, 0.78vw)',
                        borderBottom: 'none',
                      }}
                    >
                      {column.render ? column.render(item) : item[column.id]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  };
  
  export default GenericTable;