import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import Myren from '../../styles/icons/reports/myrenlogo.png';

const Page2 = ({ allData, car_speed }) => {
  const [data, setData] = useState([]);
  const [speedDomain, setSpeedDomain] = useState([0, 200]);
  const [rpmDomain, setRpmDomain] = useState([0, 15000]);
  const [loadDomain, setLoadDomain] = useState([0, 150]);

  useEffect(() => {
    if (allData) {
      const parsedData = parseData(allData, car_speed);
      setData(parsedData);
      updateDomains(parsedData);
    }
  }, [allData, car_speed]);

  const styles = {
    page: {
      width: '210mm',
      height: '297mm',
      padding: '10mm',
      margin: '0 auto',
      marginLeft:'0vw',
      backgroundColor: 'white',
      boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)',
    },
    chartContainer: {
      backgroundColor: '#F9FAFB',
      border: '0.712644px solid #D1D5DB',
      borderRadius: '5.70115px',
      padding: '20px',
      marginTop: '10px',
    },
    titleContainer: {
      display: 'flex',
      alignItems: 'flex-end',
      marginBottom: '10px',
    },
    mainTitle: {
      fontFamily: 'Noto Sans KR, sans-serif',
      fontSize: '20px',
      fontWeight: '700',
      color: '#2563EB',
    },
    subTitle: {
      fontFamily: 'Noto Sans KR, sans-serif',
      fontSize: '12px',
      fontWeight: '700',
      color: '#000000',
      marginLeft: '10px',
      marginTop: '5px',
    },
    xAxisLabel: {
      fontFamily: 'Pretendard',
      fontWeight: '400',
      fontSize: '10px',
      lineHeight: '13px',
      color: '#6D7280',
    },
    yAxisLabel: {
      fontFamily: 'Pretendard',
      fontWeight: '400',
      fontSize: '10px',
      lineHeight: '13px',
    },
  };

 
  const parseData = (allData, car_speed) => {
    if (!allData) return [];
    const timePoints = ['mfive', 'mfour', 'mthree', 'mtwo', 'mone', 'now', 'pone'];
    return timePoints.map((point, index) => ({
      time: index - 5,
      speed: point === 'now' ? parseFloat(car_speed) || 0 : parseFloat(allData[point]?.speed) || 0,
      rpm: parseFloat(allData[point]?.rpm) || 0,
      load: parseFloat(allData[point]?.load || allData[point]?.engin_load) || 0,
    }));
  };

  const updateDomains = (data) => {
    const maxSpeed = Math.max(...data.map(d => d.speed), 200);
    const maxRpm = Math.max(...data.map(d => d.rpm), 10000);
    const maxLoad = Math.max(...data.map(d => d.load), 100);

    setSpeedDomain([0, Math.ceil(maxSpeed / 50) * 50]);
    setRpmDomain([0, Math.ceil(maxRpm / 5000) * 5000]);
    setLoadDomain([0, Math.ceil(maxLoad / 50) * 50]);
  };
  
  const CustomTooltip = ({ active, payload, label, dataKey }) => {
    if (active && payload && payload.length) {
      let value;
      if (dataKey === 'speed') {
        value = `${payload[0].value} km`;
      } else if (dataKey === 'rpm') {
        value = `${payload[0].value} rpm`;
      } else if (dataKey === 'load') {
        value = `${parseFloat(payload[0].value).toFixed(2)}°C`;
      }
  
      return (
        <Box sx={{ backgroundColor: 'white', padding: '5px', border: '1px solid #ccc' }}>
          <Typography variant="body2">{`Time: ${label} sec`}</Typography>
          <Typography variant="body2">
            {dataKey === 'load' ? `temp: ${value}` : `${dataKey}: ${value}`}
          </Typography>
        </Box>
      );
    }
  
    return null;
  };

  const renderYAxis = (domain, tickCount, unit, color) => (
    <YAxis
      domain={domain}
      axisLine={false}
      tickLine={false}
      tick={{ fontSize: 10, fill: color }}
      ticks={[...Array(tickCount)].map((_, i) => domain[0] + (domain[1] - domain[0]) * i / (tickCount - 1))}
      label={{
        value: `${unit} / sec`,
        position: 'insideBottom',
        offset: -28,
        fill: color,
        fontFamily: 'Pretendard',
        fontWeight: '400',
        fontSize: '10px',
        lineHeight: '13px',
      }}
    />
  );

  return (
    <Box sx={styles.page}>
      <Box sx={styles.chartContainer}>
        <Box sx={styles.titleContainer}>
          <Typography sx={styles.mainTitle}>세이프카</Typography>
          <Typography sx={styles.subTitle}>자동차 속도 (km/h)</Typography>
        </Box>
        <ResponsiveContainer width="100%" height={250}>
          <LineChart data={data} margin={{ left: -5 }}>
            <CartesianGrid stroke="#D2D5DA" vertical={false} />
            <XAxis dataKey="time" tick={{ fontSize: 10, fill: '#000000' }} />
            {renderYAxis(speedDomain, 5, "km", '#FF7300')}
            <Tooltip content={<CustomTooltip dataKey="speed" />} />
            <Line type="monotone" dataKey="speed" stroke="#FF7300" dot={false} />
          </LineChart>
        </ResponsiveContainer>
      </Box>
      <Box sx={styles.chartContainer}>
        <Box sx={styles.titleContainer}>
          <Typography sx={styles.mainTitle}>세이프카</Typography>
          <Typography sx={styles.subTitle}>자동차 RPM (rpm)</Typography>
        </Box>
        <ResponsiveContainer width="100%" height={250}>
          <LineChart data={data} margin={{ left: -5 }}>
            <CartesianGrid stroke="#D2D5DA" vertical={false} />
            <XAxis dataKey="time" tick={{ fontSize: 10, fill: '#000000' }} />
            {renderYAxis(rpmDomain, 5, "rpm", '#8884D8')}
            <Tooltip content={<CustomTooltip dataKey="rpm" />} />
            <Line type="monotone" dataKey="rpm" stroke="#8884D8" dot={false} />
          </LineChart>
        </ResponsiveContainer>
      </Box>
      <Box sx={styles.chartContainer}>
        <Box sx={styles.titleContainer}>
          <Typography sx={styles.mainTitle}>세이프카</Typography>
          <Typography sx={styles.subTitle}>엔진 온도 (°C)</Typography>
        </Box>
        <ResponsiveContainer width="100%" height={250}>
          <LineChart data={data} margin={{ left: -5 }}>
            <CartesianGrid stroke="#D2D5DA" vertical={false} />
            <XAxis dataKey="time" tick={{ fontSize: 10, fill: '#000000' }} />
            {renderYAxis(loadDomain, 5, "°C", '#387908')}
            <Tooltip content={<CustomTooltip dataKey="load" />} />
            <Line type="monotone" dataKey="load" stroke="#387908" dot={false} />
          </LineChart>
        </ResponsiveContainer>
      </Box>
      <Box display="flex" justifyContent="end" width="100%" mt={2}>
        <img src={Myren} alt="Myren" style={{ width: '97px', height: '19px' }} />
      </Box>
    </Box>
  );
};


export default Page2;
