import React from 'react';
import { Box, Stack } from '@mui/material';
import { useAuth } from '../../context/AuthProvider';
import { styled } from '@mui/system';
import SearchBox from '../utils/SearchBox';
import CustomAddButton from '../utils/CustomAddButton'

const ChipStyled = styled(Box)(({ theme, selected }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 'clamp(4px, 0.42vw, 12px) clamp(4px, 0.42vw, 12px)',
  gap: 'clamp(5px, 0.52vw, 15px)',
  minWidth: 'clamp(45px, 4.17vw, 120px)',
  height: 'clamp(16px, 1.67vw, 48px)',
  borderRadius: 'clamp(8px, 0.83vw, 24px)',
  fontFamily: 'Pretendard',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: 'clamp(10px, 0.73vw, 21px)',
  lineHeight: 'clamp(26px, 2.5vw, 72px)',
  cursor: 'pointer',
  backgroundColor: selected ? '#2563EB' : '#FFFFFF',
  color: selected ? '#FFFFFF' : '#000000',
  border: selected ? 'none' : 'clamp(1px, 0.1vw, 3px) solid #E5E7EB',
  '&:hover': {
    backgroundColor: '#FFFFFF',
    border: 'clamp(1px, 0.1vw, 3px) solid #2563EB',
    color: '#000000',
  },
}));
const MemberActions = ({ filter, setFilter, searchTerm, setSearchTerm, searchType, setSearchType, searchTypes, onAddMember }) => {
  const { currentUser } = useAuth();
  const isViewer = currentUser?.role === 'viewer';

  return (
    <Box sx={{ 
      display: 'flex', 
      justifyContent: 'space-between', 
      alignItems: 'center', 
      marginBottom: 'clamp(5px, 0.52vw, 15px)' 
    }}>
      <Stack direction="row" spacing='clamp(5px, 0.52vw, 15px)'>
        <ChipStyled onClick={() => setFilter('all')} selected={filter === 'all'}>회원</ChipStyled>
        <ChipStyled onClick={() => setFilter('deleted')} selected={filter === 'deleted'}>탈퇴회원</ChipStyled>
      </Stack>
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        gap: 'clamp(10px, 1.04vw, 30px)' 
      }}>
        <SearchBox
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          searchType={searchType}
          setSearchType={setSearchType}
          searchTypes={searchTypes}
        />
        <CustomAddButton 
          onClick={() => !isViewer && onAddMember()} 
          disabled={isViewer}
        >
          회원 추가
        </CustomAddButton>
      </Box>
    </Box>
  );
};

export default MemberActions;