import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import ComplaintSection from '../../components/service/ComplaintSection';
import { ServiceProvider } from '../../context/ServiceContext';
import SubLayout from '../../components/layout/SubLayout';

const CustomerService = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchType, setSearchType] = useState('userId');
  const [tabIndex, setTabIndex] = useState(0);

  const searchTypes = [
    { value: 'userId', label: 'ID' },
    { value: 'body', label: '내용' },
    { value: 'title', label: '제목' }
  ];

  return (
    <ServiceProvider>
      <SubLayout>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1vh' }}>
          <Typography variant="h4" sx={{ fontWeight: 'bold', marginBottom: '1vh',   fontSize: 'clamp(24px, 2vw, 36px)'  }}>
            고객센터
          </Typography>
        </Box>
        
        <ComplaintSection
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          searchType={searchType}
          setSearchType={setSearchType}
          searchTypes={searchTypes}
          tabIndex={tabIndex}
          setTabIndex={setTabIndex}
        />
      </SubLayout>
    </ServiceProvider>
  );
};

export default CustomerService;