import React from 'react';
import { Box, Grid, Typography, Divider, Chip } from '@mui/material';
import { useFormatData } from '../../../hook/useFormatData';

const styles = {
  infoSection: (isNarrow) => ({
    padding: 'clamp(15px, 1.56vw, 30px)',
    backgroundColor: '#FFFFFF',
    borderRadius: 'clamp(10px, 1.04vw, 20px)',
    height: isNarrow ? '27vh' : '55vh',
    overflow: isNarrow ? 'auto' : 'hidden',
    '&::-webkit-scrollbar': {
      width: '0.42vw',
      height: '0.42vw',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
      borderRadius: '0.21vw',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#555',
    },
  }),
  title: {
    fontWeight: 'bold',
    marginBottom: 'clamp(10px, 1vh, 20px)',
    fontSize: 'clamp(16px, 1.25vw, 24px)',
  },
  chip: {
    backgroundColor: 'rgba(78, 158, 249, 0.14)',
    color: '#2563EB',
    marginLeft: 'clamp(4px, 0.4vw, 8px)',
    fontSize: 'clamp(10px, 0.63vw, 12px)',
    fontWeight: 'bold',
    height: 'clamp(20px, 1.5vw, 28px)',
    borderRadius: 'clamp(11px, 1.15vw, 22px)',
  },
  divider: {
    height: 'clamp(40px, 4.63vh, 50px)',
    marginTop: 'clamp(5px, 0.52vw, 10px)',
  },
  infoValue: {
    fontWeight: 'bold',
    fontSize: 'clamp(14px, 1.04vw, 20px)',
  },
  infoLabel: {
    color: '#6B7280',
    fontSize: 'clamp(12px, 0.83vw, 16px)',
  },
  gridItem: (isNarrow) => ({
    marginBottom: isNarrow ? 'clamp(5px, 0.5vw, 10px)' : '0',
  }),
};
const VehicleInfoSection = ({ selectedAccident, isNarrow }) => {
  const formatCrashValue = (value) => {
    return value ? parseFloat(value).toFixed(2) : '0';
  };

  const formatDecimalValue = (value) => {
    return value ? parseFloat(value).toFixed(2) : '0';
  };
  const {  formatTransmission } = useFormatData();
  
  const ConditionalDivider = ({ index }) => {
    if (isNarrow) {
      // 65% 이하일 때, 차량모델과 RPM 사이의 Divider를 숨깁니다 (index 2)
      return index !== 2 ? <Divider orientation="vertical" flexItem sx={styles.divider} /> : null;
    } else {
      // 65% 이상일 때, 차량번호와 차량모델 사이, RPM과 속도 사이의 Divider를 숨깁니다 (index 1, 3)
      return index !== 1 && index !== 3 ? <Divider orientation="vertical" flexItem sx={styles.divider} /> : null;
    }
  };

  return (
    <Box sx={styles.infoSection(isNarrow)}>
      <Typography sx={styles.title}>
        차량 정보
        <Chip label={selectedAccident.userCarInfo || 'N/A'} sx={styles.chip} />
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={isNarrow ? 4 : 6} sx={styles.gridItem(isNarrow)}>
          <Typography sx={styles.infoValue}>
            {selectedAccident.userCarManufacturer || 'N/A'}
          </Typography>
          <Typography sx={styles.infoLabel}>
            자동차 제조사
          </Typography>
        </Grid>
        <ConditionalDivider index={0} />
        <Grid item xs={isNarrow ? 4 : 5} sx={styles.gridItem(isNarrow)}>
          <Typography sx={styles.infoValue}>
            {selectedAccident.userCarNumber || 'N/A'}
          </Typography>
          <Typography sx={styles.infoLabel}>
            자동차 번호
          </Typography>
        </Grid>
        <ConditionalDivider index={1} />
        <Grid item xs={isNarrow ? 3 : 6} sx={styles.gridItem(isNarrow)}>
          <Typography sx={styles.infoValue}>
            {selectedAccident.userCarModelName || 'N/A'}
          </Typography>
          <Typography sx={styles.infoLabel}>
            자동차 모델 명
          </Typography>
        </Grid>
        <ConditionalDivider index={2} />
        <Grid item xs={isNarrow ? 4 : 5} sx={styles.gridItem(isNarrow)}>
          <Typography sx={styles.infoValue}>
            {selectedAccident.car_rpm || '0'} rpm
          </Typography>
          <Typography sx={styles.infoLabel}>
            자동차 RPM 
          </Typography>
        </Grid>
        <ConditionalDivider index={3} />
        <Grid item xs={isNarrow ? 4 : 6} sx={styles.gridItem(isNarrow)}>
          <Typography sx={styles.infoValue}>
            {selectedAccident.car_speed || '0.0'} km
          </Typography>
          <Typography sx={styles.infoLabel}>
            자동차 속도 (km/h)
          </Typography>
        </Grid>
        <ConditionalDivider index={4} />
        <Grid item xs={isNarrow ? 3 : 5} sx={styles.gridItem(isNarrow)}>
        <Typography sx={styles.infoValue}>
            {formatDecimalValue(selectedAccident.engin_load)} °C
          </Typography>
          <Typography sx={styles.infoLabel}>
            엔진 온도(°C)
          </Typography>
        </Grid>
        <Grid item xs={isNarrow ? 4 : 6} sx={styles.gridItem(isNarrow)}>
          <Typography sx={styles.infoValue}>
            {formatCrashValue(selectedAccident.crashValue)} G
          </Typography>
          <Typography sx={styles.infoLabel}>
            충돌 값(G)
          </Typography>
        </Grid>
        <ConditionalDivider index={6} />
        <Grid item xs={isNarrow ? 4 : 5} sx={styles.gridItem(isNarrow)}>
          <Typography sx={styles.infoValue}>
          {formatTransmission(selectedAccident.transmission)}
          </Typography>
          <Typography sx={styles.infoLabel}>
            기어 변속기
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default VehicleInfoSection;