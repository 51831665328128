import React, { useState, useEffect } from 'react';
import { Grid, Card, Typography, Box, TablePagination } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import useGeocodedAddresses from '../../hook/useGeocodedAddresses';
import useWindowDimensions from '../../hook/useWindowDimensions';


const styles = {
  container: (isNarrow) => ({
    height: '100%',
    overflow: 'hidden',

  }),
  gridContainer: {
    padding: 'clamp(15px, 0.9vw, 0.9vw)',
  },
  gridItem: {
    marginBottom: '0.1vw',
  },
  card: (isSelected) => ({
    cursor: 'pointer',
    backgroundColor: isSelected ? '#2563EB' : '#F3F4F6',
    color: isSelected ? '#FFFFFF' : '#000000',
    borderRadius: 'clamp(6px, 0.52vw, 0.52vw)',
    boxShadow: isSelected ? '0 4px 6px rgba(0, 0, 0, 0.1)' : 'none',
    padding: 'clamp(15px, 0.9vw, 0.9vw)',
  }),
  locationIcon: (isSelected) => ({
    color: isSelected ? '#FFFFFF' : '#6B7280',
    width: 'clamp(16px, 1.04vw, 1.04vw)',
    height: 'clamp(16px, 1.04vw, 1.04vw)',
  }),
  typography: {
    label: (isSelected) => ({
      fontSize: 'clamp(10px, 0.73vw, 0.73vw)',
      color: isSelected ? '#FFFFFF' : '#6B7280',
      fontWeight: 'bold',
    }),
    value: (isSelected) => ({
      fontSize: 'clamp(12px, 0.73vw, 0.73vw)',
      fontWeight: 'bolder',
      color: isSelected ? '#FFFFFF' : '#000000',
    }),
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    overflowX: 'hidden',
    marginTop: 'clamp(0, 0.2vw, 0.2vw)',
    paddingRight: 'clamp(15px, 0.9vw, 0.9vw)',
  },
  pagination: (isNarrow) => ({
    '& .MuiTablePagination-toolbar': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    '& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows': {
      fontSize: 'clamp(5px, 0.73vw, 0.73vw)',
      display: isNarrow ? 'none' : 'block',
    },
    '& .MuiTablePagination-select': {
      fontSize: 'clamp(5px, 0.73vw, 0.73vw)',
      marginRight: 'clamp(4px, 0.3vw, 0.3vw)',
      display: isNarrow ? 'none' : 'block',
    },
    '& .MuiTablePagination-menuItem': {
      fontSize: 'clamp(10px, 0.73vw, 0.73vw)',
    },
    '& .MuiTablePagination-actions': {
      marginLeft: isNarrow ? 0 : 'clamp(4px, 0.3vw, 0.3vw)',
    },
    '& .MuiIconButton-root': {
      padding: 'clamp(4px, 0.3vw, 0.3vw)',
    },
  }),
};
const formatDate = (dateString) => dateString.split(' ')[0].replace(/-/g, '.');
const formatTime = (dateString) => dateString.split(' ')[1];

const getCityLevelAddress = (address) => {
  if (!address) return '위치 정보 없음';
  const parts = address.split(' ');
  const districtIndex = parts.findIndex(part => part.endsWith('구'));
  if (districtIndex !== -1) return parts.slice(districtIndex).join(' ');
  const cityIndex = parts.findIndex(part => part.endsWith('시'));
  if (cityIndex !== -1) return parts.slice(cityIndex).join(' ');
  return address;
};

const AccidentList = ({ accidents, handleAccidentClick, selectedAccident }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(7);
  const { getAddress } = useGeocodedAddresses(accidents);
  const { isNarrow } = useWindowDimensions();

  const sortedAccidents = accidents.sort((a, b) => new Date(b.timeStamp) - new Date(a.timeStamp));
  const currentAccidents = sortedAccidents.slice(currentPage * rowsPerPage, (currentPage + 1) * rowsPerPage);

  const handlePageChange = (event, newPage) => setCurrentPage(newPage);
  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  return (
    <Box sx={styles.container(isNarrow)}>
      <Grid container spacing={2} sx={styles.gridContainer}>
      {currentAccidents.map((accident) => {
          const isSelected = selectedAccident && 
                             selectedAccident.timeStamp === accident.timeStamp &&
                             selectedAccident.userId === accident.userId;
          const address = getAddress(accident);
          return (
            <Grid item xs={12} key={accident.timeStamp} sx={styles.gridItem}>
              <Card onClick={() => handleAccidentClick(accident)} sx={styles.card(isSelected)}>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <LocationOnIcon sx={styles.locationIcon(isSelected)} />
                  </Grid>
                  <Grid item xs={11}>
                    <Grid container alignItems="center" spacing={1}>
                      {[
                        { label: '사고 날짜', value: formatDate(accident.timeStamp), xs: 4 },
                        { label: '사고 시간', value: formatTime(accident.timeStamp), xs: 3 },
                        { label: '위치', value: getCityLevelAddress(address), xs: 5 }
                      ].map((item, index) => (
                        <Grid item xs={12} sm={item.xs} key={index}>
                          <Typography variant="body2" sx={styles.typography.label(isSelected)}>
                            {item.label}
                          </Typography>
                          <Typography variant="body2" sx={styles.typography.value(isSelected)}>
                            {item.value}
                          </Typography>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          );
        })}
      </Grid>
      <Box sx={styles.paginationContainer}>
        <TablePagination
          rowsPerPageOptions={isNarrow ? [] : [7, 14, 28]}
          component="div"
          count={accidents.length}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          labelRowsPerPage={isNarrow ? '' : '페이지당 행:'}
          labelDisplayedRows={isNarrow ? () => '' : ({ from, to, count }) => `${from}-${to} / ${count}`}
          sx={styles.pagination(isNarrow)}
        />
      </Box>
    </Box>
  );
};

export default AccidentList;