import React, { useState, useEffect } from 'react';
import { Paper, Box, Button, Snackbar, Container, Chip, Stack, IconButton, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import GenericTable from '../../components/utils/GenericTable';
import GenericDialog from '../../components/utils/GenericDialog';
import SearchBox from '../../components/utils/SearchBox';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

const AdminMembers = () => {
  const { adminId } = useParams();
  const navigate = useNavigate();
  const [members, setMembers] = useState([]);
  const [accidents, setAccidents] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchType, setSearchType] = useState('NAME');
  const [open, setOpen] = useState(false);
  const [currentMember, setCurrentMember] = useState(null);
  const [alertOpen, setAlertOpen] = useState(false);
  const [accidentDialogOpen, setAccidentDialogOpen] = useState(false);
  const [currentAccident, setCurrentAccident] = useState({ member_id: '', location: '', date: '', insurance_company_id: '' });
  const [selectedPendingMembers, setSelectedPendingMembers] = useState([]);
  const [filter, setFilter] = useState('all');
  const [adminName, setAdminName] = useState('');

  useEffect(() => {
    const fetchAdminName = async () => {
      try {
        const response = await axios.get(`http://localhost:3000/api/admins/${adminId}`);
        setAdminName(response.data.username);
      } catch (error) {
        console.error('Admin 데이터 조회 오류:', error);
      }
    };

    const fetchMembersAndAccidents = async () => {
      try {
        const membersResponse = await axios.get(`http://localhost:3000/api/admins/admin/${adminId}/members`);
        setMembers(membersResponse.data);
        const accidentsResponse = await axios.get(`http://localhost:3000/api/accidents`);
        setAccidents(accidentsResponse.data);
      } catch (error) {
        console.error('회원 및 사고 데이터 조회 오류:', error);
      }
    };

    fetchAdminName();
    fetchMembersAndAccidents();
  }, [adminId]);



  const getAccidentCount = (member_id) => {
    return accidents.filter(accident => accident.member_id === member_id).length;
  };

  const getAccidentLabel = (member_id) => {
    const count = getAccidentCount(member_id);
    return count > 0 ? `${count}회` : '없음';
  };



  const handleAccidentDialogClose = () => {
    setAccidentDialogOpen(false);
  };

  const handleAccidentSave = async () => {
    if (!currentAccident.location || !currentAccident.date) {
      setAlertOpen(true);
      return;
    }
    try {
      await axios.post(`http://localhost:3000/api/accidents`, currentAccident);
      setAccidents([...accidents, currentAccident]);
    } catch (error) {
      console.error('사고 데이터 저장 오류:', error);
    }
    setAccidentDialogOpen(false);
  };

  const handleAccidentChange = (prop) => (event) => {
    setCurrentAccident({ ...currentAccident, [prop]: event.target.value });
  };

  const handleViewAccidentDetails = (member) => {
    navigate(`/member-details/${member.member_id}`, { state: { member } });
  };

  const handleClickOpen = (member) => {
    setCurrentMember(member || { NAME: '', phone_number: '', emergency_contact: '', role: '' });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentMember(null);
  };

  const handleSave = async () => {
    if (!currentMember.NAME || !currentMember.phone_number) {
      setAlertOpen(true);
      return;
    }
    try {
      if (currentMember.member_id) {
        await axios.put(`http://localhost:3000/api/members/${currentMember.member_id}`, currentMember);
        setMembers(members.map(m => m.member_id === currentMember.member_id ? currentMember : m));
      } else {
        const response = await axios.post(`http://localhost:3001/api/members`, { ...currentMember, manager_id: adminId });
        setMembers([...members, response.data]);
      }
    } catch (error) {
      console.error('회원 데이터 저장 오류:', error);
    }
    setOpen(false);
    setCurrentMember(null);
  };

  const handleChange = (prop) => (event) => {
    setCurrentMember({ ...currentMember, [prop]: event.target.value });
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this member?")) {
      try {
        await axios.delete(`http://localhost:3000/api/members/${id}`);
        setMembers(members.filter(m => m.member_id !== id));
      } catch (error) {
        console.error('회원 데이터 삭제 오류:', error);
      }
    }
  };

  const handleApprove = async (id) => {
    try {
      await axios.put(`http://localhost:3000/api/members/${id}/approve`);
      setMembers(members.map(m => m.member_id === id ? { ...m, approved: true } : m));
    } catch (error) {
      console.error('회원 승인 오류:', error);
    }
    setAlertOpen(true);
  };

  const handleRestore = async (id) => {
    try {
      await axios.put(`http://localhost:3000/api/members/${id}/restore`);
      setMembers(members.map(m => m.member_id === id ? { ...m, is_deleted: false } : m));
    } catch (error) {
      console.error('회원 복구 오류:', error);
    }
  };

  const handleCheckboxChange = (event, memberId) => {
    if (event.target.checked) {
      setSelectedPendingMembers([...selectedPendingMembers, memberId]);
    } else {
      setSelectedPendingMembers(selectedPendingMembers.filter(id => id !== memberId));
    }
  };

  const handleApproveSelected = () => {
    selectedPendingMembers.forEach(id => handleApprove(id));
    setSelectedPendingMembers([]);
  };

  const visibleMembers = members;

  const filteredMembers = filter === 'all'
    ? visibleMembers
    : filter === 'approved'
      ? visibleMembers.filter(member => member.approved && !member.is_deleted)
      : filter === 'pending'
        ? visibleMembers.filter(member => !member.approved && !member.is_deleted)
        : filter === 'deleted'
          ? visibleMembers.filter(member => member.is_deleted)
          : visibleMembers;

  const searchedMembers = filteredMembers.filter(member =>
    member[searchType] && member[searchType].toString().toLowerCase().includes(searchTerm)
  );

  const searchTypes = [
    { value: 'NAME', label: 'Name' },
    { value: 'member_id', label: 'ID' },
    { value: 'phone_number', label: 'Phone' },
    { value: 'emergency_contact', label: 'Emergency Contact' }
  ];

  return (
    <Container maxWidth="xl" sx={{ mt: 4 }}>

     <Paper
        sx={{
          p: 4,
          boxShadow: 3,
          borderRadius: 5,
          height: '90vh',
          overflow: 'auto',
          '&::-webkit-scrollbar': {
            width: '8px',
            height: '8px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888',
            borderRadius: '4px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#555',
          },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" sx={{ ml: 1 }}>
            {adminName} 관리 회원 목록
            </Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
          <SearchBox
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            searchType={searchType}
            setSearchType={setSearchType}
            searchTypes={searchTypes}
          />
          <Button variant="contained" color="primary" onClick={() => handleClickOpen()}>Add Member</Button>
        </Box>
        <Stack direction="row" spacing={1} sx={{ marginBottom: 2 }}>
          <Chip label="All" onClick={() => setFilter('all')} color={filter === 'all' ? 'primary' : 'default'} />
          <Chip label="회원" onClick={() => setFilter('approved')} color={filter === 'approved' ? 'primary' : 'default'} />
          <Chip label="승인대기" onClick={() => setFilter('pending')} color={filter === 'pending' ? 'primary' : 'default'} />
          <Chip label="탈퇴회원" onClick={() => setFilter('deleted')} color={filter === 'deleted' ? 'primary' : 'default'} />
        </Stack>
        {filter === 'pending' && (
          <Button
            variant="contained"
            color="secondary"
            onClick={handleApproveSelected}
            disabled={selectedPendingMembers.length === 0}
            sx={{ mb: 2 }}
          >
            승인
          </Button>
        )}
        <GenericTable
          columns={[
            { id: 'NAME', label: 'Name' },
            { id: 'phone_number', label: 'Phone' },
            { id: 'member_id', label: 'ID' },
            { id: 'emergency_contact', label: 'Emergency Contact' },
            {
              id: 'accident',
              label: 'Accident',
              render: (member) => (
                <Button
                  variant="outlined"
                  onClick={() => handleViewAccidentDetails(member)}
                  style={{
                    backgroundColor: getAccidentCount(member.member_id) > 0 ? '#F28080' : '#6A95A6',
                    color: 'white'
                  }}
                >
                  {getAccidentLabel(member.member_id)}
                </Button>
              )
            },
            {
              id: 'is_deleted',
              label: 'Status',
              render: (member) => (
                member.is_deleted ? (
                  <>
                    탈퇴
                    <Button variant="text" onClick={() => handleRestore(member.member_id)}>복구</Button>
                  </>
                ) : (
                  !member.approved ? '승인대기' : '활동중'
                )
              )
            },
          ]}
          data={searchedMembers}
          onEdit={handleClickOpen}
          onDelete={handleDelete}
          showCheckboxes={filter === 'pending'}
          onCheckboxChange={handleCheckboxChange}
        />
        {currentMember && (
          <GenericDialog
            open={open}
            data={currentMember}
            onClose={handleClose}
            onSave={handleSave}
            onChange={handleChange}
            fields={[
              { id: 'NAME', label: 'Name', autoFocus: true },
              { id: 'phone_number', label: 'Phone' },
              { id: 'emergency_contact', label: 'Emergency Contact' }
            ]}
            title="Member"
          />
        )}
        <GenericDialog
          open={accidentDialogOpen}
          data={currentAccident}
          onClose={handleAccidentDialogClose}
          onSave={handleAccidentSave}
          onChange={handleAccidentChange}
          title="Accident"
          fields={[
            { id: 'location', label: 'Location', autoFocus: true },
            { id: 'date', label: 'Date', type: 'date' },
            { id: 'insurance_company_id', label: 'Insurance Company' }
          ]}
        />
      </Paper>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={() => setAlertOpen(false)}
        message="Please fill in all required fields"
      />
    </Container>
  );
};

export default AdminMembers;
