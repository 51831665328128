import React, { useState, useEffect } from 'react';
import {
  Box, Typography, Dialog, DialogTitle, DialogContent, TextField, List, ListItem, Checkbox, IconButton
} from '@mui/material';
import { ref, get, set, update} from 'firebase/database';
import CloseIcon from '@mui/icons-material/Close';
import { database } from '../../firebase'; // Adjust the path to your Firebase config
import CustomAddButton from '../utils/CustomAddButton';
import CustomButton from '../utils/CustomButton';
import useWindowDimensions from '../../hook/useWindowDimensions';

const IpManagementModal = ({ open, onClose }) => {
  const [ipList, setIpList] = useState({});
  const [newIp, setNewIp] = useState('');
  const [selectedIps, setSelectedIps] = useState(new Set());
  const { isNarrow } = useWindowDimensions();


  useEffect(() => {
    if (open) {
      fetchIps();
    }
  }, [open]);

  const fetchIps = async () => {
    const dbRef = ref(database, 'policy/accessIP');
    const snapshot = await get(dbRef);
    if (snapshot.exists()) {
      setIpList(snapshot.val());
    }
  };

  const handleAddIp = async () => {
    if (newIp) {
      const newIndex = String(Object.keys(ipList).length);
      const dbRef = ref(database, `policy/accessIP/${newIndex}`);
      await set(dbRef, newIp);
      
      setIpList(prevList => ({ ...prevList, [newIndex]: newIp }));
      setNewIp('');
    }
  };

  const handleDeleteIps = async () => {
    const dbRef = ref(database, 'policy/accessIP');
    const updates = {};

    selectedIps.forEach((key) => {
      updates[key] = null;
    });

    await update(dbRef, updates);

    const updatedIpList = { ...ipList };
    selectedIps.forEach(key => delete updatedIpList[key]);
    setIpList(updatedIpList);
    setSelectedIps(new Set());
  };

  const handleCheckboxToggle = (key) => {
    const newSelectedIps = new Set(selectedIps);
    if (newSelectedIps.has(key)) {
      newSelectedIps.delete(key);
    } else {
      newSelectedIps.add(key);
    }
    setSelectedIps(newSelectedIps);
  };

  const handleIpChange = (key, newIpValue) => {
    setIpList(prevList => ({
      ...prevList,
      [key]: newIpValue
    }));
    update(ref(database, `policy/accessIP`), { [key]: newIpValue });
  };


  return (
    <Dialog 
      open={open} 
      onClose={onClose} 
      PaperProps={{ 
        sx: { 
          borderRadius: 'clamp(8px, 1.48vh, 16px)',
          padding: 'clamp(10px, 1.85vh, 20px)',
          width: isNarrow ? '90vw' : 'clamp(300px, 30vw, 500px)',
          height: isNarrow ? 'auto' : 'clamp(400px, 55vh, 600px)',
          maxWidth: 'none',
          maxHeight: isNarrow ? '90vh' : 'none'
        } 
      }}
    >
      <DialogTitle sx={{ padding: '0 0 clamp(10px, 1.85vh, 20px) 0' }}>
        <Typography variant="h6" sx={{ fontWeight: 'bold', fontSize: 'clamp(18px, 2.22vh, 24px)' }}>
          IP 관리
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 'clamp(4px, 0.74vh, 8px)',
            top: 'clamp(4px, 0.74vh, 8px)',
            color: (theme) => theme.palette.grey[500],
            '& .MuiSvgIcon-root': { 
              fontSize: 'clamp(18px, 2.4vh, 24px)'
            }
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ padding: 'clamp(12px, 2.22vh, 24px) 0' }}>
        <Box display="flex" alignItems="center" sx={{ gap: 'clamp(5px, 1vw, 10px)', margin: 'clamp(10px, 2vh, 20px) 0' }}>
          <TextField
            label="IP 입력"
            value={newIp}
            onChange={(e) => setNewIp(e.target.value)}
            sx={{ 
              flex: '0 1 65%',
              height: 'clamp(36px, 5vh, 48px)',
              '& .MuiInputBase-root': { 
                height: '100%', 
                boxSizing: 'border-box',
                fontSize: 'clamp(14px, 1.48vh, 16px)'
              },
              '& .MuiInputLabel-root': {
                fontSize: 'clamp(14px, 1.48vh, 16px)'
              }
            }}
          />
          <CustomAddButton 
            onClick={handleAddIp}
            sx={{ 
              flex: '0 1 25%',
              height: 'clamp(36px, 5vh, 48px)',
              fontSize: 'clamp(14px, 1.48vh, 16px)',
              padding: '0 clamp(10px, 1.85vh, 20px)',
              minWidth: 'clamp(80px, 8vw, 120px)',
            }}
          >
            추가
          </CustomAddButton>
        </Box>
        <Box sx={{ 
          height: 'clamp(200px, 27.78vh, 300px)',
          overflowY: 'auto', 
          '&::-webkit-scrollbar': { width: 'clamp(6px, 0.74vh, 8px)' },
          '&::-webkit-scrollbar-thumb': { backgroundColor: '#888', borderRadius: 'clamp(2px, 0.37vh, 4px)' },
          '&::-webkit-scrollbar-track': { backgroundColor: '#f1f1f1' } 
        }}>
          <List>
            {Object.keys(ipList).map((key) => (
              <ListItem key={key} sx={{ padding: 'clamp(5px, 0.93vh, 10px) 0' }}>
                <Checkbox
                  checked={selectedIps.has(key)}
                  onChange={() => handleCheckboxToggle(key)}
                  edge="start"
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 'clamp(18px, 2.22vh, 24px)' } }}
                />
                <TextField
                  value={ipList[key]}
                  onChange={(e) => handleIpChange(key, e.target.value)}
                  fullWidth
                  sx={{ 
                    '& .MuiInputBase-root': { 
                      height: 'clamp(36px, 5vh, 48px)',
                      fontSize: 'clamp(14px, 1.48vh, 16px)'
                    } 
                  }}
                />
              </ListItem>
            ))}
          </List>
        </Box>
        <Box display="flex" justifyContent="flex-end" mt={2}>
          <CustomButton 
            onClick={handleDeleteIps} 
            variant="contained"
            sx={{ 
              height: 'clamp(32px, 3.89vh, 42px)',
              fontSize: 'clamp(14px, 1.48vh, 16px)',
              padding: '0 clamp(10px, 1.85vh, 20px)'
            }}
          >
            삭제
          </CustomButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default IpManagementModal;