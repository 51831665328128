import React from 'react';
import { Paper, Box, Typography } from '@mui/material';

const styles = {
  paper: {
    height: '100%',
    overflow: 'auto',
    padding: 'clamp(10px, 2vw, 16px)',
    backgroundColor: '#FFFFFF',
  },
  answerBox: {
    borderTop: '2px solid #E5E7EB',
    paddingTop: 'clamp(10px, 2vw, 16px)',
    marginTop: 'clamp(10px, 2vw, 16px)',
  },
  answerTitle: {
    fontWeight: '600',
    fontSize: 'clamp(18px, 3vw, 24px)',
    marginBottom: 'clamp(5px, 1vw, 10px)',
    color: '#141414',
  },
  answerBody: {
    marginTop: 'clamp(5px, 1vw, 10px)',
    fontSize: 'clamp(14px, 2.5vw, 18px)',
    fontWeight: '500',
    color: '#141414',
  },
  timeStamp: {
    color: '#6B7280',
    marginTop: 'clamp(5px, 1vw, 10px)',
    fontSize: 'clamp(12px, 2vw, 16px)',
  },
};

const AnswerDetails = ({ answerBody, answerTimeStamp }) => {
  return (
    <Paper elevation={0} sx={styles.paper}>
      <Box sx={styles.answerBox}>
        <Typography sx={styles.answerTitle}>답변</Typography>
        <Typography variant="body1" sx={styles.answerBody}>
          {answerBody.split('<br>').map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </Typography>
        <Typography variant="body1" sx={styles.timeStamp}>
          {answerTimeStamp}
        </Typography>
      </Box>
    </Paper>
  );
};

export default AnswerDetails;