import React from 'react';
import { Box, Typography, InputLabel } from '@mui/material';
import { Clear as ClearIcon } from '@mui/icons-material';
import { useSendNoti } from '../../context/SendNotiContext';
import Filedown from '../../styles/icons/alert/Filedown.png';

const FileUploader = () => {
  const { files, setFiles } = useSendNoti();

  const handleFileChange = (e) => {
    setFiles([...files, ...Array.from(e.target.files)]);
  };

  const handleRemoveFile = (index) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };

  const handleRemoveAllFiles = () => {
    setFiles([]);
  };

  return (
    <Box sx={{ position: 'relative', mt: '1vh' }}>
      {files.length === 0 && (
        <InputLabel 
          htmlFor="file-input" 
          sx={{ 
            position: 'absolute', 
            top: '-1.11vh', 
            left: '0.93vw', 
            backgroundColor: 'white', 
            padding: '0 0.46vw',
            fontSize: '1.39vh',
            color: 'rgba(0, 0, 0, 0.6)',
          }}
        >
          파일 첨부
        </InputLabel>
      )}
      <Box sx={{ border: '0.09vh solid #ccc', borderRadius: '0.46vh', textAlign: 'center' }}>
        {files.length > 0 ? (
          <Box>
            <Box 
              sx={{
                backgroundColor: '#F3F4F6',
                borderTopLeftRadius: '0.93vh',
                borderTopRightRadius: '0.93vh',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0.46vh 0.93vw',
                color:'#9CA3AF',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <ClearIcon
                  fontSize="small"
                  onClick={handleRemoveAllFiles}
                  sx={{ cursor: 'pointer', marginRight: '0.93vw' }}
                />
                <Typography variant="subtitle1" sx={{ fontSize: '1.48vh' }}>파일명</Typography>
              </Box>
              <Typography variant="subtitle1" sx={{ fontSize: '1.48vh' }}>용량</Typography>
            </Box>
            {files.map((file, index) => (
              <Box key={index} sx={{ padding: '0.19vh 0.93vw', display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: '0.93vh' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <ClearIcon fontSize="small" onClick={() => handleRemoveFile(index)} sx={{ cursor: 'pointer', mr: '0.93vw', color:'#9CA3AF', }} />
                  <Typography variant="body2" sx={{ fontSize: '1.39vh' }}>{file.name}</Typography>
                </Box>
                <Typography variant="body2" sx={{ ml: '1.85vw', fontSize: '1.39vh' }}>{(file.size / 1024).toFixed(2)} KB</Typography>
              </Box>
            ))}
          </Box>
        ) : (
          <Box
            component="label"
            htmlFor="file-input"
            sx={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              justifyContent: 'center',
              height: '8.33vh', // 90px를 vh로 변환
            }}
          >
            <img src={Filedown} alt="File Download" style={{ width: '1.3vw', height: '1.8vh', marginRight: '0.74vw' }} />
            <Typography variant="subtitle1" sx={{ color: '#9CA3AF', display: 'flex', alignItems: 'center', fontSize: '1.48vh' }}>
              파일 첨부하기
            </Typography>
          </Box>
        )}
        <input
          accept="*"
          style={{ display: 'none' }}
          id="file-input"
          type="file"
          multiple
          onChange={handleFileChange}
        />
      </Box>
    </Box>
  );
};

export default FileUploader;