const formatAccidentType = (type) => {
  // type이 undefined 또는 null인지 확인
  if (!type) {
    return '알 수 없음';
  }

  // type이 문자열인지 확인한 후 includes 호출
  if (typeof type === 'string' && (type.includes('충돌 감지') || type.includes('Collision detection') || type.includes('collision detection') )) {
    return '차량 충돌 감지';
  }

  // 공백 제거 및 '급' 접두어 처리
  let formattedType = type.replace(/\s+/g, '');

  if (formattedType.startsWith('급')) {
    formattedType = formattedType.replace(/급/, '급 ');
  } else {
    formattedType = '급 ' + formattedType;
  }

  return formattedType;
};

export default formatAccidentType;