import React, { useState, useEffect } from 'react';
import { Grid, Card, Typography, Box, Checkbox, TablePagination } from '@mui/material';
import useGeocodedAddresses from '../../hook/useGeocodedAddresses';
import useWindowDimensions from '../../hook/useWindowDimensions';

const styles = {
  container: (isNarrow) => ({
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
  }),
  gridContainer: {
    padding: 'clamp(15px, 0.9vw, 0.9vw)', // 스타일을 일관성 있게 유지
  },
  gridItem: {
    marginBottom: '0.1vw',
  },
  card: (isSelected) => ({
    boxShadow: 'none',
    cursor: 'pointer',
    backgroundColor: isSelected ? '#2563EB' : '#F3F4F6',
    color: isSelected ? '#FFFFFF' : '#000000',
    borderRadius: 'clamp(6px, 0.52vw, 0.52vw)',
    padding: 'clamp(15px, 0.9vw, 0.9vw)', // 동일한 패딩 설정
  }),
  checkbox: (isSelected) => ({
    padding: 0,  // 패딩 제거
    marginRight: 'clamp(1px, 0.2vw, 0.2vw)',  // 오른쪽 마진 감소
    marginLeft: 'clamp(-8px, -0.5vw, -0.5vw)',  // 왼쪽으로 이동
    '& .MuiSvgIcon-root': { 
      color: isSelected ? '#FFFFFF' : '#6B7280',
      width: 'clamp(16px, 1.04vw, 1.04vw)',
      height: 'clamp(16px, 1.04vw, 1.04vw)',
    },
    '&.Mui-checked': { color: '#2563EB' }
  }),
  typography: {
    label: (isSelected) => ({
      fontSize: 'clamp(10px, 0.73vw, 0.73vw)',
      color: isSelected ? '#FFFFFF' : '#6B7280',
      fontWeight: 'bold',
    }),
    value: (isSelected) => ({
      fontSize: 'clamp(12px, 0.73vw, 0.73vw)',
      fontWeight: 'bolder',
      color: isSelected ? '#FFFFFF' : '#000000',
    }),
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    overflowX: 'hidden',
    marginTop: 'clamp(0, 0.2vw, 0.2vw)',
    paddingRight: 'clamp(15px, 0.9vw, 0.9vw)',
  },
  pagination: (isNarrow) => ({
    '& .MuiTablePagination-toolbar': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    '& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows': {
      fontSize: 'clamp(5px, 0.73vw, 0.73vw)',
      display: isNarrow ? 'none' : 'block',
    },
    '& .MuiTablePagination-select': {
      fontSize: 'clamp(5px, 0.73vw, 0.73vw)',
      marginRight: 'clamp(4px, 0.3vw, 0.3vw)',
      display: isNarrow ? 'none' : 'block',
    },
    '& .MuiTablePagination-menuItem': {
      fontSize: 'clamp(10px, 0.73vw, 0.73vw)',
    },
    '& .MuiTablePagination-actions': {
      marginLeft: isNarrow ? 0 : 'clamp(4px, 0.3vw, 0.3vw)',
    },
    '& .MuiIconButton-root': {
      padding: 'clamp(4px, 0.3vw, 0.3vw)',
    },
  }),
};

const getCityLevelAddress = (address) => {
  if (!address) return '위치 정보 없음';
  const parts = address.split(' ');
  const districtIndex = parts.findIndex(part => part.endsWith('구'));
  if (districtIndex !== -1) return parts.slice(districtIndex).join(' ');
  const cityIndex = parts.findIndex(part => part.endsWith('시'));
  if (cityIndex !== -1) return parts.slice(cityIndex).join(' ');
  return address;
};

const AccidentListCheck = ({ accidents, handleAccidentClick, selectedAccident, selectedAccidents, setSelectedAccidents }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(7);
  const { getAddress } = useGeocodedAddresses(accidents);
  const { isNarrow } = useWindowDimensions();


  const handleCheckboxChange = (event, accident) => {
    event.stopPropagation();
    setSelectedAccidents(prevSelected => 
      prevSelected.includes(accident.timeStamp)
        ? prevSelected.filter(a => a !== accident.timeStamp)
        : [...prevSelected, accident.timeStamp]
    );
  };

  const sortedAccidents = accidents.sort((a, b) => new Date(b.timeStamp) - new Date(a.timeStamp));
  const currentAccidents = sortedAccidents.slice(currentPage * rowsPerPage, (currentPage + 1) * rowsPerPage);

  const handlePageChange = (event, newPage) => setCurrentPage(newPage);
  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  return (
    <Box sx={styles.container(isNarrow)}>
      <Grid container spacing={2} sx={styles.gridContainer}>
        {currentAccidents.map((accident) => {
          const isSelected = selectedAccident && selectedAccident.timeStamp === accident.timeStamp;
          const address = getAddress(accident);
          return (
            <Grid item xs={12} key={accident.timeStamp} sx={styles.gridItem}>
              <Card onClick={() => handleAccidentClick(accident)} sx={styles.card(isSelected)}>
                <Grid container alignItems="center" wrap="nowrap">
                  <Grid item xs={1}>
                    <Checkbox
                      checked={selectedAccidents.includes(accident.timeStamp)}
                      onChange={(event) => handleCheckboxChange(event, accident)}
                      sx={styles.checkbox(isSelected)}
                      onClick={(event) => event.stopPropagation()}
                    />
                  </Grid>
                  <Grid item xs={11}>
                    <Grid container alignItems="center" spacing={1}>
                      {[
                        { label: '사고 날짜', value: accident.timeStamp.split(' ')[0].replace(/-/g, '.'), xs: 4 },
                        { label: '사고 시간', value: accident.timeStamp.split(' ')[1], xs: 3 },
                        { label: '위치', value: getCityLevelAddress(address), xs: 5 }
                      ].map((item, index) => (
                        <Grid item xs={12} sm={item.xs} key={index}>
                          <Typography variant="body2" sx={styles.typography.label(isSelected)}>
                            {item.label}
                          </Typography>
                          <Typography variant="body2" sx={styles.typography.value(isSelected)}>
                            {item.value}
                          </Typography>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          );
        })}
      </Grid>
      <Box sx={styles.paginationContainer}>
        <TablePagination
          rowsPerPageOptions={isNarrow ? [] : [7, 14, 28]}
          component="div"
          count={accidents.length}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          labelRowsPerPage={isNarrow ? '' : '페이지당 행:'}
          labelDisplayedRows={isNarrow ? () => '' : ({ from, to, count }) => `${from}-${to} / ${count}`}
          sx={styles.pagination(isNarrow)}
        />
      </Box>
    </Box>
  );
};

export default AccidentListCheck;
