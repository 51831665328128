import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, IconButton, Drawer, List, ListItem, ListItemIcon, ListItemText, Typography, Box, Divider, CssBaseline, Badge, Backdrop } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Bell from '../../styles/icons/dashboard/Bell.png';
import AccountCircleRoundedsmall from '../../styles/icons/dashboard/AccountCircleRoundedsmall.png';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import CarCrashOutlinedIcon from '@mui/icons-material/CarCrashOutlined';
import AnnouncementOutlinedIcon from '@mui/icons-material/AnnouncementOutlined';
import AppSettingsAltOutlinedIcon from '@mui/icons-material/AppSettingsAltOutlined';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import HeadsetMicOutlinedIcon from '@mui/icons-material/HeadsetMicOutlined';
import ForwardToInboxOutlinedIcon from '@mui/icons-material/ForwardToInboxOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { useNavigate, Link as RouterLink, useLocation } from 'react-router-dom';
import { useAuth } from '../../context/AuthProvider';
import { getDatabase, ref, onValue, off } from 'firebase/database';
import CartextImage from '../../styles/Logo/LogoGroup.png';
import Text from '../../styles/Logo/Text.png';
import GroupIcon from '../../styles/icons/dashboard/Group.png';
import GroupHoverIcon from '../../styles/icons/dashboard/Grouphover.png';
import CloserArrow from '../../styles/icons/dashboard/Closedarrow.png';

const drawerWidth = 'clamp(200px, 16.77vw, 100%)';


const styles = {
  drawerPaper: {
    width: drawerWidth,
    minWidth: drawerWidth, // Ensure drawer width does not go below 16.77vw
    height: '100vh',
    boxSizing: 'border-box',
    border: 'none',
    overflow: 'hidden',
    position: 'relative',
    
  },
  menuItemIcon: {
    color: '#6B7280',
    width: 'clamp(16px, 1.25vw, 1.25vw)',
    height: 'clamp(16px, 1.25vw, 1.25vw)',
  },
  selectedMenuItemIcon: {
    color: '#2563EB',
  },
  userIcon: {
    width: 'clamp(30px, 2.76vw, 2.76vw)',
    height: 'clamp(30px, 2.76vw, 2.76vw)',
    cursor: 'pointer',
  },
  appBar: {
    backgroundColor: 'transparent',
    color: 'black',
    boxShadow: 'none',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 'clamp(8px, 1vh, 1vh)',
    paddingLeft: 'clamp(5px, 0.52vw, 0.52vw)',
    paddingRight: 'clamp(5px, 0.52vw, 0.52vw)',
  },
  drawerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 'clamp(15px, 1.85vh, 1.85vh)',
  },
  logoButton: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
    paddingLeft: 'clamp(20px, 2.08vw, 2.08vw)',
  },
  toggleButton: {
    border: '1px solid #f3f3f3',
    borderRight: 'none',
    borderRadius: '5%',
    width: 'clamp(20px, 1.35vw, 1.35vw)',
    height: 'clamp(20px, 1.35vw, 1.35vw)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 'clamp(5px, 0.74vh, 0.74vh)',
  },
  userName: {
    fontSize: 'clamp(12px, 0.94vw, 0.94vw)',
    fontWeight: '600',
    lineHeight: 'clamp(16px, 1.94vh, 1.94vh)',
    color: '#141414',
  },
  userRole: {
    fontSize: 'clamp(10px, 0.63vw, 0.63vw)',
    lineHeight: 'clamp(16px, 1.94vh, 1.94vh)',
    color: '#737373',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginTop: '1.48vh', // 16px / 1080px * 100
  },
  logoutContainer: {
    position: 'absolute',
    bottom: 'clamp(50px, 7.41vh, 7.41vh)',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  logoutLine: {
    position: 'absolute',
    width: '100%',
    height: 'clamp(1px, 0.19vh, 0.19vh)',
    bottom: 'clamp(30px, 4.63vh, 4.63vh)',
    display: 'flex',
    backgroundColor: '#F3F4F6',
    marginTop: 'clamp(10px, 1.48vh, 1.48vh)',
  },
  userDetailsContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    paddingLeft: 'clamp(20px, 2.08vw, 2.08vw)',
    marginTop: 'clamp(30px, 4.44vh, 4.44vh)',
  },
  horizontalLine: {
    width: '100%',
    height: 'clamp(1px, 0.19vh, 0.19vh)',
    backgroundColor: '#F3F4F6',
    marginTop: 'clamp(3px, 0.46vh, 0.46vh)',
  },
  menuTitle: {
    paddingLeft: 'clamp(20px, 2.08vw, 2.08vw)',
    marginTop: 'clamp(30px, 3.98vh, 3.98vh)',
    marginBottom: 'clamp(7px, 0.93vh, 0.93vh)',
    fontFamily: 'Pretendard',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 'clamp(10px, 0.73vw, 0.73vw)',
    letterSpacing: '0.08em',
    color: '#9CA3AF',
  },
  menuItemText: {
    fontWeight: 'bold',
    color: '#141414',
    fontSize: 'clamp(12px, 0.9vw, 0.9vw)',
  },
  selectedMenuItemText: {
    color: '#2563EB',
  },
  logoutText: {
    fontWeight: 700,
    fontSize: 'clamp(12px, 0.9vw, 0.9vw)',
    color: '#9CA3AF',
  },
  rightBorderLine: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    width: 'clamp(1px, 0.1vw, 0.1vw)',
    backgroundColor: '#F3F4F6',
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1200,
    transition: 'opacity 0.3s',
  },
  drawerPaper: {
    width: drawerWidth,
    height: '100%',
    position: 'fixed',
    zIndex: 1300,
    transition: 'transform 0.3s ease-in-out',
  },
};

const Dashboard = () => {
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const [notificationCount, setNotificationCount] = useState(0);
  const [iconSrc, setIconSrc] = useState(GroupIcon);

  useEffect(() => {
    const handleResize = () => {
      const newIsMobile = window.innerWidth <= window.screen.width * 0.8;
      setIsMobile(newIsMobile);
      if (newIsMobile) {
        setDrawerOpen(false);
      } else {
        setDrawerOpen(true);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call once to set initial state
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleUserIconClick = () => {
    navigate('/admin-info');
  };

  const fetchNotificationCount = () => {
    const db = getDatabase();
    const notificationsRef = ref(db, 'adminNoti');
    onValue(notificationsRef, (snapshot) => {
      let count = 0;
      snapshot.forEach((childSnapshot) => {
        childSnapshot.forEach((typeSnapshot) => {
          typeSnapshot.forEach((notiSnapshot) => {
            if (!notiSnapshot.val().read) {
              count += 1;
            }
          });
        });
      });
      setNotificationCount(count);
    });
  };

  useEffect(() => {
    fetchNotificationCount();
  }, []);

  useEffect(() => {
    return () => {
      const db = getDatabase();
      const notificationsRef = ref(db, 'adminNoti');
      off(notificationsRef);
    };
  }, []);

  useEffect(() => {
    setIconSrc(location.pathname === '/insurance' ? GroupHoverIcon : GroupIcon);
  }, [location.pathname]);

  const iconSize = 'clamp(16px, 1.25vw,  1.25vw)';

  const menuItems = [
    { 
      text: '관리자 목록', 
      icon: <ManageAccountsOutlinedIcon sx={{ width: iconSize, height: iconSize }} />, 
      path: '/admins', 
      allowedRoles: [] 
    },
    { 
      text: '회원 목록', 
      icon: <PeopleOutlinedIcon sx={{ width: iconSize, height: iconSize }} />, 
      path: '/all-members', 
      allowedRoles: ['admin', 'specialUser', 'viewer'] 
    },
    { 
      text: '사고 목록', 
      icon: <FormatListBulletedOutlinedIcon sx={{ width: iconSize, height: iconSize }} />, 
      path: '/incidents', 
      allowedRoles: ['admin', 'specialUser', 'viewer'] 
    },
    { 
      text: '사고 보고서 요청', 
      icon: <CarCrashOutlinedIcon sx={{ width: iconSize, height: iconSize }} />, 
      path: '/request', 
      allowedRoles: ['admin', 'specialUser', 'viewer'] 
    },
    { 
      text: '보험사', 
      icon: <Box component="img" src={iconSrc} alt="보험사" sx={{ width: iconSize, height: iconSize }} />, 
      path: '/insurance', 
      allowedRoles: ['admin', 'specialUser', 'viewer'] 
    },
    { 
      text: '공지사항', 
      icon: <AnnouncementOutlinedIcon sx={{ width: iconSize, height: iconSize }} />, 
      path: '/notice', 
      allowedRoles: [] 
    },
    { 
      text: '앱 관리', 
      icon: <AppSettingsAltOutlinedIcon sx={{ width: iconSize, height: iconSize }} />, 
      path: '/app-management', 
      allowedRoles: ['admin', 'specialUser', 'viewer'] 
    },
    { 
      text: '알림 보내기', 
      icon: <CampaignOutlinedIcon sx={{ width: iconSize, height: iconSize }} />, 
      path: '/send-notification', 
      allowedRoles: ['admin', 'specialUser', 'viewer'] 
    },
    { 
      text: '고객센터', 
      icon: <HeadsetMicOutlinedIcon sx={{ width: iconSize, height: iconSize }} />, 
      path: '/customer-service', 
      allowedRoles: ['admin', 'specialUser', 'viewer'] 
    },
    { 
      text: '문자 보내기', 
      icon: <ForwardToInboxOutlinedIcon sx={{ width: iconSize, height: iconSize }} />, 
      path: '/send-message', 
      allowedRoles: [''] 
    },
  ];

  const getRoleFromUsername = (username) => {
    switch (username) {
      case 'mainAdmin':
        return 'admin';
      case 'myren03':
        return 'specialUser';
      case 'see':
        return 'viewer';
      default:
        return '';
    }
  };
  const userRole = currentUser?.role || getRoleFromUsername(currentUser?.username);


  return (
    <Box sx={{ display: 'flex', overflow: 'hidden' }}>
      <CssBaseline />
      <AppBar position="fixed" sx={styles.appBar}>
        <Toolbar sx={{ justifyContent: 'space-between', width: '100%' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer}
            >
              <MenuIcon />
            </IconButton>
            <IconButton onClick={() => navigate('/main')}>
              <Box component="img" src={Text} alt="Home" sx={{ width: 'auto', height: 'auto' }} />
            </IconButton>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton color="inherit" onClick={() => navigate('/notifications')}>
            <Badge 
              badgeContent={notificationCount} 
              sx={{ 
                '& .MuiBadge-badge': { 
                  backgroundColor: 'red', 
                  color: 'white', 
                  width: 'clamp(14px, 1vw, 18px)', 
                  height: 'clamp(12px, 0.94vw, 18px)',
                  fontSize: 'clamp(10px, 0.73vw, 14px)'
                } 
              }}
            >
              <Box 
                component="img" 
                src={Bell} 
                alt="알림" 
                sx={{ 
                  width: 'clamp(20px, 1.25vw, 24px)', 
                  height: 'clamp(20px, 1.25vw, 24px)' 
                }} 
              />
            </Badge>
          </IconButton>
          <IconButton color="inherit" onClick={handleUserIconClick}>
            <Box 
              component="img" 
              src={AccountCircleRoundedsmall} 
              alt="사용자 아이콘" 
              sx={{ 
                width: 'clamp(28px, 1.77vw, 34px)', 
                height: 'clamp(28px, 1.77vw, 34px)' 
              }} 
            />
          </IconButton>
        </Box>
        </Toolbar>
      </AppBar>

      {/* Backdrop for overlay effect when drawer is open */}
      <Backdrop open={drawerOpen && isMobile} onClick={toggleDrawer} sx={styles.overlay} />

      <Drawer
        variant={isMobile ? 'temporary' : 'persistent'}
        anchor="left"
        open={drawerOpen}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': styles.drawerPaper,
          ...(isMobile && { position: 'fixed' }), // Fixed position for mobile view
        }}
        onClose={toggleDrawer}
      >
        <Box sx={styles.drawerContainer} role="presentation">
          <Box sx={styles.logoButton}>
            <IconButton onClick={() => navigate('/main')}>
              <Box sx={{ width: 'auto', height: 'auto', display: 'flex', alignItems: 'center' }}>
                <Box component="img" src={CartextImage} alt="Home" sx={{ width: '100%', height: '100%' }} />
              </Box>
            </IconButton>
            {drawerOpen && (
              <IconButton sx={styles.toggleButton} onClick={toggleDrawer}>
            <Box 
                component="img" 
                src={CloserArrow} 
                alt="Close Drawer" 
                sx={{ 
                  width: 'clamp(14px, 0.8vw, 0.8vw)', 
                  height: 'clamp(12px, 1vh, 1vh)', 
                  marginTop: 'clamp(1px, 0.19vh, 0.19vh)' 
                }} 
              />
              </IconButton>
            )}
          </Box>
          <Divider />
          <Box sx={styles.horizontalLine} />
          <Box sx={styles.userDetailsContainer}>
            <Box component="img" src={AccountCircleRoundedsmall} alt="사용자 아이콘" sx={styles.userIcon} onClick={handleUserIconClick} />
            <Box sx={{ marginLeft: '0.83vw', marginTop: '0.46vh' }}>
              <Typography sx={styles.userName}>
                {currentUser.username || `User ${currentUser.manager_id}`}
              </Typography>
              <Typography color="textSecondary" sx={styles.userRole}>
                MYREN Data Server Admin
              </Typography>
            </Box>
          </Box>
        </Box>
        <Typography color="textSecondary" sx={styles.menuTitle}>
          MENU
        </Typography>
        <List>
          {menuItems.map((item) =>
            userRole && item.allowedRoles.includes(userRole) ? (
              <ListItem
                key={item.text}
                component={RouterLink}
                to={item.path}
                sx={{
                  paddingLeft: '2.08vw', // 40px / 1920px * 100
                  height: '4.44vh', // 48px / 1080px * 100
                  color: location.pathname === item.path ? '#2563EB' : '#141414',
                  position: 'relative',
                  '&:hover': {
                    color: '#2563EB',
                  },
                  '&::before': {
                    content: '""',
                    position: 'absolute',
                    left: '1.56vw', // 30px / 1920px * 100
                    right: '1.56vw',
                    height: '100%',
                    backgroundColor: 'rgba(78, 158, 249, 0.14)',
                    borderRadius: '5px',
                    zIndex: -1,
                    opacity: 0,
                    transition: 'opacity 0.3s',
                  },
                  '&:hover::before': {
                    opacity: 1,
                  },
                  ...(location.pathname === item.path && {
                    '& .background-box': {
                      backgroundColor: 'rgba(78, 158, 249, 0.14)',
                      left: '1.56vw',
                      right: '1.56vw',
                      opacity: 1,
                    },
                  }),
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 'auto',
                    marginRight: '0.52vw', // 10px / 1920px * 100
                    color: location.pathname === item.path ? '#2563EB' : '#6B7280',
                  }}
                >
                  {item.text === '보험사' ? (
                    <Box
                      component="img"
                      key={location.pathname}  // Force re-render on path change
                      src={location.pathname === item.path ? GroupHoverIcon : GroupIcon}
                      sx={styles.menuItemIcon}
                      alt="보험사"
                    />
                  ) : (
                    item.icon
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={item.text}
                  primaryTypographyProps={{
                    style: {
                      ...styles.menuItemText,
                      color: location.pathname === item.path ? '#2563EB' : '#141414',
                    },
                  }}
                />
              </ListItem>
            ) : null
          )}
        </List>
        <Box sx={styles.logoutContainer}>
          <Box sx={styles.logoutLine} />
          <ListItem
            button
            onClick={handleLogout}
            sx={{
              paddingLeft: '2.08vw', // 40px / 1920px * 100
              height: '4.44vh', // 48px / 1080px * 100
              '& .MuiListItemIcon-root': {
                color: '#9CA3AF',
              },
              '&:hover .MuiListItemIcon-root': {
                color: '#6B7280',
              },
            }}
          >
            <ListItemIcon sx={{ minWidth: 'auto', marginRight: '0.42vw' }}> {/* 8px / 1920px * 100 */}
              <LogoutOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="LOGOUT" primaryTypographyProps={{ style: styles.logoutText }} />
          </ListItem>
        </Box>
        <Box sx={styles.rightBorderLine} />
      </Drawer>
    </Box>
  );
};

export default Dashboard;
