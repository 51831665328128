import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

const styles = {
  chartContainer: {
    backgroundColor: '#F9FAFB',
    border: '0.712644px solid #D1D5DB',
    borderRadius: 'clamp(4px, 0.3vw, 8px)',
    padding: 'clamp(10px, 1.5vh, 20px)',
    margin: 'clamp(10px, 2vh, 30px) 0',
    height: 'clamp(200px, 30vh, 400px)',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    marginBottom: 'clamp(5px, 1vh, 15px)',
  },
  mainTitle: {
    fontFamily: 'Pretendard, sans-serif',
    fontSize: 'clamp(16px, 1.25vw, 24px)',
    fontWeight: '700',
    color: '#2563EB',
  },
  subTitle: {
    fontFamily: 'Pretendard, sans-serif',
    fontSize: 'clamp(12px, 0.83vw, 18px)',
    fontWeight: '700',
    color: '#000000',
    marginLeft: 'clamp(4px, 0.5vw, 10px)',
    marginTop: 'clamp(2px, 0.2vw, 5px)',
  },
};

const CustomTooltip = ({ active, payload, label, dataKey }) => {
  if (active && payload && payload.length) {
    let value;
    let displayKey = dataKey;
    if (dataKey === 'speed') {
      value = `${payload[0].value} km/h`;
    } else if (dataKey === 'rpm') {
      value = `${payload[0].value} rpm`;
    } else if (dataKey === 'load') {
      value = `${parseFloat(payload[0].value).toFixed(2)}°C`;
      displayKey = 'temp';  // 'load'를 'temp'로 변경
    }

    return (
      <Box sx={{ backgroundColor: 'white', padding: 'clamp(4px, 0.5vw, 10px)', border: '1px solid #ccc', borderRadius: 'clamp(2px, 0.2vw, 5px)' }}>
        <Typography sx={{ fontSize: 'clamp(10px, 0.7vw, 14px)' }}>{`Time: ${label} sec`}</Typography>
        <Typography sx={{ fontSize: 'clamp(10px, 0.7vw, 14px)' }}>{`${displayKey}: ${value}`}</Typography>
      </Box>
    );
  }

  return null;
};

const parseAccidentData = (allData, car_speed) => {
  if (!allData) return [];
  const timePoints = ['mfive', 'mfour', 'mthree', 'mtwo', 'mone', 'now', 'pone'];
  return timePoints.map((point, index) => ({
    time: index - 5,
    speed: point === 'now' ? parseFloat(car_speed) || 0 : parseFloat(allData[point]?.speed) || 0,
    rpm: parseFloat(allData[point]?.rpm) || 0,
    load: parseFloat(allData[point]?.load || allData[point]?.engin_load) || 0,
  }));
};

const AccidentChart = ({ allData, car_speed, dataKey, color, title, yDomain: initialYDomain, yAxisLabel }) => {
  const [chartData, setChartData] = useState([]);
  const [yDomain, setYDomain] = useState(initialYDomain);

  useEffect(() => {
    const parsedData = parseAccidentData(allData, car_speed);
    setChartData(parsedData);
    updateDomain(parsedData);
  }, [allData, car_speed, dataKey, initialYDomain]);

  const updateDomain = (data) => {
    const maxValue = Math.max(...data.map(d => d[dataKey]), initialYDomain[1]);
    const newMax = Math.ceil(maxValue / (initialYDomain[1] / 4)) * (initialYDomain[1] / 4);
    setYDomain([0, newMax]);
  };

  const renderYAxis = () => (
    <YAxis
      domain={yDomain}
      axisLine={false}
      tickLine={false}
      tick={{ fontSize: 'clamp(8px, 0.7vw, 14px)', fill: color }}
      ticks={[0, yDomain[1] / 4, yDomain[1] / 2, (yDomain[1] * 3) / 4, yDomain[1]]}
      label={{
        value: yAxisLabel,
        position: 'insideBottom',
        fill: color,
        fontFamily: 'Pretendard',
        fontWeight: '400',
        fontSize: 'clamp(8px, 0.6vw, 12px)',
        marginLeft: 'clamp(20px, 5vw, 100px)',
        offset: -28,
      }}
    />
  );

  return (
    <Box sx={styles.chartContainer}>
      <Box sx={styles.titleContainer}>
        <Typography sx={styles.mainTitle}>세이프카</Typography>
        <Typography sx={styles.subTitle}>{title}</Typography>
      </Box>
      <ResponsiveContainer width="100%" height="85%">
        <LineChart data={chartData} margin={{ left: 0, right: 10, bottom: 10, top: 10 }}>
          <CartesianGrid stroke="#E0E0E0" vertical={false} />
          <XAxis 
            dataKey="time"
            tick={{ fontSize: 'clamp(10px, 0.83vw, 16px)', fill: '#000000' }}
            axisLine={{ stroke: '#ccc' }}
            tickLine={{ stroke: '#ccc' }}
          />
          {renderYAxis()}
          <Tooltip
            content={<CustomTooltip dataKey={dataKey} />}
            position={{ x: 0, y: 0 }}
            cursor={{ stroke: '#D1D5DB', strokeWidth: 2 }}
          />
          <Line type="monotone" dataKey={dataKey} stroke={color} strokeWidth={2} dot={false} />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default AccidentChart;