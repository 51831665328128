// SafeWebStyles.js

const SafeWebStyles = (scale,mobileScale, showScrollTop, isLangDropdownOpen, isLangHovered, header1Visible, header2Visible) => ({
  
  container: {
    width: '100vw',
    height: '100vh',
    overflowY: 'scroll',
    overflowX: 'hidden',
    scrollSnapType: 'y mandatory',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#FFF',
    position: 'relative',
    fontFamily: "'Spoqa Han Sans Neo', sans-serif",
    '&::-webkit-scrollbar': { width: '10px' },
    '&::-webkit-scrollbar-track': { background: '#f1f1f1' },
    '&::-webkit-scrollbar-thumb': { background: '#888', borderRadius: '5px' },
    '&::-webkit-scrollbar-thumb:hover': { background: '#555' },
    scrollbarWidth: 'thin',
    scrollbarColor: '#888 #f1f1f1',

  },
  section: {
    width: '100%',
    height: '100vh',
    scrollSnapAlign: 'start',
  },
  overlay50: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1,
  },
  overlay70: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    zIndex: 1,
  },

});

export default SafeWebStyles;
