import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, Snackbar, Box, CircularProgress, Divider } from '@mui/material';
import { useIncidents } from '../../context/IncidentContext';
import GenericTable from '../../components/utils/GenericTable';
import MemberPagination from '../../components/member/MemberPagination';
import SubLayout from '../../components/layout/SubLayout';

const Incidents = () => {
  const { userIds, loading } = useIncidents();
  const navigate = useNavigate();
  const [alertOpen, setAlertOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;

  const handleUserClick = (userId) => {
    navigate(`/incidents/${userId}`);
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const userColumns = [
    { id: 'no', label: 'No.', align: 'right', width: '10%' },
    { id: 'userId', label: 'ID', align: 'left', width: '90%' },
  ];

  useEffect(() => {
    if (loading && userIds.length === 0) {
      setAlertOpen(true);
    }
  }, [loading, userIds]);

  if (loading) {
    return (
      <Container maxWidth="xl" sx={{ mt: '4vh', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Container>
    );
  }

  const paginatedData = userIds.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage).map((userId, index) => ({
    no: (currentPage - 1) * rowsPerPage + index + 1,
    userId,
  }));

  return (
    <SubLayout>
      <Box>
        <Typography 
          variant="h4" 
          sx={{ 
            fontWeight: 'bold', 
            marginBottom: '1.67vh', 
            fontSize: 'clamp(24px, 2vw, 36px)'  // 최소 24px, 최대 36px
          }}
        >
          사고 기록
        </Typography>
        <Typography 
          variant="h6" 
          sx={{ 
            marginBottom: '1.67vh', 
            color: '#6B7280', 
            fontWeight: 500, 
            fontSize: 'clamp(16px, 1vw, 24px)'  // 최소 16px, 최대 24px
          }}
        >
          모든 사용자
        </Typography>
        <Divider />
      </Box>
      <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
        <GenericTable
          columns={userColumns}
          data={paginatedData}
          onRowClick={(row) => handleUserClick(row.userId)}
          itemsPerPage={rowsPerPage}
        />
      </Box>
      <MemberPagination
        count={Math.ceil(userIds.length / rowsPerPage)}
        page={currentPage}
        onChange={handlePageChange}
      />
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={() => setAlertOpen(false)}
        message="No incidents found"
      />
    </SubLayout>
  );
};

export default Incidents;