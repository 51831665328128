import React, { useEffect, useRef, useCallback, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import MarkerBlue from '../../styles/icons/accident/MarkerBlue.png';
import MarkerRed from '../../styles/icons/accident/MarkerRed.png';
import { MarkerClusterer } from "@googlemaps/markerclusterer";

const GoogleMap = ({ markers = [], center, zoom = 18, onMarkerClick }) => {
  const mapRef = useRef(null);
  const mapInstance = useRef(null);
  const markerClusterer = useRef(null);
  const markerObjects = useRef([]); // Keep track of marker objects
  const [scriptLoaded, setScriptLoaded] = useState(false);

  useEffect(() => {
    const loadGoogleMapScript = () => {
      if (document.getElementById('google-maps-script')) {
        setScriptLoaded(true);
        return;
      }

      const script = document.createElement('script');
      script.id = 'google-maps-script';
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;
      script.async = true;
      script.defer = true;
      script.onload = () => setScriptLoaded(true);
      script.onerror = () => {
        console.error('Failed to load Google Maps script.');
      };
      document.head.appendChild(script);
    };

    loadGoogleMapScript();
  }, []);

  const initializeMap = useCallback(() => {
    if (!window.google || !window.google.maps || mapInstance.current) return;

    const mapOptions = {
      center: new window.google.maps.LatLng(center?.lat || 37.5665, center?.lng || 126.9780),
      zoom,
    };

    mapInstance.current = new window.google.maps.Map(mapRef.current, mapOptions);
    markerClusterer.current = new MarkerClusterer({ 
      map: mapInstance.current,
      gridSize: 50,
      minimumClusterSize: 1,
    });
    addMarkers(markers);
  }, [center, zoom, markers]);

  const addMarkers = useCallback((markers) => {
    if (!window.google || !window.google.maps || !mapInstance.current || !markerClusterer.current) return;

    // Clear previous markers
    markerObjects.current.forEach(marker => marker.setMap(null));
    markerObjects.current = [];

    // Add new markers with error checking
    markers.forEach(markerData => {
      if (!markerData.position || typeof markerData.position.lat !== 'number' || typeof markerData.position.lng !== 'number') {
        return; // Skip invalid marker data
      }

      const markerPosition = new window.google.maps.LatLng(markerData.position.lat, markerData.position.lng);
      const marker = new window.google.maps.Marker({
        position: markerPosition,
        title: markerData.title,
        map: mapInstance.current,
        icon: {
          url: markerData.isSelected ? MarkerRed : MarkerBlue,
          scaledSize: new window.google.maps.Size(24, 35),
        },
      });

      marker.addListener('click', () => {
        if (onMarkerClick) onMarkerClick(markerData.accident);
      });

      markerObjects.current.push(marker);
    });

    // Add markers to clusterer
    markerClusterer.current.clearMarkers(); // Clear previous markers in clusterer
    markerClusterer.current.addMarkers(markerObjects.current);
  }, [onMarkerClick]);

  useEffect(() => {
    if (scriptLoaded && mapRef.current && !mapInstance.current) {
      initializeMap();
    }
  }, [scriptLoaded, initializeMap]);

  useEffect(() => {
    if (center && mapInstance.current) {
      mapInstance.current.setCenter(new window.google.maps.LatLng(center.lat, center.lng));
    }
  }, [center]);

  useEffect(() => {
    if (mapInstance.current) {
      addMarkers(markers);
    }
  }, [markers, addMarkers]);

  if (!scriptLoaded) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <CircularProgress />
      </Box>
    );
  }

  return <div ref={mapRef} style={{ width: '100%', height: '100%' }} />;
};

export default GoogleMap;
