import React, { useState, useRef, useEffect } from 'react';
import { styled } from '@mui/system';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  FormControl,
  Box,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { Clear as ClearIcon } from '@mui/icons-material';
import useWindowDimensions from '../../hook/useWindowDimensions';

const CustomDialogContent = styled(DialogContent)({
  '&::-webkit-scrollbar': {
    width: 'clamp(4px, 0.42vw, 8px)',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#f1f1f1',
    borderRadius: 'clamp(5px, 0.52vw, 10px)',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#888',
    borderRadius: 'clamp(5px, 0.52vw, 10px)',
    '&:hover': {
      backgroundColor: '#555',
    },
  },
});

const GenericDialog = ({ open, data, onClose, onSave, onChange, fields, title }) => {
  const [currentData, setCurrentData] = useState(data || {});
  const [focusedField, setFocusedField] = useState(null);
  const refs = useRef(fields.map(() => React.createRef()));
  const { isNarrow } = useWindowDimensions();

  useEffect(() => {
    setCurrentData(data || {});
  }, [data]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setCurrentData(prev => ({ ...prev, [name]: value }));
    onChange(name, value);
  };

  const handleClear = (fieldId) => {
    setCurrentData(prev => ({ ...prev, [fieldId]: '' }));
    onChange(fieldId, '');
  };

  const handleClose = () => {
    setCurrentData({});
    onClose();
  };

  const handleKeyDown = (event, index) => {
    if (event.key === 'Enter' || event.key === 'Tab') {
      event.preventDefault();
      const nextIndex = (index + 1) % fields.length;
      refs.current[nextIndex].current.focus();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          borderRadius: 'clamp(10px, 1.04vw, 20px)',
          padding: 'clamp(5px, 0.5vw, 10px)',
          width: isNarrow ? '60vw' : 'clamp(400px, 30vw, 600px)',
          maxWidth: 'none'
        }
      }}
    >
    
    <DialogTitle sx={{ fontWeight: 'bold', color: 'black', marginBottom: 'clamp(10px, 1vw, 20px)', fontSize: 'clamp(18px, 1.15vw, 24px)' }}>
        {title}
      </DialogTitle>
      <CustomDialogContent sx={{ minWidth: isNarrow ? 'auto' : 'clamp(300px, 25vw, 500px)' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {fields.map((field, index) => {
            if (field.type === 'radio') {
              return (
                <FormControl component="fieldset" key={field.id}>
                  <FormLabel component="legend">{field.label}</FormLabel>
                  <RadioGroup
                    row
                    aria-label={field.id}
                    name={field.id}
                    value={currentData[field.id] || ''}
                    onChange={handleChange}
                  >
                    {field.options.map((option) => (
                      <FormControlLabel key={option} value={option} control={<Radio />} label={option} />
                    ))}
                  </RadioGroup>
                </FormControl>
              );
            }
            return (
              <TextField
                key={field.id}
                label={field.label}
                name={field.id}
                variant="outlined"
                fullWidth
                value={currentData[field.id] || ''}
                onChange={handleChange}
                onFocus={() => setFocusedField(field.id)}
                onBlur={() => setFocusedField(null)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                inputRef={refs.current[index]}
                sx={{
                  mb: 2,
                  marginTop: 1,
                  '& .MuiInputBase-input.Mui-disabled': {
                    WebkitTextFillColor: '#000',
                  }
                }}
                InputProps={{
                  endAdornment: (
                    focusedField === field.id && (
                      <InputAdornment position="end">
                        <IconButton onClick={() => handleClear(field.id)}>
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    )
                  ),
                }}
              />
            );
          })}
        </Box>
      </CustomDialogContent>
      <DialogActions>
        <Button onClick={handleClose} sx={{ fontWeight: 'bold', color: 'black', fontSize: 'clamp(14px, 0.83vw, 18px)' }}>
          취소
        </Button>
        <Button onClick={() => { onSave(currentData); handleClose(); }} sx={{ fontWeight: 'bold', color: 'black', fontSize: 'clamp(14px, 0.83vw, 18px)' }}>
          저장
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GenericDialog;