import React, { useState, useEffect } from 'react';
import InquiryModal from './InquiryModal';
import myren from '../../../styles/img/appbar/MYREN.png';
import '../styles/Footer.css';

const Footer = ({ scale, mobileScale,text }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    document.documentElement.style.setProperty('--scale', scale);
  }, [scale]);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <div className="footer-section">
      <div className="footer-row">
        <img src={myren} alt="MYREN" className="footer-logo" />
      </div>
      <div className="footer-row">
        <span className="footer-text">{text.address}</span>
        <span className="footer-line">|</span>
        <span className="footer-text">{text.businessNumber}</span>
        <span className="footer-line">|</span>
        <span className="footer-text">{text.representative}</span>
      </div>
      <div className="footer-row">
        <span
          className="footer-link"
          onClick={() => window.open('https://safecar.pro/safeWeb/policy', '_blank')}
        >
          {text.privacyPolicy}
        </span>
        <span className="footer-line">|</span>
        <span
          className="footer-link"
          onClick={() => window.open('https://safecar.pro/safeWeb/conditions', '_blank')}
        >
          {text.termsOfService}
        </span>
        <span className="footer-line">|</span>
        <span className="footer-copyright">
          © 2024 MYREN All rights reserved.
        </span>
        <span
          className="footer-link footer-link-underline inquiry-link"
          onClick={openModal}
        >
          {text.inquiryLink} <span className="arrow-icon">→</span>
        </span>
      </div>
      <InquiryModal isOpen={isModalOpen} onClose={closeModal}  mobileScale={mobileScale}  scale={scale} text={text} />
    </div>
  );
};

export default React.memo(Footer);