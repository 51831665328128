import React, { useEffect, useRef, useState } from 'react';

const KakaoStaticMap = ({ lat, lng, level, markerTitle }) => {
  const mapRef = useRef(null);
  const [scriptLoaded, setScriptLoaded] = useState(false);

  useEffect(() => {
    const loadKakaoMapScript = () => {
      if (document.getElementById('kakao-map-script')) {
        setScriptLoaded(true);
        return;
      }

      const script = document.createElement('script');
      script.id = 'kakao-map-script';
      script.async = true;
      script.src = `https://dapi.kakao.com/v2/maps/sdk.js?appkey=${process.env.REACT_APP_KAKAO_MAP_API_KEY}&autoload=false`;

      script.onload = () => {
        window.kakao.maps.load(() => {
          setScriptLoaded(true);
        });
      };

      script.onerror = () => {
        console.error('Failed to load Kakao Maps script.');
      };

      document.head.appendChild(script);
    };

    loadKakaoMapScript();
  }, []);

  useEffect(() => {
    if (scriptLoaded && window.kakao && window.kakao.maps) {
      const mapContainer = mapRef.current;
      const mapOption = {
        center: new window.kakao.maps.LatLng(lat, lng),
        level: level,
      };

      const map = new window.kakao.maps.Map(mapContainer, mapOption);

      const markerPosition = new window.kakao.maps.LatLng(lat, lng);
      const marker = new window.kakao.maps.Marker({
        position: markerPosition,
        title: markerTitle,
      });
      marker.setMap(map);
    }
  }, [scriptLoaded, lat, lng, level, markerTitle]);

  return <div ref={mapRef} style={{ width: '100%', height: '100%' }} />;
};

export default KakaoStaticMap;
