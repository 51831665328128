import React from 'react';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';

const CardStyled = styled(Card)(({ theme, selected }) => ({
  cursor: 'pointer',
  backgroundColor: selected ? 'rgba(78, 158, 249, 0.14)' : '#F3F4F6',
  color: '#000000',
  width: '100%',
  height: 'clamp(60px, 7.5vh, 100px)',
  borderRadius: 'clamp(6px, 0.52vw, 15px)',
  boxShadow: 'none',
  padding: '0 clamp(10px, 1.04vw, 30px)',
  display: 'flex',
  alignItems: 'center',
  '&:hover': {
    backgroundColor: 'rgba(78, 158, 249, 0.14)',
    boxShadow: '0 clamp(2px, 0.21vw, 6px) clamp(3px, 0.31vw, 9px) rgba(0, 0, 0, 0.1)',
  },
  '&.Mui-selected': {
    backgroundColor: 'rgba(78, 158, 249, 0.14)',
    boxShadow: '0 clamp(2px, 0.21vw, 6px) clamp(3px, 0.31vw, 9px) rgba(0, 0, 0, 0.1)',
  },
}));

const StyledCardContent = styled(CardContent)({
  width: '100%',
  padding: 'clamp(8px, 0.93vh, 15px) 0 !important',
});

const GridItemStyled = styled(Grid)({
  padding: '0 clamp(5px, 0.52vw, 15px)',
});

const LabelTypography = styled(Typography)({
  fontSize: 'clamp(10px, 0.73vw, 18px)',
  lineHeight: 'clamp(14px, 1.1vw, 24px)',
  fontWeight: 'bold',
  color: '#6B7280',
});

const ContentTypography = styled(Typography)({
  fontSize: 'clamp(10px, 0.83vw, 20px)',
  lineHeight: 'clamp(16px, 1.25vw, 28px)',
  fontWeight: 'bold',
});

const MemberCard = ({ member, onClick, isSelected }) => (
  <CardStyled onClick={onClick} selected={isSelected}>
    <StyledCardContent>
      <Grid container alignItems="center">
        <GridItemStyled item xs={1.5}>
          <LabelTypography>ID</LabelTypography>
          <ContentTypography>{member.userId}</ContentTypography>
        </GridItemStyled>
        <GridItemStyled item xs={1.5}>
          <LabelTypography>이름</LabelTypography>
          <ContentTypography>{member.userName}</ContentTypography>
        </GridItemStyled>
        <GridItemStyled item xs={2}>
          <LabelTypography>전화번호</LabelTypography>
          <ContentTypography>{member.userPhone}</ContentTypography>
        </GridItemStyled>
        <GridItemStyled item xs={2}>
          <LabelTypography>차량</LabelTypography>
          <ContentTypography>{member.userCarNumber} ({member.userCarModelName})</ContentTypography>
        </GridItemStyled>
        <GridItemStyled item xs={1.5}>
          <LabelTypography>보험사</LabelTypography>
          <ContentTypography>{member.insurance_name}</ContentTypography>
        </GridItemStyled>
        <GridItemStyled item xs={1.5}>
          <LabelTypography>사고감지 횟수</LabelTypography>
          <ContentTypography>{member.accidentCount || 0}</ContentTypography>
        </GridItemStyled>
        <GridItemStyled item xs={1.5}>
          <LabelTypography>보고서 요청 횟수</LabelTypography>
          <ContentTypography>{member.reportRequestCount || 0}</ContentTypography>
        </GridItemStyled>
      </Grid>
    </StyledCardContent>
  </CardStyled>
);

export default MemberCard;