import React, { useEffect, useState } from 'react';
import { useAuth } from '../../context/AuthProvider';
import { useParams } from 'react-router-dom';
import { Container, Typography, CircularProgress, Box, Modal, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useIncidents } from '../../context/IncidentContext';
import MapContainer from '../../components/maps/MapContainer';
import AccidentListCheck from '../../components/accident/AccidentListCheck';
import AccidentModal from '../../components/accident/AccidentModal';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { remove, ref } from 'firebase/database';
import { database } from '../../firebase';
import CustomButton from '../../components/utils/CustomButton';
import MainLayout from '../../components/layout/MainLayout';
import useWindowDimensions from '../../hook/useWindowDimensions';


const AccidentDetailsPage = () => {
  const { currentUser } = useAuth();
  const isViewer = currentUser?.role === 'viewer';
  const { userId } = useParams();
  const { fetchUserIncidents, userIncidents, setUserIncidents, selectedIncident, setSelectedIncident, loading, setCurrentPage, setCurrentUserId } = useIncidents();
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedAccidents, setSelectedAccidents] = useState([]);
  const { isNarrow } = useWindowDimensions();

  useEffect(() => {
    setCurrentPage('details');
    setCurrentUserId(userId);
    const loadUserIncidents = async () => {
      const incidents = await fetchUserIncidents(userId);
      setUserIncidents(incidents);
    };
    loadUserIncidents();
  }, [userId, fetchUserIncidents, setCurrentPage, setCurrentUserId, setUserIncidents]);

  const handleAccidentClick = (accident) => {
    setSelectedIncident(accident);
    setModalOpen(true);  // 모달창 열기
  };
  const handleMarkerClick = (accident) => {
    setSelectedIncident(accident);
    setModalOpen(true);
  };

  const handleCloseDetails = () => {
    setSelectedIncident(null);
    setModalOpen(false);
  };

  const handleDelete = async () => {
    try {
      const deletePromises = selectedAccidents.map(timeStamp => {
        const accidentRef = ref(database, `accidentList/${userId}/${timeStamp}`);
        return remove(accidentRef);
      });
      await Promise.all(deletePromises);
      const newIncidents = userIncidents.filter(accident => !selectedAccidents.includes(accident.timeStamp));
      setUserIncidents(newIncidents);
      setSelectedAccidents([]);
      setDeleteDialogOpen(false);
    } catch (error) {
      console.error("Error deleting accidents:", error);
    }
  };

  if (loading) {
    return (
      <Container maxWidth="xl" sx={{ mt: '4vh', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <MainLayout>
      <Box sx={{ 
        display: 'flex', 
        flexDirection: isNarrow ? 'column' : 'row', // 넓이 65% 이하일 때 수직 레이아웃
        height: '100%',
        overflow: 'hidden',
      }}>
        <Box sx={{ 
          width: isNarrow ? '100%' : '75vw', 
          height: isNarrow ? '60%' : '92vh',
        }}>
          <MapContainer
            initialLocation={userIncidents.length ? { lat: parseFloat(userIncidents[0].latitude), lng: parseFloat(userIncidents[0].longitude) } : { lat: 37.5665, lng: 126.9780 }}
            markers={userIncidents.map(accident => ({
              position: { lat: parseFloat(accident.latitude), lng: parseFloat(accident.longitude) },
              title: `장소: ${accident.address}`,
              isSelected: selectedIncident && selectedIncident.timeStamp === accident.timeStamp,
              accident,
            }))}
            center={selectedIncident ? { lat: parseFloat(selectedIncident.latitude), lng: parseFloat(selectedIncident.longitude) } : null}
            onMarkerClick={handleMarkerClick}
          />
        </Box>
        <Box sx={{ 
          width: isNarrow ? '100%' : '25vw', 
          height: isNarrow ? '40%' : '92vh', 
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            width: 'clamp(8px, 0.5vw, 12px)',  // 스크롤 바의 너비 조정
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: '#F3F4F6',  // 스크롤 바 배경
            borderRadius: '10px',  // 둥근 모서리 적용
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#9CA3AF',  // 스크롤 바의 색상
            borderRadius: '10px',  // 스크롤 바 모서리를 둥글게
            border: '2px solid transparent',  // 스크롤 바에 테두리 추가
            backgroundClip: 'content-box',  // 내부 여백 조정
          },
          '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#6B7280',  // 스크롤 바를 호버할 때 색상 변경
          },
        }}>
       <Box sx={{ padding: '1.04vh 0.42vw', borderTop: '1px solid #ddd' }}>
            <Box sx={{ marginTop: isNarrow ? '2vh' : '3vh', marginLeft: '0.52vw', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="h6" component="h3" gutterBottom sx={{ display: 'flex', alignItems: 'center', fontWeight: 'bold', fontSize: 'clamp(16px, 1.15vw, 22px)' }}>
                {userId} 사고 리스트
                <HelpOutlineOutlinedIcon sx={{ 
                  verticalAlign: 'middle', 
                  marginLeft: '0.52vw', 
                  color: '#4B5563', 
                  width: 'clamp(16px, 1.25vw, 24px)', 
                  height: 'clamp(16px, 1.25vw, 24px)' 
                }} />
              </Typography>
              <CustomButton 
                onClick={() => !isViewer && setDeleteDialogOpen(true)}
                disabled={isViewer}
                sx={{ 
                  fontSize: 'clamp(12px, 0.8vw, 16px)',
                  opacity: isViewer ? 0.5 : 1,
                  cursor: isViewer ? 'not-allowed' : 'pointer',
                  '&:hover': {
                    backgroundColor: isViewer ? '#E5E7EB' : undefined, // 기존 hover 효과 유지하거나 비활성화 시 색상 지정
                  }
                }}
              >
                삭제하기
              </CustomButton>
            </Box>
            <AccidentListCheck 
              accidents={userIncidents} 
              handleAccidentClick={handleAccidentClick} 
              selectedAccident={selectedIncident} 
              selectedAccidents={selectedAccidents} 
              setSelectedAccidents={setSelectedAccidents} 
              isViewer={isViewer}  // AccidentListCheck 컴포넌트에도 viewer 상태 전달
            />
          </Box>
        </Box>
      </Box>
      
      {/* 삭제 다이얼로그도 viewer일 때는 열리지 않도록 조건 추가 */}
      {!isViewer && (
        <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
          <DialogTitle sx={{ fontSize: '1.15vw', fontWeight: 'bold' }}>삭제 확인</DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ fontSize: '1vw' }}>선택한 사고를 삭제하시겠습니까?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteDialogOpen(false)} sx={{ fontSize: '0.83vw' }}>취소</Button>
            <Button onClick={handleDelete} color="primary" sx={{ fontSize: '0.83vw' }}>확인</Button>
          </DialogActions>
        </Dialog>
      )}
      
      {/* Modal은 그대로 유지 */}
      <Modal open={modalOpen} onClose={handleCloseDetails}>
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: '#F3F4F6', borderRadius: '1.04vw', p: '2.08vw' }}>
          {selectedIncident && <AccidentModal selectedAccident={selectedIncident} handleCloseDetails={handleCloseDetails} />}
        </Box>
      </Modal>
    </MainLayout>
  );
};

export default AccidentDetailsPage;
