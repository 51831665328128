import React from 'react';
import infoImage from '../../styles/Info.png'; // Adjust the path as necessary

const Info = () => {
  return (
    <div style={{ textAlign: 'center', maxHeight: '100vh', overflowY: 'auto' }}>
      <img 
        src={infoImage} 
        alt="Info" 
        style={{ 
          width: '100vw', 
          height: 'auto', 
          objectFit: 'contain', 
          display: 'block', 
          margin: '0 auto' 
        }} 
      />
    </div>
  );
};

export default Info;
