import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthProvider';
import KakaoStaticMap from './KakaoStaticMap';
import GoogleMap from './GoogleMap';
import { CircularProgress } from '@mui/material';

const MapContainerReport = ({ lat, lng, level = 3, markerTitle = "Accident Location" }) => {
  const { currentUser } = useAuth();
  const useGoogleMap = ['myren03', 'see2'].includes(currentUser?.username);
  const [isLoading, setIsLoading] = useState(true);
  const [isValidLocation, setIsValidLocation] = useState(false);

  useEffect(() => {
    if (lat && lng && !isNaN(lat) && !isNaN(lng)) {
      setIsValidLocation(true);
      setIsLoading(false);
    } else {
      setIsValidLocation(false);
      setIsLoading(false);
    }
  }, [lat, lng]);

  if (isLoading) {
    return <CircularProgress />;
  }

  if (!isValidLocation) {
    return <div>유효하지 않은 위치 데이터입니다. 잠시 후 다시 시도해주세요.</div>;
  }

  const markers = [{ position: { lat, lng }, title: markerTitle }];

  return (
    <div style={{ width: '100%', height: '100%' }}>
      {useGoogleMap ? (
        <GoogleMap center={{ lat, lng }} markers={markers} onMarkerClick={() => {}} />
      ) : (
        <KakaoStaticMap lat={lat} lng={lng} level={level} markerTitle={markerTitle} />
      )}
    </div>
  );
};

export default MapContainerReport;