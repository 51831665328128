import React, { useContext, useState, useEffect } from 'react';
import dropdown from '../../../styles/img/appbar/dropdown.png';
import dropup from '../../../styles/img/appbar/dropup.png';
import lang from '../../../styles/img/appbar/lang.png';
import { LanguageContext } from '../../../context/LanguageContext';
import '../styles/LangDropdown.css';

const LangDropdown = ({ isLangDropdownOpen, setIsLangDropdownOpen, isLangHovered, setIsLangHovered, styles, scale, mobileScale }) => {
  const { language, setLanguage } = useContext(LanguageContext);
  const [hoveredOption, setHoveredOption] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 431);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 431);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    document.documentElement.style.setProperty('--scale', scale);
    document.documentElement.style.setProperty('--mobile-scale', mobileScale);
  }, [scale, mobileScale]);

  const handleLanguageChange = (newLanguage) => {
    setLanguage(newLanguage);
    localStorage.setItem('language', newLanguage);
    setIsLangDropdownOpen(false);
  };

  return (
    <div
      className="lang-dropdown"
      style={{color: isLangDropdownOpen || isLangHovered ? 'white' : '#9CA3AF'}}
      onClick={() => setIsLangDropdownOpen(!isLangDropdownOpen)}
      onMouseEnter={() => setIsLangHovered(true)}
      onMouseLeave={() => setIsLangHovered(false)}
    >
      <img
        src={isLangDropdownOpen ? dropup : dropdown}
        alt="Toggle dropdown"
        className="drop-icon"
        style={{filter: isLangDropdownOpen || isLangHovered ? 'brightness(0) invert(1)' : 'none'}}
      />
      <span 
        className="lang-text"
        style={{color: isLangDropdownOpen || isLangHovered ? 'white' : '#9CA3AF'}}
      >
        {language === 'en' ? 'ENG' : language === 'ko' ? '한국어' : '日本語'}
      </span>
      {!isMobile && (
        <img
          src={lang}
          alt="Language"
          className="lang-icon"
          style={{filter: isLangDropdownOpen || isLangHovered ? 'brightness(0) invert(1)' : 'none'}}
        />
      )}
      <div className={`lang-options ${isLangDropdownOpen ? 'open' : ''}`}>
        {['en', 'ko', 'ja'].map((lang) => (
          <div
            key={lang}
            className="lang-option"
            style={{color: hoveredOption === lang ? 'white' : '#9CA3AF'}}
            onClick={() => handleLanguageChange(lang)}
            onMouseEnter={() => setHoveredOption(lang)}
            onMouseLeave={() => setHoveredOption(null)}
          >
            {lang === 'en' ? 'English' : lang === 'ko' ? '한국어' : '日本語'}
          </div>
        ))}
      </div>
    </div>
  );
};

export default LangDropdown;