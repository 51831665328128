// src/utils/encodingUtils.js

export const encodeParams = (userId, timeStamp) => {
  const encodedUserId = encodeURIComponent(btoa(userId));
  const encodedTimeStamp = encodeURIComponent(btoa(timeStamp));
  return { encodedUserId, encodedTimeStamp };
};

export const decodeParams = (encodedUserId, encodedTimeStamp) => {
  const userId = atob(decodeURIComponent(encodedUserId));
  const timeStamp = atob(decodeURIComponent(encodedTimeStamp));
  return { userId, timeStamp };
};
