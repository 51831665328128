import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthProvider';
import { TextField, Button, Paper, Container, Typography, Box, Grid, Tabs, Tab } from '@mui/material';

const LoginPage = ({ isIPAllowed, ipAddress }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [tab, setTab] = useState(0);
  const { setCurrentUser, login } = useAuth();
  const navigate = useNavigate();

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
    setUsername('');
    setPassword('');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const user = await login(username, password, tab === 0 ? 'view' : 'edit');
      setCurrentUser(user);
      navigate('/main');
    } catch (error) {
      alert('로그인 실패: 잘못된 사용자명 또는 비밀번호');
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '100vh' }}>
        <Grid item>
          <Paper elevation={6} sx={{ padding: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography component="h1" variant="h5">
              Login
            </Typography>
            <Tabs value={tab} onChange={handleTabChange} sx={{ mb: 2 }}>
              <Tab label="관람용" />
              <Tab label="수정용" />
            </Tabs>
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoComplete="username"
                autoFocus
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={tab === 1 && !isIPAllowed}
              >
                Login
              </Button>
            </Box>
            {tab === 1 && !isIPAllowed && (
              <Box sx={{ mt: 2, textAlign: 'center' }}>
                <Typography color="error" sx={{ mt: 1 }}>
                  현재 IP 주소: {ipAddress}
                </Typography>
                <Typography  sx={{ mt: 1 }}>
                  수정용 로그인을 위해서는 IP 인증이 필요합니다.<br></br> IP 주소를 등록해주세요.
                </Typography>
              </Box>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default LoginPage;