import React from 'react';
import { Box } from '@mui/material';
import MemberCard from './MemberCard';

const MemberList = ({ members, onCardClick, selectedMember }) => (
  <Box sx={{
    maxHeight: 'calc(100vh - 25vh)', // 대략적인 값, 필요에 따라 조정
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: 'clamp(6px, 0.42vw, 12px)',
      height: 'clamp(6px, 0.42vw, 12px)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
      borderRadius: 'clamp(2px, 0.21vw, 6px)',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#555',
    },
  }}>
    {members.map((member) => (
      <Box key={member.userId} sx={{
        mb: 'clamp(10px, 1.85vh, 30px)',
        mt: 'clamp(10px, 1.85vh, 30px)',
      }}>
        <MemberCard
          member={member}
          onClick={() => onCardClick(member)}
          isSelected={selectedMember && selectedMember.userId === member.userId}
        />
      </Box>
    ))}
  </Box>
);

export default MemberList;