import React from 'react';
import { Snackbar, Alert, Box, Typography } from '@mui/material';
import { TaskAlt as TaskAltIcon } from '@mui/icons-material';
import { useSendNoti } from '../../context/SendNotiContext';

const NotiSnackbar = () => {
  const { snackbarState, updateSnackbarState } = useSendNoti();

  const handleClose = () => {
    updateSnackbarState({ open: false });
  };

  return (
    <Snackbar
      open={snackbarState.open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert
        onClose={handleClose}
        severity={snackbarState.severity}
        sx={{
          width: '100%',
          backgroundColor: '#F3F4F6',
          color: '#000000',
          boxShadow: '0 clamp(2px, 0.37vh, 5px) clamp(10px, 1.85vh, 25px) rgba(0, 0, 0, 0.1)',
          border: 'clamp(1px, 0.09vh, 2px) solid #E5E7EB',
          borderRadius: 'clamp(4px, 0.74vh, 10px)',
          '& .MuiAlert-icon': {
            fontSize: 'clamp(16px, 2.22vh, 30px)',
          },
          '& .MuiAlert-action': {
            paddingTop: 'clamp(2px, 0.37vh, 5px)',
          },
        }}
        iconMapping={{
          success: <TaskAltIcon sx={{ color: '#2563EB' }} />,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="body1" gutterBottom sx={{ 
            color: '#2563EB', 
            fontWeight: 'bold', 
            fontSize: 'clamp(14px, 1.67vh, 20px)' 
          }}>
            알림이 성공적으로 전송되었습니다!
          </Typography>
        </Box>
        <Typography variant="subtitle2" sx={{ 
          color: '#000000', 
          fontSize: 'clamp(12px, 1.48vh, 18px)' 
        }}>
          <strong>제목:</strong> {snackbarState.title}
        </Typography>
        <Typography variant="subtitle2" sx={{ 
          color: '#000000', 
          fontSize: 'clamp(12px, 1.48vh, 18px)' 
        }}>
          <strong>메세지:</strong> {snackbarState.message}
        </Typography>
      </Alert>
    </Snackbar>
  );
};

export default NotiSnackbar;