import React, { createContext, useState, useEffect, useContext } from 'react';
import { getDatabase, ref, onValue, update } from 'firebase/database';
import axios from 'axios';

const ServiceContext = createContext();

export const ServiceProvider = ({ children }) => {
  const [complaints, setComplaints] = useState([]);

  useEffect(() => {
    const fetchComplaints = () => {
      const db = getDatabase();
      const complaintsRef = ref(db, 'cs');

      onValue(complaintsRef, (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          const formattedComplaints = Object.entries(data).flatMap(([userId, userComplaints]) =>
            Object.entries(userComplaints).map(([timestamp, complaint]) => ({
              userId,
              timestamp,
              ...complaint,
            }))
          );
          setComplaints(formattedComplaints);
        } else {
        }
      });
    };

    fetchComplaints();
  }, []);

  const updateComplaint = async (updatedComplaint) => {
    const db = getDatabase();
    const complaintsRef = ref(db, `cs/${updatedComplaint.userId}/${updatedComplaint.timestamp}`);
    
    try {
      await update(complaintsRef, {
        answerBody: updatedComplaint.answerBody,
        answerTimeStamp: updatedComplaint.answerTimeStamp
      });
    } catch (error) {
      console.error("Error updating complaint:", error);
      throw error;
    }
  };
  const sendFCMNotificationToUserById = async (userId, title, body) => {
    try {
      const response = await axios.post('https://us-central1-safecar-1487e.cloudfunctions.net/api/sendNotificationToUser', { userId, title, body });
      return response.data;
    } catch (error) {
      console.error(`Failed to send FCM notification to user ${userId}:`, error);
      throw error;
    }
  };

  return (
    <ServiceContext.Provider value={{ complaints, updateComplaint, sendFCMNotificationToUserById }}>
      {children}
    </ServiceContext.Provider>
  );
};

export const useService = () => useContext(ServiceContext);
