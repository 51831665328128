// CustomButton.js
import React from 'react';
import { Button } from '@mui/material';

const buttonStyles = {
  normal: {
    backgroundColor: 'transparent',
    color: 'black',
    fontWeight: 'bold',
    borderColor: '#E5E7EB',
    borderWidth: 'clamp(1px, 0.1vw, 2px)',
    borderStyle: 'solid',
    borderRadius: 'clamp(6px, 0.52vw, 12px)',
    padding: 'clamp(4px, 0.42vw, 10px) clamp(8px, 0.83vw, 20px)',
    marginLeft: 'auto',
    fontSize: 'clamp(12px, 0.83vw, 18px)',
  },
  hover: {
    backgroundColor: '#fff',
    color: 'black',
    borderColor: '#4285f4',
  },
  active: {
    backgroundColor: '#4285f4',
    color: 'white',
  },
};

const CustomButton = ({ children, onClick, sx = {}, ...props }) => {
  return (
    <Button
      onClick={onClick}
      sx={{
        ...buttonStyles.normal,
        ...sx,
        '&:hover': buttonStyles.hover,
        '&:active': buttonStyles.active,
      }}
      {...props}
    >
      {children}
    </Button>
  );
};

export default CustomButton;