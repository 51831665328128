import React, { createContext, useState, useEffect } from 'react';
import { getDatabase, ref, onValue, off, update } from "firebase/database";

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const [currentNotifications, setCurrentNotifications] = useState([]);

  useEffect(() => {
    const db = getDatabase();
    const adminNotiRef = ref(db, 'adminNoti');

    const fetchNotifications = () => {
      onValue(adminNotiRef, (snapshot) => {
        const data = snapshot.val();
        let notificationList = [];
        const processNotifications = (type, data) => {
          Object.keys(data).forEach(userId => {
            Object.keys(data[userId]).forEach(timeStamp => {
              const item = data[userId][timeStamp];
              if (item.displayed === undefined || item.displayed === false) {
                notificationList.push({ ...item, id: `${type}/${userId}/${timeStamp}`, type, userId });
              }
            });
          });
        };

        processNotifications('accidentList', data.accidentList || {});
        processNotifications('requestReport', data.requestReport || {});
        processNotifications('cs', data.cs || {});

        setNotifications(notificationList);
      });
    };

    fetchNotifications();

    return () => {
      off(adminNotiRef);
    };
  }, []);

  useEffect(() => {
    if (notifications.length > 0) {
      const newNotifications = notifications.filter(notification => !currentNotifications.some(n => n.id === notification.id));
      if (newNotifications.length > 0) {
        setCurrentNotifications(prev => [...prev, ...newNotifications]);
      }
    }
  }, [notifications, currentNotifications]);

  const handleNotificationClose = (id) => {
    markAsDisplayed(id);
    setCurrentNotifications((prev) => prev.filter((notification) => notification.id !== id));
  };

  const markAsDisplayed = async (path) => {
    const db = getDatabase();
    const itemRef = ref(db, `adminNoti/${path}`);
    await update(itemRef, { displayed: true });
  };

  return (
    <NotificationContext.Provider value={{ currentNotifications, handleNotificationClose }}>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotifications = () => React.useContext(NotificationContext);
