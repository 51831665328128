import React, { useState } from 'react';
import { Container, Paper, Box, Button, Typography, CircularProgress, Stack, Chip } from '@mui/material';
import { useAdmin } from '../../context/AdminContext';
import { useAuth } from '../../context/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { Add as AddIcon } from '@mui/icons-material';
import GenericDialog from '../../components/utils/GenericDialog';
import SearchBox from '../../components/utils/SearchBox';
import GenericTable from '../../components/utils/GenericTable';

const initialAdmins = [
  { id: 1, username: 'admin1', grade: 1, phone_number: '123-456-7890', email: 'admin1@example.com', memberLimit: 10 },
  { id: 2, username: 'admin2', grade: 2, phone_number: '234-567-8901', email: 'admin2@example.com', memberLimit: 20 },
  { id: 3, username: 'admin3', grade: 3, phone_number: '345-678-9012', email: 'admin3@example.com', memberLimit: 30 },
  { id: 4, username: 'admin4', grade: 3, phone_number: '456-789-0123', email: 'admin4@example.com', memberLimit: 40 },
  { id: 5, username: 'admin5', grade: 3, phone_number: '567-890-1234', email: 'admin5@example.com', memberLimit: 50 },
  { id: 6, username: 'admin6', grade: 3, phone_number: '678-901-2345', email: 'admin6@example.com', memberLimit: 60 },
];

const AdminList = () => {
  const { currentUser } = useAuth();
  const { addAdmin, updateAdmin } = useAdmin();
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState('');
  const [searchType, setSearchType] = useState('username');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [admins, setAdmins] = useState(initialAdmins);
  const [currentAdmin, setCurrentAdminState] = useState({ username: '', grade: '3', password: '', phone_number: '', email: '', memberLimit: 0 });
  const [filter, setFilter] = useState(currentUser.grade === 2 ? '3' : 'all');



  const handleAddAdmin = () => {
    setDialogOpen(true);
    setEditMode(false);
    setCurrentAdminState({ username: '', grade: '3', password: '', phone_number: '', email: '', memberLimit: 0 });
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDialogSave = async () => {
    try {
      if (editMode) {
        await updateAdmin(currentAdmin);
        setAdmins((prev) => prev.map((admin) => (admin.id === currentAdmin.id ? currentAdmin : admin)));
      } else {
        await addAdmin(currentAdmin);
        setAdmins((prev) => [...prev, { ...currentAdmin, id: prev.length + 1 }]);
      }
      handleDialogClose();
    } catch (error) {
      console.error('Failed to save admin:', error);
    }
  };

;

  const handleDialogChange = (name, value) => {
    setCurrentAdminState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const filteredAdmins = admins
    .filter(admin => admin.username.toLowerCase().includes(searchTerm) || admin.phone_number.toLowerCase().includes(searchTerm))
    .filter(admin => filter === 'all' || admin.grade.toString() === filter);

  const columns = [
    { id: 'username', label: 'Username' },
    { id: 'grade', label: 'Grade' },
    { id: 'phone_number', label: 'Phone Number' },
    { id: 'email', label: 'Email' },
  ];

  if (!currentUser) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="xl" sx={{ mt: 4 }}>
      <Paper
        sx={{
          p: 4,
          boxShadow: 3,
          borderRadius: 5,
          height: '90vh',
          overflow: 'auto',
          '&::-webkit-scrollbar': {
            width: '8px',
            height: '8px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888',
            borderRadius: '4px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#555',
          },
        }}
      >
        <Typography variant="h4" gutterBottom>
          Admin Dashboard
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2, justifyContent: 'space-between' }}>
          <SearchBox
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            searchType={searchType}
            setSearchType={setSearchType}
            searchTypes={[
              { value: 'username', label: 'Username' },
              { value: 'phone_number', label: 'Phone Number' },
            ]}
          />
          {(currentUser.grade === 1 || currentUser.grade === 2) && (
            <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleAddAdmin}>
              Add Admin
            </Button>
          )}
        </Box>
        {currentUser.grade === 1 && (
          <Stack direction="row" spacing={1} sx={{ marginBottom: 2 }}>
            <Chip label="All" onClick={() => setFilter('all')} color={filter === 'all' ? 'primary' : 'default'} />
            <Chip label="Main" onClick={() => setFilter('1')} color={filter === '1' ? 'primary' : 'default'} />
            <Chip label="Sub" onClick={() => setFilter('2')} color={filter === '2' ? 'primary' : 'default'} />
            <Chip label="Admin" onClick={() => setFilter('3')} color={filter === '3' ? 'primary' : 'default'} />
          </Stack>
        )}
        <GenericTable columns={columns} data={filteredAdmins} onRowClick={(admin) => navigate(`/admins/${admin.id}`)} />
      </Paper>
      <GenericDialog
        open={dialogOpen}
        data={currentAdmin}
        onClose={handleDialogClose}
        onSave={handleDialogSave}
        onChange={handleDialogChange}
        title="Admin"
        fields={[
          { id: 'username', label: 'Username', autoFocus: true },
          { id: 'password', label: 'Password', type: 'password' },
          { id: 'phone_number', label: 'Phone Number' },
          { id: 'email', label: 'Email' },
          { id: 'memberLimit', label: 'Member Limit', type: 'number' },
          {
            id: 'grade',
            label: 'Grade',
            type: 'radio',
            options: currentUser.grade === 1 ? ['1', '2', '3'] : ['3'],
          },
        ]}
      />
    </Container>
  );
};

export default AdminList;

