import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, Chip } from '@mui/material';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MapContainer from '../../maps/MapContainer';
import axios from 'axios';
import useGeocodedAddresses from '../../../hook/useGeocodedAddresses';
import formatAccidentType from '../../../hook/formatAccidentType';

const styles = {
  infoSection: (isNarrow) => ({
    padding: 'clamp(15px, 1.56vw, 30px)',
    backgroundColor: '#FFFFFF',
    borderRadius: 'clamp(10px, 1.04vw, 20px)',
    height: isNarrow ? '27vh' : '55vh',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '0.42vw',
      height: '0.42vw',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
      borderRadius: '0.21vw',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#555',
    },
  }),
  title: {
    fontWeight: 'bold',
    marginBottom: 'clamp(10px, 1vh, 20px)',
    fontSize: 'clamp(16px, 1.25vw, 24px)',
  },
  chip: {
    backgroundColor: '#FECACA',
    color: '#DC2626',
    marginLeft: 'clamp(4px, 0.4vw, 8px)',
    fontSize: 'clamp(10px, 0.63vw, 12px)',
    fontWeight: 'bold',
    height: 'clamp(20px, 1.5vw, 28px)',
    borderRadius: 'clamp(11px, 1.15vw, 22px)',
  },
  infoValue: {
    fontWeight: 'bold',
    fontSize: 'clamp(14px, 1.04vw, 20px)',
    display: 'flex',
    alignItems: 'center',
  },
  infoLabel: {
    color: '#6B7280',
    fontSize: 'clamp(12px, 0.83vw, 16px)',
    marginLeft: 'clamp(15px, 1.56vw, 30px)',
  },
  mapBox: {
    height: 'clamp(150px, 29vh, 45vh)',
    width: '100%',
    marginTop: 'clamp(5px, 0.52vw, 10px)',
    position: 'relative',
  },
  w3wBox: {
    position: 'absolute',
    top: '10%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    padding: 'clamp(2px, 0.21vw, 4px) clamp(4px, 0.42vw, 8px)',
    borderRadius: 'clamp(2px, 0.21vw, 4px)',
    display: 'flex',
    alignItems: 'center',
    zIndex: 1,
  },
  w3wText: {
    fontWeight: 'bold',
    color: '#000',
    fontSize: 'clamp(12px, 0.83vw, 16px)',
  },
};

const AccidentTimeLocationSection = ({ selectedAccident, isNarrow }) => {
  const [w3wAddress, setW3wAddress] = useState('');
  const { getAddress } = useGeocodedAddresses([selectedAccident]);
  const address = getAddress(selectedAccident);

  const markers = [
    {
      position: {
        lat: parseFloat(selectedAccident.latitude),
        lng: parseFloat(selectedAccident.longitude),
      },
      title: `장소: ${selectedAccident.address}, 날짜: ${new Date(selectedAccident.timeStamp).toLocaleDateString()}`,
    },
  ];

  const initialLocation = markers[0]?.position || { lat: 37.5665, lng: 126.9780 };
  const selectedCenter = markers[0]?.position;

  useEffect(() => {
    const fetchW3WAddress = async () => {
      try {
        const response = await axios.get(
          `https://api.what3words.com/v3/convert-to-3wa?coordinates=${selectedAccident.latitude},${selectedAccident.longitude}&key=${process.env.REACT_APP_W3W_API_KEY}`
        );
        setW3wAddress(response.data.words);
      } catch (error) {
        console.error('Error fetching what3words address:', error);
      }
    };

    fetchW3WAddress();
  }, [selectedAccident.latitude, selectedAccident.longitude]);

  return (
    <Box sx={styles.infoSection(isNarrow)}>
      <Typography sx={styles.title}>
        사고 시간 & 위치
        <Chip label={formatAccidentType(selectedAccident.accident_type) || 'N/A'} sx={styles.chip} />
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography sx={styles.infoValue}>
            <AccessTimeOutlinedIcon sx={{ marginRight: 'clamp(5px, 0.52vw, 10px)' }} />
            {selectedAccident.timeStamp}
          </Typography>
          <Typography sx={styles.infoLabel}>
            사고시간
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography sx={styles.infoValue}>
            <LocationOnIcon sx={{ marginRight: 'clamp(5px, 0.52vw, 10px)' }} /> {address}
          </Typography>
          <Typography sx={styles.infoLabel}>
            사고위치
          </Typography>
          <Box sx={styles.mapBox}>
            <MapContainer initialLocation={initialLocation} markers={markers} center={selectedCenter} />
            <Box sx={styles.w3wBox}>
              <LocationOnIcon sx={{ color: '#FF0000', marginRight: 'clamp(4px, 0.42vw, 8px)', fontSize: 'clamp(12px, 0.83vw, 16px)' }} />
              <Typography sx={styles.w3wText}>
                What3Words: {w3wAddress}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AccidentTimeLocationSection;