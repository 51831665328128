import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import ContactPhoneOutlinedIcon from '@mui/icons-material/ContactPhoneOutlined';
import DomainOutlinedIcon from '@mui/icons-material/DomainOutlined';
import { ref, onValue } from 'firebase/database';
import { database } from '../../../firebase';

const styles = {
  sectionBox: {
    padding: 'clamp(10px, 1.04vw, 20px)',
    backgroundColor: '#E5E7EB',
    borderRadius: 'clamp(10px, 1.04vw, 20px)',
    position: 'sticky',
    top: 0,
    zIndex: 2,
  },
  sectionTitle: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    marginBottom: 'clamp(10px, 1vw, 20px)',
    fontSize: 'clamp(16px, 1.25vw, 24px)',
    color: 'black',
  },
  infoBox: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 'clamp(2px, 0.2vw, 4px)',
  },
  infoIcon: {
    marginRight: 'clamp(10px, 1.04vw, 20px)',
    color: '#6B7280',
    fontSize: 'clamp(18px, 1.82vw, 35px)',
  },
  infoLabel: {
    fontWeight: 'bold',
    color: '#6B7280',
    fontSize: 'clamp(12px, 1.04vw, 20px)',
  },
  infoValue: {
    marginLeft: 'clamp(10px, 1.04vw, 20px)',
    fontWeight: 'bold',
    color: '#000000',
    fontSize: 'clamp(12px, 1.04vw, 20px)',
  },
};

const UserInfoSection = ({ selectedAccident, isNarrow }) => {
  const [insuranceName, setInsuranceName] = useState('');

  useEffect(() => {
    const fetchInsuranceName = () => {
      const userInfoRef = ref(database, `userInFo/${selectedAccident.userId}`);
      onValue(userInfoRef, (snapshot) => {
        const data = snapshot.val();
        if (data && data.insurance_name) {
          setInsuranceName(data.insurance_name);
        }
      });
    };

    fetchInsuranceName();
  }, [selectedAccident.userId]);

  return (
    <Box sx={styles.sectionBox}>
      <Typography sx={styles.sectionTitle}>
        사용자 정보
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={isNarrow ? 6 : 4}>
          <Box sx={styles.infoBox}>
            <AssignmentIndOutlinedIcon sx={styles.infoIcon} />
            <Typography sx={styles.infoLabel}>
              사용자 ID
            </Typography>
            <Typography sx={styles.infoValue}>
              {selectedAccident.userId || 'N/A'}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={isNarrow ? 6 : 4}>
          <Box sx={styles.infoBox}>
            <PersonOutlineIcon sx={styles.infoIcon} />
            <Typography sx={styles.infoLabel}>
              사용자 명
            </Typography>
            <Typography sx={styles.infoValue}>
              {selectedAccident.userName || 'N/A'}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={isNarrow ? 6 : 4}>
          <Box sx={styles.infoBox}>
            <PhoneOutlinedIcon sx={styles.infoIcon} />
            <Typography sx={styles.infoLabel}>
              사용자 전화번호
            </Typography>
            <Typography sx={styles.infoValue}>
              {selectedAccident.userPhone || 'N/A'}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={isNarrow ? 6 : 4}>
          <Box sx={styles.infoBox}>
            <ContactPhoneOutlinedIcon sx={styles.infoIcon} />
            <Typography sx={styles.infoLabel}>
              보호자 명
            </Typography>
            <Typography sx={styles.infoValue}>
              {selectedAccident.userGuardianName || 'N/A'}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={isNarrow ? 6 : 4}>
          <Box sx={styles.infoBox}>
            <ContactPhoneOutlinedIcon sx={styles.infoIcon} />
            <Typography sx={styles.infoLabel}>
              보호자 전화번호
            </Typography>
            <Typography sx={styles.infoValue}>
              {selectedAccident.userGuardianNumber || 'N/A'}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={isNarrow ? 6 : 4}>
          <Box sx={styles.infoBox}>
            <DomainOutlinedIcon sx={styles.infoIcon} />
            <Typography sx={styles.infoLabel}>
              가입 보험사
            </Typography>
            <Typography sx={styles.infoValue}>
              {insuranceName || 'N/A'}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserInfoSection;