// textContent.js
export const textContent = {
  ko: {
    appIntro: "세이프카 앱",
    accidentReport: "사고 보고서",
    appVideo: "APP 소개영상",
    mainTitle: "언제나 당신의 차를 안전하게",
    subTitle: "실시간 사고 감지부터 사고 보고서까지 당신의 차를\n가장 안전하게 지켜줄 세이프카입니다.",
    controlSite: "관제 사이트",
    controlSiteDesc: "사고 정보와 회원 정보 및 어플을\n전반적으로 관리하는 관제 사이트 입니다",
    trafficAccidentReport: "교통사고 보고서",
    trafficAccidentReportDesc: "교통사고 보고서를 신청하고\n사고 정보를 한눈에 확인할 수 있어요",
    promoVideo: "세이프카 홍보영상",
    address: "경상북도 영천시 금호읍 대구대길333, 창업보육센터 2호관 1202호",
    businessNumber: "사업자등록번호 261-86-01229",
    representative: "대표자 최은홍",
    privacyPolicy: "개인정보처리방침",
    termsOfService: "이용약관",
      // InquiryModal text
    inquiryLink: "문의하기",
    inquiryTitle: "문의 작성하기",
    emailAddress: "이메일 주소",
    emailPlaceholder: "이메일 주소를 입력해주세요.",
    inquiryContent: "문의 내용",
    contentPlaceholder: "문의 내용을 작성해 주세요.",
    submitButton: "문의하기",
    invalidEmailMessage: "이메일 주소가 잘못되었습니다.",
    successMessage: "문의가 성공적으로 전송되었습니다.",
    errorMessage: "문의 전송에 실패했습니다. 다시 시도해 주세요.",
  },
  en: {
    appIntro: "SafeCar APP",
    appVideo: "Promotional video",
    accidentReport: "Accident Report",
    mainTitle: "Always Keep Your Car Safe",
    subTitle: "From real-time accident detection to accident reports,\nSafecar will keep your car safe at all times.",
    controlSite: "Control Site",
    controlSiteDesc: "A control site that comprehensively manages\naccident information, member information, and applications.",
    trafficAccidentReport: "Traffic Accident Report",
    trafficAccidentReportDesc: "You can apply for a traffic accident report\nand check accident information at a glance.",
    promoVideo: "Safecar Promotional Video",
    address: "1202, Startup Center 2, 333, Daegudae-gil, Geumho-eup, Yeongcheon-si, Gyeongsangbuk-do",
    businessNumber: "Business Registration Number 261-86-01229",
    representative: "CEO Choi Eun-hong",
    privacyPolicy: "Privacy Policy",
    termsOfService: "Terms of Service",
        // InquiryModal text
    inquiryLink: "Inquiry",
    inquiryTitle: "Submit an Inquiry",
    emailAddress: "Email Address",
    emailPlaceholder: "Please enter your email address.",
    inquiryContent: "Inquiry Content",
    contentPlaceholder: "Please write your inquiry.",
    submitButton: "Submit",
    invalidEmailMessage: "Invalid email address.",
    successMessage: "Your inquiry has been successfully sent.",
    errorMessage: "Failed to send inquiry. Please try again.",
  },
  ja: {
    appIntro: "SafeCar アプリ",
    appVideo: "プロモーションビデオ",
    accidentReport: "事故レポート",
    mainTitle: "いつでもあなたの車を安全に",
    subTitle: "リアルタイムの事故検知から事故レポートまで、\nセーフカーがあなたの車を常に安全に守ります。",
    controlSite: "管制サイト",
    controlSiteDesc: "事故情報、会員情報、アプリケーションを\n包括的に管理する管制サイトです。",
    trafficAccidentReport: "交通事故レポート",
    trafficAccidentReportDesc: "交通事故レポートを申請し、\n事故情報を一目で確認できます.",
    promoVideo: "セーフカープロモーションビデオ",
    address: "慶尚北道永川市金湖邑大邱大路333、創業保育センター2号館1202号",
    businessNumber: "事業者登録番号 261-86-01229",
    representative: "代表者 チェ・ウンホン",
    privacyPolicy: "プライバシーポリシー",
    termsOfService: "利用規約",
    // InquiryModal text
    inquiryLink: "お問い合わせ",
    inquiryTitle: "お問い合わせ",
    emailAddress: "メールアドレス",
    emailPlaceholder: "メールアドレスを入力してください。",
    inquiryContent: "お問い合わせ内容",
    contentPlaceholder: "お問い合わせ内容をご記入ください。",
    submitButton: "送信",
    invalidEmailMessage: "メールアドレスが無効です。",
    successMessage: "お問い合わせが正常に送信されました。",
    errorMessage: "お問い合わせの送信に失敗しました。もう一度お試しください。",
  }
};