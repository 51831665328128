import React, { createContext, useState, useContext, useEffect } from 'react';
import { database, ref, onValue, set, push, messaging, getToken } from '../firebase';

const SendNotiContext = createContext();

export const useSendNoti = () => useContext(SendNotiContext);

export const SendNotiProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const [notificationData, setNotificationData] = useState({ title: '', message: '', fileUrls: [], url: '' });
  const [files, setFiles] = useState([]);
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    title: '',
    message: '',
    severity: 'success'
  });

  useEffect(() => {
    const notificationsRef = ref(database, 'sendNoti');
    const unsubscribe = onValue(notificationsRef, (snapshot) => {
      const data = snapshot.val() || {};
      const notificationsArray = Object.entries(data)
        .map(([timeStamp, details]) => ({ timeStamp, ...details }))
        .sort((a, b) => new Date(b.timeStamp) - new Date(a.timeStamp));
      setNotifications(notificationsArray);
      console.log('Notifications updated:', notificationsArray);
    });

    return () => unsubscribe();
  }, []);

  const sendNotification = async (title, message) => {
    try {
      const newNotificationRef = push(ref(database, 'sendNoti'));
      const timestamp = new Date().toISOString();
      await set(newNotificationRef, {
        title,
        message,
        timestamp
      });
      console.log('Notification sent successfully');
      updateSnackbarState({
        open: true,
        title: '알림 전송 성공',
        message: '알림이 성공적으로 전송되었습니다.',
        severity: 'success'
      });
    } catch (error) {
      console.error('Error sending notification:', error);
      updateSnackbarState({
        open: true,
        title: '알림 전송 실패',
        message: '알림 전송 중 오류가 발생했습니다.',
        severity: 'error'
      });
    }
  };

  const updateNotificationData = (id, value) => {
    setNotificationData(prevData => ({ ...prevData, [id]: value }));
  };

  const resetNotificationData = () => {
    setNotificationData({ title: '', message: '', fileUrls: [], url: '' });
    setFiles([]);
  };

  const updateSnackbarState = (newState) => {
    setSnackbarState(prevState => ({ ...prevState, ...newState }));
  };

  const registerFCMToken = async (userId) => {
    try {
      const currentToken = await getToken(messaging);
      if (currentToken) {
        const userRef = ref(database, `userInFo/${userId}`);
        await set(userRef, { token: currentToken }, { merge: true });
      } else {
        console.log('No registration token available. Request permission to generate one.');
      }
    } catch (error) {
      console.error('An error occurred while retrieving token. ', error);
    }
  };

  const value = {
    notifications,
    notificationData,
    updateNotificationData,
    resetNotificationData,
    files,
    setFiles,
    snackbarState,
    updateSnackbarState,
    sendNotification,
    registerFCMToken,
  };

  return (
    <SendNotiContext.Provider value={value}>
      {children}
    </SendNotiContext.Provider>
  );
};