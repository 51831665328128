import React from 'react';
import { useAuth } from '../../context/AuthProvider';
import KakaoMap from './KakaoMap';
import GoogleMap from './GoogleMap';

const MapContainer = ({ markers, center, onMarkerClick }) => {
  const { currentUser } = useAuth();
  const useGoogleMap = ['myren03', 'see2'].includes(currentUser?.username);

 return (
    <div style={{ width: '100%', height: '100%' }}>
      {useGoogleMap ? (
        <GoogleMap center={center} markers={markers} onMarkerClick={onMarkerClick} />
      ) : (
        <KakaoMap markers={markers} center={center} onMarkerClick={onMarkerClick} />
      )}
    </div>
  );
};

export default MapContainer;