import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { getDatabase, ref, onValue, off } from 'firebase/database';
import axios from 'axios';

const IncidentContext = createContext();

export const useIncidents = () => useContext(IncidentContext);

export const IncidentProvider = ({ children }) => {
  const [userIds, setUserIds] = useState([]);
  const [userIncidents, setUserIncidents] = useState([]);
  const [selectedIncident, setSelectedIncident] = useState(null);
  const [loading, setLoading] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [currentPage, setCurrentPage] = useState('main');
  const [currentUserId, setCurrentUserId] = useState(null);

  const fetchUserIds = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get('https://us-central1-safecar-1487e.cloudfunctions.net/api/getAllUserIds');
      setUserIds(response.data || []);
    } catch (error) {
      console.error('Error fetching user IDs:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchUserIncidents = useCallback(async (userId) => {
    setLoading(true);
    try {
      const response = await axios.get('https://us-central1-safecar-1487e.cloudfunctions.net/api/getUserIncidents', {
        params: { userId }
      });
      return response.data || [];
    } catch (error) {
      console.error('Error fetching user incidents:', error);
      return [];
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchRecentIncidents = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get('https://us-central1-safecar-1487e.cloudfunctions.net/api/getRecentIncidents');
      setUserIncidents(response.data || []);
    } catch (error) {
      console.error('Error fetching recent incidents:', error);
    } finally {
      setLoading(false);
      setInitialLoad(false);
    }
  }, []);

  useEffect(() => {
    fetchUserIds();

    const db = getDatabase();
    const accidentRef = ref(db, 'accidentList');

    const handleAccidentChange = (snapshot) => {
      if (currentPage === 'main') {
        fetchRecentIncidents();
      } else if (currentPage === 'details' && currentUserId) {
        fetchUserIncidents(currentUserId).then(setUserIncidents);
      }
      fetchUserIds(); // Fetch user IDs again to reflect any changes
    };

    onValue(accidentRef, handleAccidentChange);

    return () => {
      off(accidentRef, 'value', handleAccidentChange);
    };
  }, [currentPage, currentUserId, fetchRecentIncidents, fetchUserIncidents, fetchUserIds]);

  return (
    <IncidentContext.Provider
      value={{
        userIds,
        userIncidents,
        setUserIncidents,
        selectedIncident,
        setSelectedIncident,
        fetchUserIncidents,
        fetchRecentIncidents,
        loading,
        initialLoad,
        setCurrentPage,
        setCurrentUserId,
      }}
    >
      {children}
    </IncidentContext.Provider>
  );
};