import React, { useState, useRef } from 'react';
import { Grid, TextField, Box, Typography, IconButton, InputAdornment } from '@mui/material';
import { Clear as ClearIcon, Visibility, VisibilityOff } from '@mui/icons-material';
import { useAuth } from '../../context/AuthProvider';

const RenderTextField = ({ label, name, value, icon, onChangeHandler, showPassword, setShowPassword }) => {
  const [isHovered, setIsHovered] = useState(false);
  const { currentUser } = useAuth();
  const inputRef = useRef(null);
  const isViewer = currentUser?.role === 'viewer';

  const handleClear = (e) => {
    if (isViewer) return;
    e.preventDefault();
    onChangeHandler({ target: { name, value: '' } });
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', marginBottom: 'clamp(4px, 0.42vh, 8px)' }}>
      <img
        src={icon}
        alt={`${label} Icon`}
        style={{ width: 'clamp(30px, 2.29vw, 44px)', height: 'clamp(30px, 3.89vh, 42px)', marginRight: 'clamp(4px, 0.42vw, 8px)' }}
      />
      <Typography
        variant="body2"
        sx={{
          flex: '0 0 clamp(80px, 7.81vw, 150px)',
          marginLeft: 'clamp(5px, 1vw, 20px)',
          fontWeight: 'bold',
          fontSize: 'clamp(12px, 0.83vw, 16px)',
          color: '#444444'
        }}
      >
        {label}
      </Typography>
      <Box
        sx={{ flexGrow: 1 }}
        onMouseEnter={() => !isViewer && setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <TextField
          inputRef={inputRef}
          margin="dense"
          type={name === 'userPW' && !showPassword ? 'password' : 'text'}
          fullWidth
          variant="outlined"
          value={value || ''}
          onChange={(e) => onChangeHandler(e)}
          disabled={isViewer}
          sx={{
            ml: 'clamp(5px, 0.52vw, 10px)',
            '& .MuiInputBase-root': {
              height: 'clamp(30px, 3.89vh, 42px)',
              alignItems: 'center',
              fontSize: 'clamp(12px, 0.83vw, 16px)',
              fontWeight: 'bold',
              marginLeft: 'clamp(-10px, -1vw, -20px)',
              color: '#141414',
              bgcolor: isViewer ? '#f5f5f5' : 'transparent',
              '& input': {
                color: '#141414',
                '-webkit-text-fill-color': '#141414 !important',
              }
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: isViewer ? '#e0e0e0' : undefined
            }
          }}
          InputProps={{
            readOnly: isViewer,
            endAdornment: (
              !isViewer && isHovered && (
                <InputAdornment position="end">
                  {name === 'userPW' && (
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword((show) => !show)}
                      onMouseDown={(e) => e.preventDefault()}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  )}
                  <IconButton edge="end" onClick={handleClear} onMouseDown={(e) => e.preventDefault()}>
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              )
            ),
          }}
        />
      </Box>
    </Grid>
  );
};

export default RenderTextField;
