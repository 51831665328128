import React, { useEffect } from 'react';
import { Snackbar, Alert } from '@mui/material';
import { useNotifications } from '../../context/NotificationContext';
import InfoIcon from '@mui/icons-material/Info';
import ErrorIcon from '@mui/icons-material/Error';
import ReportIcon from '@mui/icons-material/Report';
import HelpIcon from '@mui/icons-material/Help';

const NotificationDisplay = () => {
  const { currentNotifications, handleNotificationClose } = useNotifications();

  useEffect(() => {
    const timers = currentNotifications.map(notification => 
      setTimeout(() => {
        handleNotificationClose(notification.id);
      }, 3000)
    );

    return () => {
      timers.forEach(timer => clearTimeout(timer));
    };
  }, [currentNotifications, handleNotificationClose]);

  const getNotificationMessage = (notification) => {
    let message = '';
    switch (notification.type) {
      case 'accidentList':
        message = `사고 발생 - 사용자 ID: ${notification.userId}, 시간: ${notification.timeStamp}, 장소: ${notification.address}`;
        break;
      case 'requestReport':
        message = `보고서 요청 - 사용자 ID: ${notification.userId}, 시간: ${notification.timeStamp}, 장소: ${notification.address}`;
        break;
      case 'cs':
        message = `문의 - 사용자 ID: ${notification.userId}, 시간: ${notification.timeStamp}, 이메일: ${notification.email}`;
        break;
      default:
        message = `알림 - 사용자 ID: ${notification.userId}, 시간: ${notification.timeStamp}`;
    }
    return message;
  };

  const getAlertProps = (notification) => {
    if (!notification) return {}; // notification이 null인 경우 빈 객체 반환

    let borderColor = '#FFFFFF'; // 기본 흰색 테두리
    let IconComponent = InfoIcon; // 기본 아이콘
    let iconColor = '#FFFFFF'; // 기본 아이콘 색상

    switch (notification.type) {
      case 'accidentList':
        borderColor = '#FF0000'; // 빨간색 테두리
        IconComponent = ErrorIcon;
        iconColor = '#FF0000';
        break;
      case 'requestReport':
        borderColor = '#0000FF'; // 파란색 테두리
        IconComponent = ReportIcon;
        iconColor = '#0000FF';
        break;
      case 'cs':
        borderColor = '#008000'; // 초록색 테두리
        IconComponent = HelpIcon;
        iconColor = '#008000';
        break;
      default:
        break;
    }
    return {
      border: `2px solid ${borderColor}`,
      backgroundColor: '#FFFFFF', // 기본 배경색 흰색
      icon: <IconComponent style={{ color: iconColor }} /> // 아이콘 색상
    };
  };

  return (
    <>
      {currentNotifications.map((notification) => {
        const alertProps = getAlertProps(notification);
        return (
          <Snackbar
            key={notification.id}
            open={!!notification}
            autoHideDuration={3000}
            onClose={() => handleNotificationClose(notification.id)}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Alert 
              onClose={() => handleNotificationClose(notification.id)} 
              severity="info" 
              icon={alertProps.icon} 
              sx={{ border: alertProps.border, backgroundColor: alertProps.backgroundColor, width: '100%' }}
            >
              {notification && getNotificationMessage(notification)}
            </Alert>
          </Snackbar>
        );
      })}
    </>
  );
};

export default NotificationDisplay;
