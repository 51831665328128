import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useService } from '../../context/ServiceContext';
import { Box, Typography, IconButton, Snackbar, Alert } from '@mui/material';
import ComplaintDetails from '../../components/service/ComplaintDetails';
import ResponseForm from '../../components/service/ResponseForm';
import AnswerDetails from '../../components/service/AnswerDetails';
import back from '../../styles/icons/customer/back.png';
import SubLayout from '../../components/layout/SubLayout';
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    padding: 'clamp(10px, 2vw, 20px)',
    boxSizing: 'border-box',
    marginLeft: 'clamp(-180px, -18vw, -240px)',
  },
  phasicContainer: {
    flex: '1',
    overflowY: 'auto',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 'clamp(10px, 2vh, 20px)',
  },
  backIcon: {
    marginRight: 'clamp(10px, 2vw, 20px)',
    padding: 0,
  },
  title: {
    fontSize: 'clamp(24px, 4vw, 32px)',
    fontWeight: 'bold',
    color: '#141414',
  },
  section: {
    flex: '40%',
    overflow: 'auto',
    marginBottom: 'clamp(10px, 2vh, 20px)',
  },
};

const DetailedComplaint = () => {
  const { userId, timeStamp } = useParams();
  const { complaints, updateComplaint, sendFCMNotificationToUserById } = useService();
  const navigate = useNavigate();
  const [answerBody, setAnswerBody] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const decodedTimeStamp = decodeURIComponent(timeStamp);
  const complaint = complaints.find(c => c.userId === userId && c.timestamp === decodedTimeStamp);

  if (!complaint) {
    return <div>민원을 찾을 수 없습니다.</div>;
  }

  const handleBack = () => {
    navigate(-1);
  };

  const handleSubmit = async () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hour = String(now.getHours()).padStart(2, '0');
    const minute = String(now.getMinutes()).padStart(2, '0');
    const finalFormattedDate = `${year}-${month}-${day} ${hour}:${minute}`;

    const updatedComplaint = {
      ...complaint,
      answerBody: answerBody.replace(/\n/g, '<br>'),
      answerTimeStamp: finalFormattedDate
    };

    try {
      await updateComplaint(updatedComplaint);
      console.log(`Complaint updated successfully for user ${complaint.userId}`);
      
      const notificationResult = await sendFCMNotificationToUserById(
        complaint.userId, 
        '문의 답변 작성 완료', 
        '문의하신 내용의 답변이 작성되었습니다.'
      );
      console.log('FCM notification result:', notificationResult);
      
      setSnackbarMessage('답변이 성공적으로 완료되었습니다.');
      setSnackbarOpen(true);
      setTimeout(() => navigate(-1), 2000);
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      setSnackbarMessage('답변 처리 중 오류가 발생했습니다.');
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <SubLayout>
      <Box sx={styles.header}>
        <IconButton onClick={handleBack} sx={styles.backIcon}>
          <img 
            src={back} 
            alt="Back" 
            style={{ 
              width: 'clamp(30px, 5vw, 50px)', 
              height: 'clamp(30px, 5vw, 50px)' 
            }} 
          />
        </IconButton>
        <Typography sx={styles.title}>고객센터</Typography>
      </Box>

      <Box sx={styles.contentContainer}>
        <Box sx={styles.section}>
          <ComplaintDetails complaint={complaint} />
        </Box>
        <Box sx={styles.section}>
          {complaint.answerBody ? (
            <AnswerDetails
              answerBody={complaint.answerBody}
              answerTimeStamp={complaint.answerTimeStamp}
            />
          ) : (
            <ResponseForm
              complaint={complaint}
              answerBody={answerBody}
              setAnswerBody={setAnswerBody}
              handleSubmit={handleSubmit}
            />
          )}
        </Box>
      </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleSnackbarClose} 
          severity="success" 
          sx={{ 
            width: '100%',
            fontSize: 'clamp(14px, 1.5vw, 16px)',
          }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </SubLayout>
  );
};

export default DetailedComplaint;